import React from 'react'
import c from "./content.module.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap'
const content = () => {
  return (
    <div className={c.ParentDiv}>
      <div className={c.title}>
        Sustainability Economics at GreenFin Boston Summit
      </div>
      <div className={c.content}>
        June 26-28, 2023
      </div>
      <div className={c.watch}>
        Watch our CEO and Co-founder speak with WION at the GreenFin summit in Boston, MA.
      </div>
      <div className={c.videoPart}>
        <div className={c.video}>
          <Container style={{margin:"0rem"}}>
            <div className="ratio ratio-16x9">
              <iframe src="https://www.youtube.com/embed/AqU4n848S38" title="YouTube video" allowFullScreen></iframe>
            </div>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default content

