import classes from "./OurStrategy.module.scss";
import OurStrategyImg from "../../../component/assets/icons/ourStrategy.svg";
import EggDiagram from "../../assets/eggDiagram.svg";
import EggDiagramMobile from "../../assets/icons/mobileEggDiagram.svg";
import InfinityMobile from "../../assets/icons/mobileInfinity.svg";
import { ReactComponent as RightArrow } from "../../../component/assets/icons/rightArrow.svg";
import { useState } from "react";

const liMap = [
  "Current state discovery",
  "Transition planning",
  "Economic gap",
  "Energy mix",
  "Transition finance",
  "Blockchain-driven Marketplace",
  "Financial analysis",
];

let liPara = [
  "Data-driven extraction of the technical and financial aspects of the current state of CFPP",
  "Practical, location-specific transition plans developed in collaboration with CFPP owners, power purchasers, financial institutions, and policymakers",
  "Practical, data-driven mechanisms to pinpoint the exact dollars CFPP owners need to forego as part of the transition",
  "Model-driven approaches covering region-specific policies to identify potential clean energy technologies and associated costs, along with financial analysis",
  "Data-driven approaches using innovative transition financing instruments for profitability and collaboration with equity and debt partners",
  "Sourcing the right clean energy technologies by working with clean energy developers to expedite the transition",
  "Detailed financial analysis using innovative transition financing instruments and transition credit cash flows for both equity and debt players",
];

const OurStrategy = ({ mobileVersion }) => {
  const [liActive, setLiActive] = useState(0);

  const liClickHandler = (index) => () => {
    setLiActive(index);
  };

  return (
    <section className={classes["our-strategy-container"]}>
      <h1 className={classes["title"]} aria-label="Our Strategy Overview">
        Our Strategy
      </h1>
      <div className={classes["our-strategy-content"]}>
        CLEM employs a seven-step approach encompassing location-specific
        transition planning with innovative financial instruments and transition
        credits. At CLEM, our mission is to make energy transitions not just
        practical but profitable for CFPP asset owners and financiers.
      </div>

      <div className={classes["sample-data-wrapper"]}>
        <div className={classes["egg-diagram-container"]}>
          {mobileVersion ? (
            <div className={classes["mobile-img"]}>
              <img
                className={classes["egg-diagram"]}
                src={EggDiagramMobile}
                alt="aboutus"
              />
            </div>
          ) : (
            <img
              className={classes["egg-diagram"]}
              src={EggDiagram}
              alt="aboutus"
            />
          )}
          {!mobileVersion && (
            <div className={classes["egg-diagram-arrow"]}>
              <div className={classes["arrow-body"]}></div>
              <RightArrow />
            </div>
          )}
        </div>
        <div className={classes["list-items"]}>
          <div className={classes["inifinity-logo-container"]}>
            {mobileVersion ? (
              <div className={classes["mobile-img"]}>
                <img src={InfinityMobile} alt="aboutus" />
              </div>
            ) : (
              <img src={OurStrategyImg} alt="aboutus" />
            )}
          </div>
          <div className={classes["ul-list"]}>
            <ul>
              {liMap.map((el, index) => (
                <>
                  <li
                    onClick={liClickHandler(index)}
                    className={liActive === index ? classes["li-active"] : ""}
                  >
                    {el}
                  </li>
                  {liActive === index && (
                    <p className={classes["sub-content"]}>{liPara[index]}</p>
                  )}
                </>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurStrategy;
