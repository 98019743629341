import React from "react";
import classes from "./financial.module.css";
import FinancialCard from "./FinancialCard/FinancialCard";

import FinancialInstitutions from "../../assets/financial Institutions.jpeg";
import EnergySector from "../../assets/energy sector.jpeg";
import RealE from "../../assets/real estate.jpeg";
import Industries from "../../assets/Industries.jpeg";
import Transport from "../../assets/transport.jpeg";
import HighEmittingSector from "../../assets/high emitting sectors.jpeg";
import BusinessOwners from "../../assets/business owners.jpeg";
import Lenders from "../../assets/lenders.jpeg";
import Buyers from "../../assets/buyers.jpeg";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
function Financial() {
  const sectors = [
    {
      image: FinancialInstitutions,
      name: "Financial Institutions",
    },
    {
      image: EnergySector,
      name: "Energy Sector",
    },
    {
      image: RealE,
      name: "Real Estate",
    },
    {
      image: Industries,
      name: "Industries",
    },
    {
      image: Transport,
      name: "Transport",
    },
    {
      image: HighEmittingSector,
      name: "Other High Emitting Sectors",
    },
  ];

  const buyingCentres = [
    {
      image: BusinessOwners,
      name: "Business Owners",
    },
    {
      image: Lenders,
      name: "Lenders",
    },
    {
      image: Buyers,
      name: "Buyers",
    },
  ];

  return (
    <div className={classes.mainParentDiv}>
      <div className={classes.headingDiv}>Who We Help</div>

      <div>
        <div className={classes.parentDiv}>Sectors</div>
        <FinancialCard cardData={sectors} />
      </div>

      <div>
        <div className={classes.parentDiv}>Buying Centres</div>
        <FinancialCard cardData={buyingCentres} />
      </div>
    </div>
  );
}

export default Financial;
