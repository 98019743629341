import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Dropdown from "../Core/Dropdown/Dropdown";
import { formatDate } from "../../utils/helper/dateConverter";
import { ReactComponent as CancelIcon } from "../../../src/component/assets/formcancel.svg"

import classes from "./Form.module.scss";

const formContent = [
  { name: "Full Name*", type: "text", key: "fullName" },
  { name: "Company name*", type: "text", key: "companyName" },
  { name: "Company mail ID*", type: "text", key: "companyEmail" },
  { name: "Designation", type: "text", key: "designation" },
  {
    name: "How are you going to attend this event?*",
    type: "dropdown",
    options: ["Virtual", "In-person"],
    key: "event",
  },
  // {
  //   name: "Select the days that you will attend the event*",
  //   type: "checkbox",
  //   options: ["Sept 24th", "Sept 25th", "Sept 26th"],
  //   key: "attendingDate",
  // },
];

const Form = ({ onSuccess,onCancel,date }) => {

  
  

  const [formObj, setFormObj] = useState({ isAgreed: false });
  const [multiCheckBoxObj, setMultiSelectCheckBox] = useState({});
  const [invalidInputList, setInvalidInputList] = useState([]);
  const [registerLoader ,setRegisterLoader] = useState(false)

  const privacyCheckHandler = (event) => {
    const isAllFilled =
      formObj.fullName &&
      formObj.companyName &&
      formObj.companyEmail &&
      formObj.event &&
      formObj.attendingDate?.length !== 0 &&
      checkValidation();
  
    if (isAllFilled) {
      event.target.checked = true;
      const dateToSend = date === 0 ? '26-09-2024' : date === 1 ? '24-09-2024' : date === 2 ? '25-09-2024' : '26-09-2024';
      setFormObj((prevObj) => {
        prevObj["isAgreed"] = event.target.checked;
        prevObj["attendingDate"] = dateToSend;
        return { ...prevObj };
      });
    } else {
      event.target.checked = false;
    }
  };

  const checkValidation = () => {

    const nameRegex = /^[A-Za-z\s]+$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[com]{3}$/;

    if (!nameRegex.test(formObj.fullName)) {
      setInvalidInputList((prevList) => {
        return [...prevList, "fullName"];
      })
      return false;
    }
    else if (!nameRegex.test(formObj.companyName)) {
      setInvalidInputList((prevList) => {
        return [...prevList, "companyName"];
      })
      return false;
    }
    else if (!emailRegex.test(formObj.companyEmail)) {
      setInvalidInputList((prevList) => {
        return [...prevList, "companyEmail"];
      })
      return false;
    }
    else if (!nameRegex.test(formObj.designation)) {
      setInvalidInputList((prevList) => {
        return [...prevList, "designation"];
      })
      return false;
    }
    else {
      return true;
    }

  };

  const registerClickHandler = async () => {

    setRegisterLoader(true)
    let valuesArr = Object.values(multiCheckBoxObj);
    let datesArr = [];

    for (let key in valuesArr[0]) {
      if (valuesArr[0][key]) {
        datesArr.push(formatDate(key));
      }
    }

    const objToSend = { attendingDate: datesArr, ...formObj };

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const response = await fetch("https://x1ssbqi8cl.execute-api.ap-south-1.amazonaws.com/prod/api/v1/register", {
      method: "POST",
      body: JSON.stringify(objToSend),
      headers: myHeaders,
    })
      .then((res) => {
        if (res.status === 200) {
          setRegisterLoader(false)
          toast.success("Registered Successfully");
        } else {
          throw new Error("Error while registering for event");
        }
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        onSuccess?.();
      });
  };

  const inputChangeHandler = (receivedKey, value) => {
    setInvalidInputList([]);
    setFormObj((prevObj) => {
      prevObj[receivedKey] = value;
      const isAllFilled =
        formContent.every(
          (item) =>
            (item.type !== "text" || prevObj[item.key]) &&
            (item.type !== "dropdown" || prevObj[item.key])
        ) && prevObj.attendingDate?.length !== 0;
  
      if (!isAllFilled) {
        prevObj["isAgreed"] = false; // Uncheck the checkbox if any input is empty
      }
  
      return { ...prevObj };
    });
  };

  const dropDownChangeHandler = (receivedKey) => (receivedValue) => {
    setFormObj((prevObj) => {
      const matchingField = formContent.find(
        (field) => field.key === receivedKey
      );

      if (matchingField && matchingField.type === "dropdown") {
        prevObj[receivedKey] = matchingField.options[receivedValue];
      }

      return { ...prevObj };
    });
  };

  const multiSelectCheckboxHandler = (receivedDate, receivedKey) => (event) => {
    setMultiSelectCheckBox((prevObj) => {
      prevObj[receivedKey] = {
        ...prevObj[receivedKey],
        [receivedDate]: event.target.checked,
      };

      return { ...prevObj };
    });
  };

  return (
    <div>
      <div className={classes["cancel-icon"]}  onClick={onCancel}>
        <CancelIcon/>
      </div>
    <div className={classes["form-container"]}>
      <div className={classes["form-title"]}>
        <b>Registeration form</b>
      </div>
      <div className={classes["form-content-container"]}>
        {formContent.map((formEle) => (
          <div className={classes["form-ele-container"]}>
            <label className={classes["form-ele-label"]}>
              {formEle.name}
            </label>
            {formEle.type === "checkbox" && (
              <div className={classes["checkbox-text-container"]}>
                {formEle.options.map((el) => {
                  return (
                    <div className={classes["checkbox-text-child-container"]}>
                      <input
                        className={classes["form-ele-input"]}
                        type={"checkbox"}
                        onChange={multiSelectCheckboxHandler(el, formEle.key)}
                      />
                      <div style={{ color: "#0E0E0E", fontWeight: "600" }}>
                        {el}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {formEle.type === "text" && (
              <input
                style={invalidInputList.includes(formEle.key) ? { border: "1px solid red" } : {}}
                // style={invalidInput ? { border: "1px solid red" } : {}}
                className={classes["form-ele-input"]}
                type={"text"}
                onChange={(event) => {
                  inputChangeHandler(formEle.key, event.target.value);
                }}
              />
            )}
            {formEle.type === "dropdown" && (
              <Dropdown
                options={formEle.options}
                onChange={dropDownChangeHandler(formEle.key)}
              />
            )}
          </div>
        ))}
        <div className={classes["privacy-container"]}>
          <input type="checkbox" onChange={privacyCheckHandler}></input>
          <div>I acknowledge that I have read the above information</div>
        </div>
        <div
  className={`${classes["register-btn-container"]}`}
  style={
    formObj.isAgreed && !registerLoader
      ? {}
      : {
          backgroundColor: "#e5e5e5",
          color: "black",
          cursor: "not-allowed",
        }
  }
  onClick={formObj.isAgreed && !registerLoader ? registerClickHandler : () => {}}
>
 { !registerLoader? "Register" :"Registering..."}
</div>
      </div>
    </div>
    </div>
  );
};

export default Form;
