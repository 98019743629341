import React from "react";
import { useEffect } from "react";
import contact from "./contact.module.css";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className={contact.firstDiv}>
        <div className={contact.firstTextMainDiv}>
          {/* <div data-aos="fade-right" data-aos-once="true" className={contact.firstDivText}>Unlock New Opportunities to a Sustainable Future.</div> */}
          <div data-aos="fade-right" data-aos-once="true" className={contact.firstDivText}>Unlock New Opportunities to a <h1 style={{display:"inline"}}>Low-Carbon Economy with Sustainability Economics</h1></div> {/* Changed For SEO*/}
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default Contact;
