import React from "react";
import w from "./work.module.css";
import Earth from "../../assets/Earth.png";
import pin from "../../assets/pin.png";
import Map from "../../assets/Earth.png"
function Work() {
  return (
    <div className={w.work}>
      <div data-aos="fade-up" data-aos-once="true" className={w.where}>
        WHERE WE WORK
      </div>
      <div className={w.map} data-aos="fade-up" data-aos-once="true">
        {/* <div className={w.pinLoc}>
          <div className={w.insideDivPin1}>
            <img data-aos="fade-down" data-aos-once="true" data-aos-delay="200" className={w.pin} src={pin} alt="/png" />
            <div className={w.loc}>USA</div>
          </div>

        </div>
        <div className={w.pinLoc1}>
          <div className={w.insideDivPin}>
            <img data-aos="fade-down" data-aos-once="true" data-aos-delay="200" className={w.pin} src={pin} alt="/png" />
            <div className={w.loc}>Singapore</div>
          </div>

        </div>
        <div className={w.pinLoc2}>
          <div className={w.insideDivPin}>
            <img data-aos="fade-down" data-aos-once="true" data-aos-delay="200" className={w.pin} src={pin} alt="/png" />
            <div className={w.loc}>India</div>
          </div>

        </div>
        <div className={w.pinLoc3}>
          <div className={w.insideDivPin}>
            <img data-aos="fade-down" data-aos-once="true" data-aos-delay="200" className={w.pin} src={pin} alt="/png" />
            <div className={w.loc}>UK</div>
          </div>

        </div>
        <div className={w.pinLoc4}>
          <div className={w.insideDivPin}>
            <img data-aos="fade-down" data-aos-once="true" data-aos-delay="200" className={w.pin} src={pin} alt="/png" />
            <div className={w.loc}>Japan</div>
          </div>

        </div>
        <img className={w.map} src={Earth} alt="/png" />
      </div> */}
        <img data-aos="zoom-in" data-aos-once="true" data-aos-delay="200" className={w.mapImg} src={Map} alt="Map" />
      </div>
    </div>
  );
}

export default Work;
