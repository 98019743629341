import React from "react";
import { useNavigate } from "react-router-dom";

import ACIImage from "../../../component/assets/acImage.png";
import ClemImage from "../../../component/assets/clemPic.png";
import CalendarIcon from "../../../component/assets/calendarTimeIcon.svg";

import c from "./content.module.css";
import "bootstrap/dist/css/bootstrap.min.css";

const Content = () => {
  const navigate = useNavigate();

  const navigatePressRelease = (url) => {
    navigate(`/media/press-release/${url}`);
  };

  return (
    <div className={c.ParentDiv}>
      <div
        className={c.card}
        onClick={() =>
          navigatePressRelease("sustainabilityeconomics-aci-partnership")
        }
      >
        <div className={c.imageContainer}>
          <img className={c.cardImage} src={ACIImage}></img>
        </div>

        <div className={c.cardHeading}>
          Sustainability Economics works with Asia Carbon Institute, of the
          TRACTION group...
        </div>

        <div className={c.cardContent}>
          <span className={c.cardContentSpan}> Singapore – 17 April 2024 </span>{" "}
          - Sustainability Economics, a leading provider of global net-zero
          solutions, has announced to choose Asia Carbon ...
        </div>

        <div className={c.cardDetails}>
          <img className={c.calendarIcon} src={CalendarIcon} alt="Calendar" />
          April 17, 2024
        </div>
      </div>

      <div
        className={c.card}
        onClick={() => navigatePressRelease("se-ptsucofindo-partnership")}
      >
        <div className={c.imageContainer}>
          <img className={c.cardImage} src={ClemImage}></img>
        </div>

        <div className={c.cardHeading}>
          Sustainability Economics partners with PT SUCOFINDO, a state-owned
          company of ...
        </div>

        <div className={c.cardContent}>
          <span className={c.cardContentSpan}> Singapore – 26 July 2024 </span>{" "}
          - Sustainability Economics, an international entity, providing
          end-to-end net-zero solutions for high emitting ...
        </div>

        <div className={c.cardDetails}>
          <img className={c.calendarIcon} src={CalendarIcon} alt="Calendar" />
          July 26, 2024
        </div>
      </div>
    </div>
  );
};

export default Content;
