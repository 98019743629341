import React from "react";
import { useEffect } from "react";

import AllSector from "../../home/allSector/AllSector";

import SectorInfoSection from "./sectorInfoSection/SectorInfoSection.js";
import WhoWeHelp from "./WhoWeHelp/WhoWeHelp.js";
import WhatWeDo from "./WhatWeDo/WhatWeDo.js";
import Video from "./video/video.js";

const Main = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Video />
      <WhoWeHelp />
      <WhatWeDo />
      {/* <AllSector /> */}
      <SectorInfoSection />
    </>
  );
};

export default Main;
