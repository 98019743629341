import React, { useState } from "react";
import con from "./content.module.css";
import { useNavigate } from "react-router-dom";

const data = [
  {
    id: 1,
    img: require("../../assets/Finance.png"),
    title: "Financial Institutions",
    body: "As the impacts of climate change pose a material risk to financial institutions, investors and stakeholders are demanding resilient decarbonization plans. From depreciation of valuation to increased expenses in your own operations, awareness of climate risks will help to curtail these impacts. Our SaaS platform solutions are designed to simplify and automate your Net Zero Transition journey.",
    learn: "learnfinance",
  },
  {
    id: 2,
    img: require("../../assets/Energy1.png"),
    title: "Energy Sector",
    body: "According to the latest IPCC report, decarbonization requires a rapid shift of the global economy, away from traditional energy generation. Regulators are implementing new programs that mandate continuous reporting of emissions. We work with clients and ensure the E2E of their Monitoring, Reporting, Verification and help them ensure compliance and make strategic business decisions. ",
    learn: "",
  },
  {
    id: 3,
    img: require("../../assets/Industry_1.png"),
    title: "Industry sector",
    body: "Climate change and its effects on the global supply chain are impacting the industrial sector. As manufacturers and industrial facilities are placed under increased scrutiny by environmental agencies, there is a large push towards climate positive practises. We work with our clients to ensure compliance across several jurisdictions as well as accelerate their decarbonization journey.",
    learn: "",
  },
  {
    id: 4,
    img: require("../../assets/Transport1.png"),
    title: "Transport sector",
    body: "The impacts of climate change are sending ripples across the supply chain of the global economy, and as such, decarbonization is an important priority for the transport sector. Climate risks from value chain disruptions to policy restrictions on fossil fuels have to be accounted for. Our SaaS platform allows you to understand and curtail your risks across your organization and build resilient strategies adapted for a changing climate.",
    learn: "",
  },
  {
    id: 5,
    img: require("../../assets/RE.png"),
    title: "Real Estate sector",
    body: "As climate change intensifies, the real estate sector is incurring heavy losses due to extreme weather events. From direct property damage to increased costs of insurance, real assets will need to withstand natural hazards, both acute and chronic, and will require higher investments. We at Sustainability Economics help organizations account for your emissions as well as project your risks to ensure a resilient strategy.",
    learn: "",
  },
];
function Content() {
  const [state, setState] = useState(data);
  const [activeCard, setActiveCard] = useState(1);
  const navigate = useNavigate();
  const navigateFinance = () => {
    navigate("/solutions/finance");
  };


  return (
    <div>
      <div className={con.parentDiv}>
        <div data-aos="fade-up" data-aos-once="true" className={con.head}>
          <h1 style={{ display: "inline" }}>OUR NET-ZERO SOLUTIONS</h1>
        </div>
        <div data-aos="fade-up" data-aos-once="true" className={con.text}>
          Sustainability Economics focuses on the lifecycle management of E2E
          Net-Zero transitions across multiple sectors, with an automation-first
          approach.
        </div>
        {/* Changed for SEO*/}
      </div>
      <div className={con.mapflexDiv}>
        <div className={con.mapMainDiv}>
          {state.map((data) => {
            return (
              <div
                data-aos="fade-up"
                data-aos-once="true"
                className={con.MainDiv}
              >
                <div className={con.CardDiv}>
                  <img className={con.img} src={data.img} alt="/png" />
                  <div className={con.title}>{data.title}</div>
                  <div className={con.body}>{data.body}</div>
                  <div className={con.learn}>
                    <button
                      disabled={data.id !== activeCard}
                      onClick={navigateFinance}
                      className={`${data.learn}`}
                      style={data.id === 1 ? { color: "rgb(70 139 70)" } : {}}
                    >
                      Learn More
                    </button>
                    <span></span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={con.border}></div>
    </div>
  );
}

export default Content;

{
  /* <iframe
  src="https://player.vimeo.com/video/781777065?h=d74bbbafbd&badge=0&autopause=0&player_id=0&app_id=58479"
  frameborder="0"
  allow="autoplay; fullscreen; picture-in-picture"
  allowfullscreen
  style="position:absolute;top:0;left:0;width:100%;height:100%;"
  title="CDP 2023 Brand Film"
></iframe>; */
}
