import React from "react";
import Kasu from "../../assets/kasu.jpg";
import Nayani from "../../assets/nayani.png";
import Linkedin from "../../assets/linkedin.png";
import t from "./team.module.css";
function Team() {
  return (
    <div className={t.div}>
      <div data-aos="fade-up" data-aos-once="true"data-aos-delay="1000"  className={t.leadership}>OUR LEADERSHIP</div>
      <div className={t.parentMainDiv}>
        <div className={t.ParentDiv}>
          <div data-aos="fade-up" data-aos-once="true" data-aos-delay="100" className={t.FirstDiv}>
            <div className={t.img}>
              <img className={t.img} src={Kasu} alt="png" />
            </div>
            <div className={t.ceo}>Co-Founder & CEO</div>
            <div className={t.name}>Kasu Venkata Reddy</div>
            <div className={t.info}>
              Seasoned corporate executive with 25+ years of all round
              experience in Product Development, Consulting, and Sales. Recent
              position: Worked for Cisco as a CTO
            </div>
            <div className={t.imgL}>
            <a href="https://www.linkedin.com/in/venkata-reddy-kasu-0580823" target="_blank"><img className={t.linkedin} src={Linkedin} alt="" /></a>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-once="true" data-aos-delay="200" className={t.SecondDiv}>
            <div className={t.img}>
              <img className={t.img} src={Nayani} alt="png" />
            </div>
            <div className={t.ceo}>Co-Founder & Advisor</div>
            <div className={t.name}>Nayani Sreenadha Reddy</div>
            <div className={t.info}>
              Seasoned investor and Harvard Alumni with 25+ years of experience
              in strategy and investing in multiple sectors including IT,
              Biotech, Real Estate, Education.
            </div>
            <div className={t.imgL}>
              {/* <img className={t.linkedin} src={Linkedin} alt="" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
