import React from "react";
import ins from "./institution.module.css";
import Inv from "../../../assets/Investors.png";
import Bnk from "../../../assets/Banks.png";
import Insurers from "../../../assets/Insurers.png";
import Infy from "../../../assets/solutions/infinity-finacial.svg";

const Institution = () => {
  const Cards = [
    {
      img: Inv,
      title: "NET-ZERO INVESTOR PLATFORM",
      subtitle: "FOR investors (Am & ao)",
    },
    {
      img: Bnk,
      title: "NET-ZERO FIN PLATFORM",
      subtitle: "FOR Banks",
    },
    {
      img: Insurers,
      title: "NET-ZERO INSURER PLATFORM",
      subtitle: "FOR Insurers",
    },
  ];
  return (
    <div className={ins.parentDiv}>
      <div data-aos="fade-up" data-aos-once="true" className={ins.mainHeading}>
        Financial Institutions
      </div>
      <div data-aos="fade-up" data-aos-once="true" className={ins.subHeading}>
        Enable the end-to-end transition towards sustainable business with
        specialized products designed for your specific needs.
      </div>
      <div data-aos="fade-up" data-aos-once="true" className={ins.BoxDiv}>
        <div
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-delay="150"
          className={ins.finBox}
        >
          {Cards.map((item) => (
            <div className={ins.Cards}>
              <img className={ins.finImg} src={item.img} alt="svg" />
              <h2 className={ins.content}>{item.title}</h2>{" "}
              {/*Changed for SEO */}
              <div className={ins.subtitle}>{item.subtitle}</div>
            </div>
          ))}
        </div>
      </div>
      <div data-aos="zoom-in" data-aos-once="true" className={ins.imageDiv}>
        <div className={ins.infy_image}>
          <img src={Infy} className={ins.Infinity}></img>
        </div>
      </div>
    </div>
  );
};

export default Institution;
