import React from 'react'

import Content from "./content/content"

export default function Page() {
  return (
    <>
    
      <Content/>
    
    </>
  )
}
