import React from "react";
import { useNavigate } from "react-router-dom";

import ClemEventsImage from "../../component/assets/ClemEventsImage.jpg";
import TransitionFinanceImage from "../../component/assets/TransitionFinancingImage.jpg";
import CalendarIcon from "../../component/assets/calendarTimeIcon.svg";



import c from "./events.module.css";
import "bootstrap/dist/css/bootstrap.min.css";

const Events = () => {
  const navigate = useNavigate();

  const navigatePressRelease = (url) => {
    navigate(`/events`);
  };

  const navigatePressRelease2 = (url) => {
    navigate(`/events/clem-event2`);
  };

  return (
    <>
     
      <div className={c.ParentDiv}>
        <div
          className={c.card}
          onClick={() => navigatePressRelease("")}
        >
          <div className={c.imageContainer}>
            <img className={c.cardImage} src={ClemEventsImage}></img>
          </div>

          <div className={c.cardContent}>
            {/* <span className={c.cardContentSpan}> Singapore – 17 April 2024 </span>{" "}
            - Sustainability Economics, a leading provider of global net-zero
            solutions, has announced to choose Asia Carbon ... */}
            Sustainability Economics, an international entity headquartered in
            Singapore and US, with a big development center in ...
            {/* Bangalore
            providing end-to-end net-zero solutions across high emitting sectors
            for global businesses. Sustainability Economics brings CLEM (Clean
            Energy Mechanism), a first-of-its-kind end-to-end solution to
            accelerate and make clean energy transitions globally profitable. */}
          </div>

          <div className={c.cardDetails}>
            <img className={c.calendarIcon} src={CalendarIcon} alt="Calendar" />
            24th - 26th September 2024
          </div>
        </div>

        <div
          className={c.card}
        // onClick={() => navigatePressRelease2("clem-event2")}
        >
          <div className={c.imageContainer}>
            <img className={c.cardImage} src={TransitionFinanceImage}></img>
          </div>

          <div className={c.cardContent}>
            {/* <span className={c.cardContentSpan}> Singapore – 17 April 2024 </span>{" "}
            - Sustainability Economics, a leading provider of global net-zero
            solutions, has announced to choose Asia Carbon ... */}
            Sustainability Economics, an international entity headquartered in
            Singapore and US, with a big development center in ...
            {/* Bangalore
            providing end-to-end net-zero solutions across high emitting sectors
            for global businesses. Sustainability Economics brings CLEM (Clean
            Energy Mechanism), a first-of-its-kind end-to-end solution to
            accelerate and make clean energy transitions globally profitable. */}
          </div>

          <div className={c.cardDetails}>
            <img className={c.calendarIcon} src={CalendarIcon} alt="Calendar" />
            26th September 2024
          </div>
        </div>
      </div>
     

    </>
  );
};

export default Events;
