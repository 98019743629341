import React from "react";
import fc from "./FinancialCard.module.css";

export default function FinancialCard({ cardData }) {
    return (
        <div className={fc.cardContainer}>
            {cardData.map((card, index) => (
                <div key={index} className={fc.card}>
                    <div className={fc.cardImage}>
                        <img src={card.image} alt={card.name} />
                    </div>
                    <div className={fc.cardContent}>
                        {card.name}
                    </div>
                </div>
            ))}
        </div>
    );
}
