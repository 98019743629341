import React from 'react'

import c from "./pressReleaseCfpp.module.css";
import ACIImage from "../../../component/assets/acImage.png";

import yahooLogo from "../../../component/assets/yahooLogo.svg";
import quantLogo from "../../../component/assets/quantumLogo.svg";
import IEPLogo from "../../../component/assets/eveningLogo.svg";

const pressReleaseCfpp = () => {

    const redirectToPath = (link) => {
        window.open(link)
    }

    return (
        <>
        

            <div className={c.ParentDiv}>
                <div className={c.mainTitle}>
                    Sustainability Economics works with Asia Carbon Institute, of the
                    TRACTION group, to accelerate Phase-Out of Coal-Fired Power Plants in
                    Asia
                </div>

                <div className={c.imageContainer}>
                    <img src={ACIImage}></img>
                </div>

                <p className={c.subject}>
                    <span className={c.subjectWords}>Singapore,17 April 2024,</span>  Sustainability Economics, a leading provider
                    of global net-zero solutions, has announced to choose Asia Carbon
                    Institute (ACI) as the standard for evaluating our transition credits
                    methodology that aims to expedite the phase-out of coal-fired power
                    plants (CFPPs) in the Asia region.
                </p>

                <div className={c.secondPara}>
                    <span className={c.articleDescription}>
                        ACI is an independent non-profit organisation dedicated to
                        establishing voluntary carbon credit standards and a registry. It is
                        committed to accelerate the transition to a carbon-neutral economy by
                        producing credible, transparent, and high-quality carbon credits. ACI
                        is also a member of the TRACTION group led by the Monetary Authority
                        of Singapore (MAS), focused on developing transition credits as a
                        complimentary financial instrument for the early retirement of Asia’s
                        coal plants.
                    </span>
                </div>

                <div className={c.secondPara}>
                    <span className={c.articleDescription}>
                        Sustainability Economics is a leading global provider of end-to-end
                        net-zero solutions across multiple sectors. Their expertise is a blend
                        of extensive domain knowledge with state-of-the-art digital platforms
                        and advanced technologies, prioritising lifecycle management and
                        automation by connecting the dots to deliver comprehensive net-zero
                        transition solutions for high-emission sectors.
                    </span>
                </div>

                <article className={c.articleDiv}>
                    <div className={c.articleHeading}>
                        Kasu Venkata Reddy, Co-Founder/CEO of Sustainability Economics:
                        <span className={c.articleDescription}>
                            "We are thrilled to partner with Asia Carbon Institute in our shared
                            mission to accelerate the transition towards clean energy sources in
                            Asia. This collaboration underscores our commitment to driving
                            sustainable solutions and combating climate change."
                        </span>
                    </div>
                </article>

                <article className={c.articleDiv}>
                    <div className={c.articleHeading}>
                        John Lo, Co-Founder/CEO of Asia Carbon Institute:
                        <span className={c.articleDescription}>
                            "We are pleased that Sustainability Economics has chosen ACI as the
                            standard of choice for evaluating their transition credit
                            methodology. This marks a significant milestone in our efforts to
                            establish transparent standards for carbon credits and accelerate
                            the transition to a carbon-neutral economy."
                        </span>
                    </div>
                </article>

                <article className={c.articleDiv}>
                    <div className={c.articleHeading}>
                        Transition Credits
                        <div className={c.articleDescription}>
                            Coal-fired power generation remains a significant contributor to
                            global carbon emissions, particularly in Asia where a concentration
                            of young CFPPs exists, averaging less than 15 years old. To address
                            this pressing issue, Sustainability Economics will work with ACI and
                            submit the methodology that facilitate the transition of CFPPs to
                            clean energy sources. This methodology aims to create high-integrity
                            Transition Credits, a financial instrument designed to accelerate
                            the early retirement of CFPPs in Asia.
                        </div>
                    </div>
                </article>

                <article className={c.articleDiv}>
                    <div className={c.articleHeading}>
                        Market Driven Solution
                        <div className={c.articleDescription}>
                            Transition Credits, generated through the closure of CFPPs and their
                            replacement with clean energy sources, provide a market-driven
                            solution to enhance the economic viability and scalability of early
                            retirement transactions. These credits adhere to the Core Carbon
                            Principles (CCP) established by the International Carbon Value Chain
                            Management (ICVCM), ensuring credibility and alignment with
                            Sustainable Development Goals (SDGs).
                        </div>
                    </div>
                </article>

                <article className={c.articleDiv}>
                    <div className={c.articleHeading}>
                        Practical and Crafted Methodology
                        <div className={c.articleDescription}>
                            Sustainability Economics' methodology, rooted in practicality and
                            crafted with input from CFPP owners and renewable energy developers
                            throughout Asia, emphasizes a data-driven approach and the use of
                            digital platforms and state-of-the-art technologies to automate the
                            process of producing Transition Credits from initiation to
                            completion.
                        </div>
                    </div>
                </article>

                <div className={c.articleHeadingContact}>
                    Contact:
                    <span className={c.articleDescription}>

                    </span>
                </div>

                <div className={c.contactMain}>
                    <div className={c.contactSubContainer}>

                        <div className={c.articleHeading}>Esther Benschop</div>

                        <div className={c.contactInfoContainer}>Communications manager</div>

                        <div className={c.contactInfoContainer}>Phone: +65 98851574</div>

                        <div className={c.contactInfoContainer}>
                            Email:
                            <a className={c.emailContainer} href="mailto:esther@sustainabilityeconomics.com">esther@sustainabilityeconomics.com</a>
                        </div>

                    </div>

                </div>

                <div className={c.adContainer}>

                    <div className={c.adContainerHeading}>
                        Coverage Across
                    </div>

                    <div className={c.adLogoContainer}>

                        <div onClick={() => redirectToPath("https://finance.yahoo.com/news/sustainability-economics-works-asia-carbon-000000070.html")}>
                            <img src={yahooLogo} alt="Yahoo Logo"></img>
                        </div>

                        <div onClick={() => redirectToPath("https://indiaeveningpost.com/sustainability-economics-works-with-asia-carbon-institute-of-the-traction-group-to-accelerate-phase-out-of-coal-fired-power-plants-in-asia/")}>
                            <img src={IEPLogo}></img>
                        </div>

                        <div onClick={() => redirectToPath("https://www.qcintel.com/carbon/article/asia-carbon-institute-to-evaluate-transition-credit-methodology-23611.html")}>
                            <img src={quantLogo} ></img>
                        </div>

                    </div>

                </div>

            </div>
            
        </>
    )
};
export default pressReleaseCfpp;