import React from "react";
import { useEffect } from "react";
import Ins from "./institution/institution.js";
import FinCard from "./finCards/finCards.js";
import Video from "./video/video.js";

const Main = () => {
  useEffect(() => {
    window.scrollTo(-1, -1);
  }, []);
  return (
    <>
     
      <Video />
      <Ins />
      <FinCard />
     
    </>
  );
};

export default Main;
