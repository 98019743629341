import React from 'react'
import Footer from '../home/Footer/footer'
import Header from '../home/header/header'
import Blog from './blog'

function BlogMain() {
  return (
    <div>
        <Header />
        <Blog />
        <Footer />
    </div>
  )
}

export default BlogMain