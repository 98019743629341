import DigitalPlatformDecarbCard from "./digitalPlatformDecarbCard/digitalPlatformDecarbCard";
import dpd from "./digitalPlatformDecarb.module.css";

export default function DigitalPlatformDecarb() {
  const myStrings = [
    "Accounting",
    "Carbon Accounting",
    "Target Setting and Peer Benchmarking",
  ];
  const reporting = [
    "Reporting",
    "Country specific",
    "Standard specific",
    "Bespoke",
  ];
  const climateScenarioAnalysis = [
    "Climate Scenario Analysis",
    "Transition Risk",
    "Physical Risk",
  ];
  const transitionFinancing = [
    "Transition Financing",
    "Philanthropic Funds",
    "Concessional Funds",
    "Climate Bonds",
    "Transition Credits",
  ];

  const carbonMarkets = [
    "Carbon Markets",
    "Methodology Developer",
    "Project Proponent / Project Developer",
    "Marketplace",
  ];

  const sustainableProductsMarketplace = [
    "Sustainable Products Marketplace",
    "Green Cement",
    "Green Steel",
  ];

  return (
    <div className={dpd.dpdMainDiv}>
      <DigitalPlatformDecarbCard pointers={myStrings} />
      <DigitalPlatformDecarbCard pointers={reporting} />
      <DigitalPlatformDecarbCard pointers={climateScenarioAnalysis} />

      <DigitalPlatformDecarbCard pointers={transitionFinancing} />
      <DigitalPlatformDecarbCard pointers={carbonMarkets} />
      <DigitalPlatformDecarbCard pointers={sustainableProductsMarketplace} />

    </div>
  );
}
