import { useState, useEffect } from 'react';

const useMobile = () => {
  const [mobileVersion, setMobileVersion] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setMobileVersion(true);
    }

    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileVersion(true);
      } else {
        setMobileVersion(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return mobileVersion;
};

export default useMobile;
