import { React } from "react";
import "./video.css";
import { useNavigate } from "react-router-dom";
function Video() {
  const navigate = useNavigate();
  const navigateContact = () => {
    navigate("/contact");
  };
  return (
    <div className="video1">
      <div className="overlay"></div>
      <div className="vs">
        <video
          className="video"
          controls={false}
          autostart="true"
          autoPlay
          loop
          muted
          src={
            "https://sen-website-video.s3.ap-south-1.amazonaws.com/financial-instutions-video.mp4"
          }
          type="video/mp4"
        />
      </div>
      <div data-aos="fade-right" data-aos-once="true" className={`headingPart`}>
        <div className={`heading`}>
          Accelerate the Transition Towards Net-Zero.
        </div>
        <div className={`heading2`}>
          Explore Sustainability Economics Solutions to take action on Climate
          Change
        </div>
      </div>
    </div>
  );
}

export default Video;
