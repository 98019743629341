import React, { useRef } from "react";
import { useEffect, useState } from "react";

import Form from "../../form/Form.js";
import Dropdown from "../../../component/Core/Dropdown/Dropdown.js";

import Bg from "../bg/bg.js";


import ClemInfoImage from "../../assets/clemStrategy.jpg";
import ClemBackground from "../../assets/ClemBlueBackground.svg";

import SeMainLogo from "../../assets/SeMainLogo";
// import SeLogo from "../../assets/companyLogos/SELogo";
import GPCLLogo from "../../assets/companyLogos/GPCLLogo";
// import IEALogo from "../../assets/companyLogos/IEALogo";
// import IRENALogo from "../../assets/companyLogos/IRENALogo";
// import SinghaniaLogo from "../../assets/companyLogos/SinghaniaLogo";
// import SUCOFINDOLogo from "../../assets/companyLogos/SucoFindoLogo";
import ACILogo from "../../assets/companyLogos/ACILogo";
import SMBCLogo from "../../assets/companyLogos/SMBCLogo";
import ClimateXLogo from "../../assets/companyLogos/ClimateXLogo";
import GFanzLogo from "../../assets/companyLogos/GFanzLogo";
import IDXCarbonLogo from "../../assets/companyLogos/IDXCarbonLogo";
import {ReactComponent as ClimateBondsInitiative} from "../../assets/icons/climateBondsInitiative.svg";
import{ReactComponent as  CleanLogo} from "../../assets/companyLogos/clean.svg";
import  GCFLogo from "../../assets/companyLogos/GCFLogo.png";
import { ReactComponent as CalenderIcon } from "../../../component/assets/calander.svg";
// import { ReactComponent as ClockIcon } from "../../../component/assets/squareclock.svg";
import { ReactComponent as UserIcon } from "../../../component/assets/person.svg";
import { ReactComponent as LocationIcon } from "../../../component/assets/location.svg";
import { ReactComponent as IdxIcon } from "../../../component/assets/idxcarbon.svg";
// import { ReactComponent as JacobSolutions } from "../../../component/assets/jacobSolutions.svg";
import { ReactComponent as CfAsia } from "../../assets/companyLogos/CFAsia.svg";
import { ReactComponent as NRELOGO } from "../../assets/icons/nrelLogo.svg";
import  { ReactComponent as Newmarketcapital }  from "../../assets/icons/NewmarketCapital.svg";
import  { ReactComponent as AnthropoceneFixed }  from "../../assets/icons/anthropoceneFixed .svg";
import  { ReactComponent as TaraClimateFoundation }  from "../../assets/icons/taraClimate.svg";
import { ReactComponent as ArticleSixGroup } from "../../../component/assets/icons/articleSix.svg";





import RealityCheckCard from "./RealityCheckCard/RealityCheckCard.js";

import e from "./eventsClem1.module.css";
import IntroCard from "./IntroCard/IntroCard.js";

const registrationUrl =
  "https://events.teams.microsoft.com/event/f938eeb2-36ae-4f29-b665-c1548ee9a8f2@a8606430-fd02-4b66-b04e-e614589bb5ae/registration";

const mainEventContent = {
  networkingBreak1: "10:20 AM - 10:40 AM",
  lunchBreak: "12:00 PM - 1:30 PM",
  networkingBreak2: "2:50 PM - 3:10 PM",
  networkingBreak3: "4:10 PM - 4:30 PM",
  networkingBreak4: "5:00 PM - 6:00 PM",
  intro: {
    text: "Registration",
    subText: "Networking",
    timings: "8:00 AM - 8:30 AM",
  },
  intro1: {
    text: "Introduction",
    subText: "Introductory Note",
    timings: "8:30 AM - 8:40 AM",
  },
  intro2: {
    text: "CLEM Introduction",
    subText:
      "CLEM (Clean Energy Mechanism) - Overview By Kasu Venkata Reddy, CEO and Co-founder of Sustainability Economics, outlining CLEM’s vision to transform coal-fired power plants into profitable clean energy sources.",
    timings: "8:40 AM - 9:00 AM",
  },
  intro3: {
    text: "CLEM - Energy Mix demonstration with real-time customer case study",
    subText:
      "Model-driven approach to identify right renewable energy mix (solar, wind, battery) combination mainly covering capacity, land, CUF, cost required to replace CFPP based on location (lat-long).",
    timings: "10:00 AM - 10:20 AM",
  },
  intro4: {
    text: "CLEM - Transition financing demonstration with real-time customer case study ",
    subText:
      "Detailed financial analysis to demonstrate that CFPP transitions are profitable with transition financing instruments.",
    timings: "11:40 AM - 12:00 PM",
  },
  intro5: {
    text: "CLEM - Financing analysis with transition credits cashflows  demonstration, featuring real-time customer case study",
    subText:
      "Detailed financial analysis to demonstrate that CFPP transitions are profitable with transition credit cashflow combinations.",
    timings: "2:30 PM - 2:50 PM",
  },
  intro6: {
    text: "CLEM - End to End demonstration with real-time customer case study",
    subText:
      "CLEM's end-to-end real-time case study showcasing a profitable transition from coal-fired power plants to clean energy sources.",
    timings: "4:30 PM - 5:00 PM",
  },
  realityCheck: {
    title: "Panelists",
    timings: "9:00 AM - 10:00 AM",
    mainTitle: "Moderator",
    header:
      "Reality check on the readiness of clean energy sources to replace traditional CFPPs as a round-the-clock source",
    subHeader:
      "A panel discussion about the readiness of clean energy sources to replace traditional CFPPs as a round-the-clock source. ",
    names: [
      <>
        {
          <span
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/arun-mahesh-babu-m-s-ias-97a4b922/",
                "_blank"
              )
            }
            className={e.panelistsNamesSpan}
          >
            Arun Mahesh Babu
          </span>
        }
        , Managing Director, Gujarat Power Corporation Limited (India)
      </>,
      <>
        {
          <span
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/paul-jacobson-b3716b13/",
                "_blank"
              )
            }
            className={e.panelistsNamesSpan}
          >
            Paul Jacobson
          </span>
        }
        , President, Clean Energy Bridge
      </>,

<>
{
  <span
    onClick={() =>
      window.open(
        "https://www.linkedin.com/in/sarah-kurtz-1167911/",
        "_blank"
      )
    }
    className={e.panelistsNamesSpan}
  >
    Sarah Kurtz
  </span>
}
, Principal Scientist, National Renewable Energy Laboratory
</>,
<>
{
  <span
    onClick={() =>
      window.open(
        "https://www.linkedin.com/in/peter-f-green-9609b7230/overlay/about-this-profile/",
        "_blank"
      )
    }
    className={e.panelistsNamesSpan}
  >
    Peter F. Green
  </span>
}
, Deputy Laboratory Director & Chief Research Officer, National Renewable Energy Laboratory
</>,
    
    ],
    moderator: [      <>
      {
        <span
          onClick={() =>
            window.open(
              "https://www.linkedin.com/in/venkata-reddy-kasu-0580823/",
              "_blank"
            )
          }
          className={e.panelistsNamesSpan}
        >
          Kasu Venkata Reddy
        </span>
      }
      , CEO & Co-founder, Sustainability Economics
    </>,],
    panelistsImages: [
      <GPCLLogo height={68} width={168} />,
      <SeMainLogo height={68} width={168} />,
      // <JacobSolutions width={168} height={68} />,
      <NRELOGO width={168} height={68}/>,
      <CleanLogo width={168} height={68}/>
      
    ],
  },
  realityCheck1: {
    title: "Panelists",
    timings: "10:40 AM - 11:40 AM",
    header:
      "Innovative transition financing instruments (equity, debt, concessional funds) to accelerate managed phase-out of CFPPs",
    subHeader:
      "A panel discussion revolving around innovative transition financing instruments, including equity and debt options, designed to accelerate the managed phase-out of CFPPs.",
    names: [
      <>
        {
          <span
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/yuki-yasui-b029a71b/",
                "_blank"
              )
            }
            className={e.panelistsNamesSpan}
          >
            Yuki Yasui
          </span>
        }
        , Managing Director, Asia Pacific Network Glasgow Financial Alliance for
        Net Zero (GFANZ)
      </>,
      <>
        {
          <span
            onClick={() =>
              window.open("https://www.linkedin.com/in/dolph-habeck-8bb0464/")
            }
            className={e.panelistsNamesSpan}
          >
            Dolph Habeck
          </span>
        }
        , Head of Sustainable Solutions, Americas, SMBC Group
      </>,
      <>
      {
        <span
          onClick={() =>
            window.open(
              "https://www.linkedin.com/in/dr-eng-ana-diaz-vazquez-1b71321a6/"
            )
          }
          className={e.panelistsNamesSpan}
        >
          Ana Diaz
        </span>
      }
      , Global Energy Transition lead, Climate Bonds Initiative
    </>,
    <>
    {
      <span
        onClick={() =>
          window.open(
            "https://www.linkedin.com/in/andrew-hohns-6901226/"
          )
        }
        className={e.panelistsNamesSpan}
      >
        Andrew Hohns
      </span>
    }
    , Chief Executive Officer, Newmarket Capital
  </>,
      <>
      {
        <span
          onClick={() =>
            window.open(
              "https://www.linkedin.com/in/ulf-erlandsson/"
            )
          }
          className={e.panelistsNamesSpan}
        >
          Ulf Erlandsson
        </span>
      }
      , CEO & Founder, Anthropocene Fixed Income Institute (AFII)
    </>,
    
    ],
    mainTitle: "Moderator",
    moderator: [      <>
      {
        <span
          onClick={() =>
            window.open(
              "https://www.linkedin.com/in/venkata-reddy-kasu-0580823/",
              "_blank"
            )
          }
          className={e.panelistsNamesSpan}
        >
          Kasu Venkata Reddy
        </span>
      }
      , CEO & Co-founder, Sustainability Economics
    </>,],
    panelistsImages: [
      <GFanzLogo height={64} width={140} />,
      <SMBCLogo height={68} width={140} />,
      <SeMainLogo height={68} width={138} />,
      <ClimateBondsInitiative height={68} width={138} />,
      <Newmarketcapital height={68} width={140} />,
      <AnthropoceneFixed height={68} width={150} />,

    ]
  },
  realityCheck2: {
    title: "Panelists",
    timings: "1:30 PM - 2:30 PM",
    header:
      "Transition credits, a new financial instrument, to accelerate and enable profitable CFPP transitions + marketplace and off-takers",
    subHeader:
      "A panel discussion about leveraging transition credits to accelerate CFPP transitions while making them profitable, along with conversations around the marketplace and off-takers for these credits. ",
    names: [
      <>
        {
          <span
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/john-lo-a2738225a/",
                "_blank"
              )
            }
            className={e.panelistsNamesSpan}
          >
            John Lo
          </span>
        }
        , Founder, Asia Carbon Institute
      </>,
      <>
        {
          <span
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/mikkellarsen1/",
                "_blank"
              )
            }
            className={e.panelistsNamesSpan}
          >
            Mikkel Larsen
          </span>
        }
        , Executive Board Member, Climate Impact X
      </>,
      <>
        {
          <span
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/ignatius-denny-wicaksono-cfa-frm-cipm-3622b236/",
                "_blank"
              )
            }
            className={e.panelistsNamesSpan}
          >
            Ignatius Denny Wicaksono
          </span>
        }
        , Executive Vice President & Business Development, Indonesia Carbon
        Exchange
      </>,
      <>
      {
        <span
          onClick={() =>
            window.open(
              "https://www.linkedin.com/in/elizabeth-willmott-26b9734/",
              "_blank"
            )
          }
          className={e.panelistsNamesSpan}
        >
          Elizabeth Willmott
        </span>
      }
      , Principal, Earthrise Consulting, LLC
    </>,
    <>
    {
      <span
        onClick={() =>
          window.open(
            "https://www.linkedin.com/in/ely-sandler/",
            "_blank"
          )
        }
        className={e.panelistsNamesSpan}
      >
        Ely Sandler
      </span>
    }
    , Managing Partner, Article Six Group and Fellow at the Harvard Kennedy School
  </>,
    ],
    mainTitle: "Moderator",
    moderator: [      <>
      {
        <span
          onClick={() =>
            window.open(
              "https://www.linkedin.com/in/venkata-reddy-kasu-0580823/",
              "_blank"
            )
          }
          className={e.panelistsNamesSpan}
        >
          Kasu Venkata Reddy
        </span>
      }
      , CEO & Co-founder, Sustainability Economics
    </>,],
    panelistsImages: [
      <SeMainLogo height={68} width={168} />,
      <ACILogo height={68} width={168} />,
      // <SMBCLogo height={58} width={148} />,
      <ClimateXLogo height={88} width={208} />,
      <IDXCarbonLogo height={68} width={148} />,
      <ArticleSixGroup height={98} width={148} />,

    ],
  },
  realityCheck3: {
    title: "Panelists",
    timings: "2:50 PM - 3:30 PM",
    header: "Marketplace and off-takers for transition credits",
    names: [
      <>
        {
          <span
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/cedric-rimaud-6695936/",
                "_blank"
              )
            }
            className={e.panelistsNamesSpan}
          >
            Cedric Rimaud
          </span>
        }
        , Deputy General Manager, SMBC Group
      </>,
      <>
        {
          <span
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/venkata-reddy-kasu-0580823/",
                "_blank"
              )
            }
            className={e.panelistsNamesSpan}
          >
            Kasu Venkata Reddy
          </span>
        }
        , CEO & Co-founder, Sustainability Economics
      </>,
      <>
        {
          <span
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/mikkellarsen1/",
                "_blank"
              )
            }
            className={e.panelistsNamesSpan}
          >
            Mikkel Larsen
          </span>
        }
        , Executive Board Member, Climate Impact X
      </>,
      <>
        {
          <span
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/ignatius-denny-wicaksono-cfa-frm-cipm-3622b236/",
                "_blank"
              )
            }
            className={e.panelistsNamesSpan}
          >
            Ignatius Denny Wicaksono
          </span>
        }
        , Executive Vice President & Business Development, Indonesia Carbon
        Exchange
      </>,
    ],
    panelistsImages: [
      <SMBCLogo height={58} width={148} />,
      <SeMainLogo height={68} width={168} />,
      <ClimateXLogo height={88} width={168} />,
      <IdxIcon />,
    ],
  },
  realityCheck4: {
    title: "Panelists",
    timings: "3:10 PM -  4:10 PM",
    header: "Financing Just Transition",
    subHeader: "A panel discussion around financing just transitions.",
    names: [
      
      <>
        {
          <span
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/cedric-rimaud-6695936/",
                "_blank"
              )
            }
            className={e.panelistsNamesSpan}
          >
            Cedric Rimaud
          </span>
        }
        , Deputy General Manager, SMBC Group
      </>,
      <>
      {
        <span
          onClick={() =>
            window.open(
              "https://www.linkedin.com/in/chan-yau-chong-44b03916/",
              "_blank"
            )
          }
          className={e.panelistsNamesSpan}
        >
          Chan-yau Chong
        </span>
      }
      , Member Board of Directors (Climate Finance Asia), Chief Executive Officer at CarbonCare InnoLab
    </>,
      <>
      {
        <span
          onClick={() =>
            window.open(
              "https://www.linkedin.com/in/andrea-guerrero-09073721/",
              "_blank"
            )
          }
          className={e.panelistsNamesSpan}
        >
          Andrea Guerrero-Garcia
        </span>
      }
      , Director of Field Innovation, Growald Climate Fund
    </>,
    <>
    {
      <span
        onClick={() =>
          window.open(
            "https://www.linkedin.com/in/jamie-choi-0058728/",
            "_blank"
          )
        }
        className={e.panelistsNamesSpan}
      >
        Jamie Choi
      </span>
    }
    , Chief Executive Officer, Tara Climate Foundation
  </>,
    ],
    // mainTitle: "Moderator",
    // moderator: [      <>
    //   {
    //     <span
    //       onClick={() =>
    //         window.open(
    //           "https://www.linkedin.com/in/arun-mahesh-babu-m-s-ias-97a4b922/",
    //           "_blank"
    //         )
    //       }
    //       className={e.panelistsNamesSpan}
    //     >
    //       Arun Mahesh Babu
    //     </span>
    //   }
    //   , Managing Director, Gujarat Power Corporation Limited (India)
    // </>,],
    panelistsImages: [
      // <GFanzLogo height={68} width={140} />,
      <SMBCLogo height={68} width={140} />,
      <CfAsia width={108} height={58} />,
      <TaraClimateFoundation width={108} height={58} />,
      <img src={GCFLogo} alt="GCF Logo" style={{width:148,height:68}} />
      
    ],
  },
};

const breakOutSessions = [
  {
    intro: {
      text: "Registration",
      subText: "Networking",
      timings: "3:30 PM - 4:00 PM",
    },
    realityCheck: {
      title: "Speakers",
      timings: "4:00 PM - 5:30 PM",
      header:
        "Philanthropic funds support to accelerate CFPPs to clean energy sources transitions.",
      names: [
        <>
        {
          <span
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/yuki-yasui-b029a71b/",
                "_blank"
              )
            }
            className={e.panelistsNamesSpan}
          >
            Yuki Yasui
          </span>
        }
        , Managing Director, Asia Pacific Network Glasgow Financial Alliance for
        Net Zero (GFANZ)
      </>,
        <>
          {
            <span
              onClick={() =>
                window.open("https://www.linkedin.com/in/dolph-habeck-8bb0464/")
              }
              className={e.panelistsNamesSpan}
            >
              Dolph Habeck
            </span>
          }
          , Head of Sustainable Solutions, Americas, SMBC Group
        </>,
        <>
          {
            <span
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/cedric-rimaud-6695936/",
                  "_blank"
                )
              }
              className={e.panelistsNamesSpan}
            >
              Cedric Rimaud
            </span>
          }
          , Deputy General Manager, SMBC Group
        </>,
        <>
          {
            <span
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/venkata-reddy-kasu-0580823/",
                  "_blank"
                )
              }
              className={e.panelistsNamesSpan}
            >
              Kasu Venkata Reddy
            </span>
          }
          , CEO & Co-founder, Sustainability Economics
        </>,
      ],
      panelistsImages: [
        <GFanzLogo height={68} width={148} />,
        <SMBCLogo height={68} width={148} />,
        <SeMainLogo height={68} width={148} />,
      ],
    },
  },
  {
    intro: {
      text: "Registration",
      subText: "Networking",
      timings: "8:30 AM - 9:00 AM",
    },
    realityCheck: {
      title: "Speakers",
      timings: "9:00 AM - 10:30 AM",
      header:
        "Equity players opportunities in transition financing for  CFPPs to clean energy source transitions",
      names: [
        <>
          {
            <span
              onClick={() =>
                window.open("https://www.linkedin.com/in/dolph-habeck-8bb0464/")
              }
              className={e.panelistsNamesSpan}
            >
              Dolph Habeck
            </span>
          }
          , Head of Sustainable Solutions, Americas, SMBC Group
        </>,
        <>
          {
            <span
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/cedric-rimaud-6695936/",
                  "_blank"
                )
              }
              className={e.panelistsNamesSpan}
            >
              Cedric Rimaud
            </span>
          }
          , Deputy General Manager, SMBC Group
        </>,
        <>
          {
            <span
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/venkata-reddy-kasu-0580823/",
                  "_blank"
                )
              }
              className={e.panelistsNamesSpan}
            >
              Kasu Venkata Reddy
            </span>
          }
          , CEO & Co-founder, Sustainability Economics
        </>,
      ],
      panelistsImages: [
        <SMBCLogo height={68} width={148} />,
        <SeMainLogo height={68} width={148} />,
      ],
    },
  },
  {
    intro: {
      text: "Registration",
      subText: "Networking",
      timings: "8:30 AM - 9:00 AM",
    },
    realityCheck: {
      title: "Speakers",
      timings: "9:00 AM - 10:30 AM",
      header: "Marketplace and off-takers for transition credits",
      names: [
        <>
          {
            <span
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/cedric-rimaud-6695936/",
                  "_blank"
                )
              }
              className={e.panelistsNamesSpan}
            >
              Cedric Rimaud
            </span>
          }
          , Deputy General Manager, SMBC Group
        </>,
        <>
          {
            <span
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/venkata-reddy-kasu-0580823/",
                  "_blank"
                )
              }
              className={e.panelistsNamesSpan}
            >
              Kasu Venkata Reddy
            </span>
          }
          , CEO & Co-founder, Sustainability Economics
        </>,
        <>
        {
          <span
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/mikkellarsen1/",
                "_blank"
              )
            }
            className={e.panelistsNamesSpan}
          >
            Mikkel Larsen
          </span>
        }
        , Executive Board Member, Climate Impact X
      </>,
        <>
          {
            <span
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/ignatius-denny-wicaksono-cfa-frm-cipm-3622b236/",
                  "_blank"
                )
              }
              className={e.panelistsNamesSpan}
            >
              Ignatius Denny Wicaksono
            </span>
          }
          , Executive Vice President & Business Development, Indonesia Carbon
          Exchange
        </>,
      ],
      panelistsImages: [
        <SMBCLogo height={68} width={168} />,
        <SeMainLogo height={68} width={168} />,
        <ClimateXLogo height={68} width={168} />,
        <IdxIcon />,
      ],
    },
  },
];

const EventsClem1 = () => {
  const tabContentRef = useRef(null);

  const [formVisibility, setFormVisibility] = useState(null);
  const [readMore, setReadMore] = React.useState(false);
  const [activeDay, setActiveDay] = React.useState(0);

  const [activeCard, setActiveCard] = useState(0);

  const registerBtnRef = useRef(null);
  const registerTopBtnRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [mobileVersion, setMobileVersion] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 1000) {
      setMobileVersion(true);
    }

    const handleResize = () => {
      if (window.innerWidth < 1000) {
        setMobileVersion(true);
      } else {
        setMobileVersion(false);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const registerClickHandler = () => {
    const element = registerBtnRef.current;
    const elementRect = element.getBoundingClientRect();
    const absoluteElementTop = elementRect.top + window.pageYOffset;
    const middle =
      absoluteElementTop - window.innerHeight / 2 + elementRect.height / 2;

    window.scrollTo({
      top: middle,
      behavior: "smooth",
    });

    setFormVisibility("bottom");
  };

  const registerTopClickHandler = () => {
    const element = registerTopBtnRef.current;

    console.log("reached here:: ", element);

    const elementRect = element.getBoundingClientRect();
    const absoluteElementTop = elementRect.top + window.pageYOffset;
    const middle =
      absoluteElementTop -
      window.innerHeight / 2 +
      elementRect.height / 2 -
      100;

    window.scrollTo({
      top: middle,
      behavior: "smooth",
    });

    setFormVisibility("top");
  };

  const blackScreenClickHandler = () => {
    setFormVisibility(null);
  };

  const successHandler = () => {
    setFormVisibility(null);
  };

  // useEffect(() => {
  //   if (tabContentRef.current) {
  //     const top = tabContentRef.current.offsetTop;

  //     const top1 = tabContentRef.current.scrollTop;

  //     console.log(
  //       tabContentRef.current.scrollY,
  //       top,
  //       top1,
  //       window.scrollY,
  //       "offestTop:: "
  //     );

  //     // window.scrollTo({
  //     //   top: top,
  //     //   behavior: 'smooth'
  //     // });

  //     // window.scrollBy(0, top);

  //     tabContentRef.current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "start",
  //     });
  //   }
  // }, [activeCard]);

  return (
    <>
    
      <Bg img={ClemBackground} mobileVersion={mobileVersion} />

      <div className={e.EventsParent}>
        <div className={e.AboutDiv}>
          <div className={e.AboutDivHeader}>ABOUT</div>

          <div className={e.AboutDivDes}>
            Sustainability Economics, an international entity headquartered in
            Singapore and US, with a big development center in Bangalore
            providing end-to-end net-zero solutions across high emitting sectors
            for global businesses. Sustainability Economics brings CLEM (Clean
            Energy Mechanism), a first-of-its-kind end-to-end solution to
            accelerate and make clean energy transitions globally profitable.
          </div>
        </div>

        <div className={e.AboutDiv}>
          <div className={e.AboutDivHeader}>
            CLEM - PRACTICAL AND CRAFTED APPROACH
          </div>

          <div className={e.AboutDivDes}>
            Coal-fired power generation remains a significant contributor to
            global carbon emissions, particularly in Asia, where a concentration
            of young CFPPs exists, averaging less than 15 years old. To address
            this pressing issue, CLEM, a first-of-its-kind clean energy
            transition tailor-made solution, takes a practical data-driven
            approach working with CFPP asset owners, power purchasers and
            policymakers by connecting the dots to automate, simplify and scale
            the clean energy transitions.
          </div>
        </div>

        {readMore && (
          <>
            <div className={e.AboutDiv}>
              <div className={e.AboutDivHeader}>
                CLEM VISION - MAKING ENERGY TRANSITIONS PROFITABLE
              </div>

              <div className={e.AboutDivDes}>
                CLEM’s vision is to make energy transition not just practical
                but profitable for CFPP asset owners and financiers. Leveraging
                a cutting-edge, data-driven approach, CLEM’s tailor-made
                solutions provide the most effective and strategic clean energy
                transition planning while ensuring profitability with a
                make-it-happen approach.
              </div>

              <img
                src={ClemInfoImage}
                alt="ClemInfoImage"
                className={e.ClemInfoImage}
              />
            </div>

            <div className={e.AboutDiv}>
              <div className={e.AboutDivHeader}>KEY OUTCOMES:</div>

              {keyOutcomesData.map((data, index) => (
                <div className={e.OutcomesContainer} key={index}>
                  <ul>
                    <li>
                      <span className={e.OutcomesContainerKey}>
                        {data.title} -{" "}
                      </span>{" "}
                      {data.description}
                    </li>
                  </ul>
                </div>
              ))}
            </div>
          </>
        )}

        <div
          className={e.ReadMore}
          style={{ cursor: "pointer" }}
          onClick={() => setReadMore(!readMore)}
        >
          {!readMore ? "Read more" : "Read less"}
        </div>

        {mobileVersion ? (
          <div className={e.fixedContainer}>
            <div className={e.selectTabContainer}>
              <div
                className={`${e.tabItem} ${
                  activeCard === 0 ? e.tabItemActive : ""
                }`}
                onClick={() => setActiveCard(0)}
              >
                Main Event
              </div>

              <div
                className={`${e.tabItem} ${
                  activeCard === 1 ? e.tabItemActive : ""
                }`}
                onClick={() => setActiveCard(1)}
              >
                Breakout Sessions
              </div>
            </div>

            {activeCard === 0 ? (
              <div
                className={`${e.cardEvent} ${e.mainCardEvent} ${e.mobileCard}`}
              >
                <div className={e.cardHeaderEvent}>
                  <div>
                    CLEM (Clean Energy Mechanism)- Making Coal Fired Power
                    Plants to Clean Energy Sources Transitions Profitable
                  </div>
                </div>
                <div className={e.bodyContainerEvent}>
                  <div className={e.headerIconEvent}>
                    <div className={e.iconBoxEvent}>
                      <div className={e.icon}>
                        <CalenderIcon />
                      </div>
                      <div>
                        26<sup>th</sup> Sept ‘2024
                      </div>
                    </div>
                    <div className={e.iconBoxEvent}>
                      {/* <div className={e.icon}>
                        <ClockIcon />
                      </div>
                      <div>8:00 AM - 6:00 PM</div> */}
                    </div>
                  </div>
                  <div className={e.iconBoxEvent}>
                    <div className={e.icon}>
                      <UserIcon />
                    </div>
                    <div>Format: Hybrid</div>
                  </div>
                  <div className={e.footerIcon}>
                    <div className={e.iconBoxEvent}>
                      <div className={e.icon}>
                        <LocationIcon />
                      </div>
                      <div>One World Trade Center 285 Fulton Street, 64th Floor New York, NY 10007</div>
                    </div>
                  </div>
                  <div className={e.bodyContainerEvent}>
                    <button
                      className={e.btn}
                      onClick={() => window.open(registrationUrl, "_blank", "noopener,noreferrer")}
                      ref={registerTopBtnRef}
                    >
                      Register now
                    </button>
                    {formVisibility === "top" && (
                      <>
                        <div
                          className={e["black-screen"]}
                          onClick={blackScreenClickHandler}
                        ></div>
                        <div className={e["form-container"]}>
                          <Form
                            onSuccess={successHandler}
                            onCancel={blackScreenClickHandler}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : null}

            {activeCard === 1 ? (
              <div
                className={`${e.cardEvent} ${e.mainCardEvent} ${e.mobileCard}`}
              >
                <div className={e.cardHeaderEvent}>
                  <div>
                    Expert-led discussions on the crucial role of philanthropic
                    funds and equity players in driving the transition from
                    CFPPs to clean energy solutions.
                  </div>
                </div>
                <div className={e.bodyContainerEvent}>
                  <div className={e.headerIconEvent}>
                    <div className={e.iconBoxEvent}>
                      <div className={e.icon}>
                        <CalenderIcon />
                      </div>
                      <div>
                        23<sup>rd</sup> - 24<sup>th</sup> Sept ‘2024
                      </div>
                    </div>
                    <div className={e.iconBoxEvent}>
                      {/* <div className={e.icon}>
                        <ClockIcon />
                      </div>
                      <div>8:30 AM - 10:30 AM</div> */}
                    </div>
                  </div>
                  <div className={e.iconBoxEvent}>
                    <div className={e.icon}>
                      <UserIcon />
                    </div>
                    <div>Format: Hybrid</div>
                  </div>
                  <div className={e.footerIcon}>
                    <div className={e.iconBoxEvent}>
                      <div className={e.icon}>
                        <LocationIcon />
                      </div>
                      <div>One World Trade Center, Suite 8500, NY 10048</div>
                    </div>
                  </div>
                  <div className={e.bodyContainerEvent}>
                    <button className={`${e.btn}`}>Know more</button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}

        {!mobileVersion ? (
          <div className={e.cardsContainerEvent}>
            <div
              className={`${e.cardEvent} ${
                activeCard === 0 ? e.mainCardEventActive : e.mainCardEvent
              }`}
              onClick={() => setActiveCard(0)}
            >
              <div className={e.cardHeaderEvent}>
                <div
                  className={`${e.eventType} ${
                    activeCard === 0 ? e.eventTypeActive : ""
                  }`}
                >
                  Main Event
                </div>
                <div>
                  CLEM (Clean Energy Mechanism)- Making Coal Fired Power Plants
                  to Clean Energy Sources Transitions Profitable
                </div>
              </div>
              <div className={e.bodyContainerEvent}>
                <div className={e.headerIconEvent}>
                  <div className={e.iconBoxEvent}>
                    <div className={e.icon}>
                      <CalenderIcon />
                    </div>
                    <div>
                      26<sup>th</sup> Sept ‘2024
                    </div>
                  </div>
                  <div className={e.iconBoxEvent}>
                    {/* <div className={e.icon}>
                      <ClockIcon />
                    </div>
                    <div>8:00 AM - 6:00 PM</div> */}
                  </div>
                </div>
                <div className={e.iconBoxEvent}>
                  <div className={e.icon}>
                    <UserIcon />
                  </div>
                  <div>Format: Hybrid</div>
                </div>
                <div className={e.footerIcon}>
                  <div className={e.iconBoxEvent}>
                    <div className={e.icon}>
                      <LocationIcon />
                    </div>
                    <div>One World Trade Center 285 Fulton Street, 64th Floor New York, NY 10007</div>
                  </div>
                </div>
                <div className={e.bodyContainerEvent}>
                  <button
                    className={`${e.btn} ${
                      activeCard === 0 ? e.btnActive : ""
                    }`}
                    onClick={() => window.open(registrationUrl, "_blank", "noopener,noreferrer")}
                    ref={registerTopBtnRef}
                  >
                    Register now
                  </button>
                  {formVisibility === "top" && (
                    <>
                      <div
                        className={e["black-screen"]}
                        onClick={blackScreenClickHandler}
                      ></div>
                      <div className={e["form-container"]}>
                        <Form
                          onSuccess={successHandler}
                          onCancel={blackScreenClickHandler}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              className={`${e.cardEvent} ${
                activeCard === 1 ? e.mainCardEventActive : e.mainCardEvent
              } `}
              onClick={() => setActiveCard(1)}
            >
              <div className={e.cardHeaderEvent}>
                <div
                  className={`${e.eventType} ${
                    activeCard === 1 ? e.eventTypeActive : ""
                  }`}
                >
                  Breakout Sessions
                </div>
                <div>
                  Expert-led discussions on the crucial role of philanthropic
                  funds and equity players in driving the transition from CFPPs
                  to clean energy solutions.
                </div>
              </div>
              <div className={e.bodyContainerEvent}>
                <div className={e.headerIconEvent}>
                  <div className={e.iconBoxEvent}>
                    <div className={e.icon}>
                      <CalenderIcon />
                    </div>
                    <div>
                      23<sup>rd</sup> - 24<sup>th</sup> Sept ‘2024
                    </div>
                  </div>
                  <div className={e.iconBoxEvent}>
                    {/* <div className={e.icon}>
                      <ClockIcon />
                    </div>
                    <div>8:30 AM - 10:30 AM</div> */}
                  </div>
                </div>
                <div className={e.iconBoxEvent}>
                  <div className={e.icon}>
                    <UserIcon />
                  </div>
                  <div>Format: Hybrid</div>
                </div>
                <div className={e.footerIcon}>
                  <div className={e.iconBoxEvent}>
                    <div className={e.icon}>
                      <LocationIcon />
                    </div>
                    <div>One World Trade Center, Suite 8500, NY 10048</div>
                  </div>
                </div>
                <div className={e.bodyContainerEvent}>
                  <button
                    className={`${e.btn} ${
                      activeCard === 1 ? e.btnActive : ""
                    }`}
                  >
                    Know more
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className={e.AgendaDiv}>
          {mobileVersion ? (
            <div className={e.AgendaDropdownContainer}>
              {activeCard === 1 ? (
                <Dropdown
                  onChange={setActiveDay}
                  defaultSelected={true}
                  options={[
                    "Sept 23th : Breakout Session 1",
                    "Sept 24th  : Breakout Session 2",
                    // "Sept 25th : Breakout Session 3",
                  ]}
                />
              ) : null}
            </div>
          ) : null}

          <>
            {activeCard === 0 ? (
              <>
                <div className={e.Break}>
                  <div className={e.BreakHeader}>Agenda</div>
                </div>

                <IntroCard
                  mobileVersion={mobileVersion}
                  data={mainEventContent.intro}
                  showLogo={false}
                />

                <IntroCard
                  mobileVersion={mobileVersion}
                  data={mainEventContent.intro1}
                />

                <IntroCard
                  mobileVersion={mobileVersion}
                  data={mainEventContent.intro2}
                />

                <RealityCheckCard
                  mobileVersion={mobileVersion}
                  data={mainEventContent.realityCheck}
                />

                <IntroCard
                  mobileVersion={mobileVersion}
                  data={mainEventContent.intro3}
                />

                <div className={e.Break}>
                  <div className={e.BreakHeader}>Networking Break</div>
                  <div className={e.AgendaTimings}>
                    {mainEventContent.networkingBreak1}
                  </div>
                </div>

                <RealityCheckCard
                  mobileVersion={mobileVersion}
                  data={mainEventContent.realityCheck1}
                />

                <IntroCard
                  mobileVersion={mobileVersion}
                  data={mainEventContent.intro4}
                />

                <div className={e.Break}>
                  <div className={e.BreakHeader}>Lunch Break</div>
                  <div className={e.AgendaTimings}>
                    {mainEventContent.lunchBreak}
                  </div>
                </div>

                <RealityCheckCard
                  mobileVersion={mobileVersion}
                  data={mainEventContent.realityCheck2}
                />

                <IntroCard
                  mobileVersion={mobileVersion}
                  data={mainEventContent.intro5}
                />

                <div className={e.Break}>
                  <div className={e.BreakHeader}>Networking Break</div>
                  <div className={e.AgendaTimings}>
                    {mainEventContent.networkingBreak2}
                  </div>
                </div>

                {/* <RealityCheckCard
                  mobileVersion={mobileVersion}
                  data={mainEventContent.realityCheck3}
                /> */}

                <RealityCheckCard
                  mobileVersion={mobileVersion}
                  data={mainEventContent.realityCheck4}
                />

                <div className={e.Break}>
                  <div className={e.BreakHeader}>Networking Break</div>
                  <div className={e.AgendaTimings}>
                    {mainEventContent.networkingBreak3}
                  </div>
                </div>

                <IntroCard
                  mobileVersion={mobileVersion}
                  data={mainEventContent.intro6}
                />

                <div className={e.Break}>
                  <div className={e.BreakHeader}>
                    Networking Break & Refreshments
                  </div>
                  <div className={e.AgendaTimings}>
                    {mainEventContent.networkingBreak4}
                  </div>
                </div>
              </>
            ) : (
              <>
                {" "}
                {!mobileVersion && (
                  <div className={e.AgendaTabContainer}>
                    <div
                      onClick={() => setActiveDay(0)}
                      className={`${e.AgendaTab} ${
                        activeDay === 0 ? `${e.ActiveAgendaTab}` : ""
                      }`}
                    >
                      Sept 23<sup>rd</sup> : Breakout Session 1
                    </div>

                    <div className={e.slash}>/</div>

                    <div
                      onClick={() => setActiveDay(1)}
                      className={`${e.AgendaTab} ${
                        activeDay === 1 ? `${e.ActiveAgendaTab}` : ""
                      }`}
                    >
                      Sept 24<sup>th</sup> : Breakout Session 2
                    </div>

                    {/* <div
                      onClick={() => setActiveDay(2)}
                      className={`${e.AgendaTab} ${
                        activeDay === 2 ? `${e.ActiveAgendaTab}` : ""
                      }`}
                    >
                      Sept 25<sup>th</sup> : Breakout Session 3
                    </div> */}
                  </div>
                )}
                <div className={e.Break}>
                  <div className={e.BreakHeader}>Agenda</div>
                </div>
                <IntroCard
                  mobileVersion={mobileVersion}
                  data={breakOutSessions[activeDay].intro}
                  showLogo={false}
                />
                <RealityCheckCard
                  mobileVersion={mobileVersion}
                  data={breakOutSessions[activeDay].realityCheck}
                />
                <div className={e.Break}>
                  <div className={e.BreakHeader}>
                    Networking Break & Refreshments
                  </div>
                  <div className={e.AgendaTimings}>
                    {activeDay === 0 ? <>5:30 PM - 6:00 PM</>  : <> 10:30 AM - 11:00 AM</> }
                    
                  </div>
                </div>
                {/* <div className={e.Registration}>
                <div className={e.RegistrationHeader}>
                  <div>Registration</div>
                  <div className={e.RegistrationSubHeader}>Networking</div>
                </div>
                <div className={e.AgendaTimings}>8:00 AM - 8:30 AM</div>
              </div> */}
                {/* {!mobileVersion && (
                <div className={e.Introduction}>
                  <div className={e.IntroductionHeader}>
                    <div>Introduction</div>
                    <div className={e.IntroductionSubHeader}>
                      {dayWiseDetails[activeDay].introSubText}
                    </div>
                  </div>
                  <div
                    className={`${e["AgendaTimings"]} ${e["agenda-timings-intro"]}`}
                  >
                    <div>
                      <SeLogo height={68} width={168} />
                    </div>
                    <div className={e.AgendaTimings}>8:30 AM - 8:50 AM</div>
                  </div>
                </div>
              )} */}
                {/* {mobileVersion && (
                <div className={e.RealityCheck}>
                  <div className={e.RealityCheckHeader}>
                    <div className={e.RealityCheckHeaderContainer}>
                      Introduction
                    </div>
                    <div className={e.AgendaTimings}>8:30 AM - 8:50 AM</div>
                  </div> */}
                {/* <div className={e.RealityCheckSubHeader}>
                CLEM (Clean Energy Mechanism) - Transition finance
              </div> */}
                {/* <div className={e.RealityCheckSubHeader}>
                    {dayWiseDetails[activeDay].introSubText}
                  </div>

                  <div className={e.RealityCheckPanelContainer}>
                    <div className={e.RealityCheckPanelNames}></div>

                    <div className={e.RealityCheckPanelImages}>
                      <div className={e.RealityCheckPanelImagesContainer}>
                        <SeMainLogo height={68} width={168} />
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
                {/* <div className={e.RealityCheck}>
                <div className={e.RealityCheckHeader}>
                  <div className={e.RealityCheckHeaderContainer}>
                    {dayWiseDetails[activeDay].realityCheck.header}
                  </div>
                  <div className={e.AgendaTimings}>8:55 AM - 9:45 AM</div>
                </div>

                <div className={e.RealityCheckSubHeader}>
                  {dayWiseDetails[activeDay].realityCheck.subHeader}
                </div>

                <div className={e.RealityCheckPanelContainer}>
                  <div className={e.RealityCheckPanelNames}>
                    Panelists :
                    <ul>
                      {dayWiseDetails[
                        activeDay
                      ].realityCheck.panelistsNames.map((data, index) => (
                        <li key={index} className={e.RealityCheckPanelListItem}>
                          {data}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className={e.RealityCheckPanelImages}>
                    <div className={e.RealityCheckPanelImagesContainer}>
                      {
                        dayWiseDetails[activeDay].realityCheck
                          .panelistsImages[0]
                      }
                      {
                        dayWiseDetails[activeDay].realityCheck
                          .panelistsImages[1]
                      }
                    </div>

                    <div className={e.RealityCheckPanelImagesContainer}>
                      {
                        dayWiseDetails[activeDay].realityCheck
                          .panelistsImages[2]
                      }
                      {dayWiseDetails[activeDay].realityCheck
                        .panelistsImages[3] &&
                        dayWiseDetails[activeDay].realityCheck
                          .panelistsImages[3]}
                    </div>
                  </div>
                </div>
              </div>

              <div className={e.Break}>
                <div className={e.BreakHeader}>Networking Break</div>
                <div className={e.AgendaTimings}>9:45 AM - 9:55 AM</div>
              </div> */}
                {/* {dayWiseDetails[activeDay].financing && (
                <div className={e.RealityCheck}>
                  <div className={e.RealityCheckHeader}>
                    <div className={e.RealityCheckHeaderContainer}>
                      {dayWiseDetails[activeDay].financing.header}
                    </div>
                    <div className={e.AgendaTimings}>10:00 AM - 10:30 AM</div>
                  </div>

                  <div className={e.RealityCheckSubHeader}>
                    {dayWiseDetails[activeDay].financing.subHeader}
                  </div>

                  <div className={e.RealityCheckPanelContainer}>
                    <div className={e.RealityCheckPanelNames}>
                      Panelists :
                      <ul>
                        {dayWiseDetails[activeDay].financing.panelistsNames.map(
                          (data, index) => (
                            <li
                              key={index}
                              className={e.RealityCheckPanelListItem}
                            >
                              {data}
                            </li>
                          )
                        )}
                      </ul>
                    </div>

                    <div className={e.RealityCheckPanelImages}>
                      <div className={e.RealityCheckPanelImagesContainer}>
                        {dayWiseDetails[activeDay].financing.panelistsImages[0]}
                        {dayWiseDetails[activeDay].financing.panelistsImages[1]}
                      </div>

                      <div className={e.RealityCheckPanelImagesContainer}>
                        {dayWiseDetails[activeDay].financing.panelistsImages[2]}
                        {dayWiseDetails[activeDay].financing
                          .panelistsImages[3] &&
                          dayWiseDetails[activeDay].financing
                            .panelistsImages[3]}
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
                {/* <div className={e.RealityCheck}>
                <div className={e.RealityCheckHeader}>
                  <div className={e.RealityCheckHeaderContainer}>
                    {dayWiseDetails[activeDay].policymakers.header}
                  </div>
                  <div className={e.AgendaTimings}>
                    {dayWiseDetails[activeDay].policymakers.timings}
                  </div>
                </div>

                <div className={e.RealityCheckSubHeader}>
                  {dayWiseDetails[activeDay].policymakers.subHeader}
                </div>

                <div className={e.RealityCheckPanelContainer}>
                  <div className={e.RealityCheckPanelNames}>
                    Panelists :
                    <ul>
                      {dayWiseDetails[
                        activeDay
                      ].policymakers.panelistsNames.map((data, index) => (
                        <li key={index} className={e.RealityCheckPanelListItem}>
                          {data}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className={e.RealityCheckPanelImages}>
                    <div className={e.RealityCheckPanelImagesContainer}>
                      {
                        dayWiseDetails[activeDay].policymakers
                          .panelistsImages[0]
                      }
                      {
                        dayWiseDetails[activeDay].policymakers
                          .panelistsImages[1]
                      }
                    </div>

                    <div className={e.RealityCheckPanelImagesContainer}>
                      {
                        dayWiseDetails[activeDay].policymakers
                          .panelistsImages[2]
                      }
                      {dayWiseDetails[activeDay].policymakers
                        .panelistsImages[3] &&
                        dayWiseDetails[activeDay].policymakers
                          .panelistsImages[3]}
                    </div>
                  </div>
                </div>
              </div> */}
                {/* {dayWiseDetails[activeDay].networkingBreak2 && (
                <div className={e.Break}>
                  <div className={e.BreakHeader}>Networking Break</div>
                  <div className={e.AgendaTimings}>
                    {dayWiseDetails[activeDay].networkingBreak2}
                  </div>
                </div>
              )} */}
                {/* <div className={e.RealityCheck}>
                <div className={e.RealityCheckHeader}>
                  <div className={e.RealityCheckHeaderContainer}>
                    {dayWiseDetails[activeDay].clemDemonstration.header}
                  </div>
                  <div className={e.AgendaTimings}>
                    {dayWiseDetails[activeDay].clemDemonstration.timings}
                  </div>
                </div>

                <div className={e.RealityCheckSubHeader}>
                  {dayWiseDetails[activeDay].clemDemonstration.subHeader}
                </div>

                <div className={e.RealityCheckPanelContainer}>
                  <div className={e.RealityCheckPanelNames}></div>

                  <div className={e.RealityCheckPanelImages}>
                    <div className={e.RealityCheckPanelImagesContainer}>
                      <SeMainLogo height={68} width={168} />
                    </div>
                  </div>
                </div>
              </div>

              <div className={e.Break}>
                <div className={e.BreakHeader}>
                  Networking Break & Refreshments
                </div>
                <div className={e.AgendaTimings}>
                  {dayWiseDetails[activeDay].networkingBreak3}
                </div>
              </div> */}
              </>
            )}
          </>
        </div>

        <div className={e.book}>
         { activeCard === 0 && <button onClick={() => window.open(registrationUrl, "_blank", "noopener,noreferrer")} ref={registerBtnRef}>
            Register Now
          </button>}
          {formVisibility === "bottom" && (
            <>
              <div
                className={e["black-screen"]}
                onClick={blackScreenClickHandler}
              ></div>
              <div className={e["form-container"]}>
                <Form
                  onSuccess={successHandler}
                  onCancel={blackScreenClickHandler}
                  date={activeDay}
                />
              </div>
            </>
          )}
        </div>

        {activeCard === 0 ? (
          <div className={e.AboutDiv}>
            <div className={e.AboutDivHeader}>
              You'll get expert insights on-
            </div>

            {additionalDetails.map((data, index) => (
              <div className={e.OutcomesContainer} key={index}>
                <ul>
                  <li> {data.description}</li>
                </ul>
              </div>
            ))}
          </div>
        ) : null}
      </div>
     
    </>
  );
};

export default EventsClem1;

const keyOutcomesData = [
  {
    title: "Current state discovery",
    description:
      "Data-driven extraction of technical and financials of current state of CFPP.",
  },
  {
    title: "Transition planning",
    description:
      "Practical location/country-specific transition plan (phased approach) by collaborating with CFPP owners, power purchasers, financial institutions, and policymakers.",
  },
  {
    title: "Economic gap",
    description:
      "Practical data-driven mechanism to nail down exact dollars CFPP owners have to forego as part of the transition.",
  },
  {
    title: "Energy mix",
    description:
      "Model-driven approaches covering region/country-specific policies to identify the potential clean energy source technologies and the cost associated along with financial analysis. Build clean energy source technologies working with region/country specific partners.",
  },
  {
    title: "Transition finance",
    description:
      "Data-driven, practical approach to identify transition financing requirements mapping with clear timelines covering different business models. Constructing transition financing requirements by using innovative global financial instruments to make transition profitable and working with our equity, debt global financial partners to raise funds.",
  },
  {
    title: "Blockchain-driven Marketplace",
    description:
      "Source the right clean energy source technology by working with clean energy source developers to speed up the transition.",
  },
  {
    title: "Financial analysis",
    description:
      "Provide detailed financial analysis with innovative transition financing instruments and transition credit cash flows for both equity and debt players.",
  },
  {
    title: "Summary",
    description:
      "Demonstrate profitability with transitions by comparing different options for CFPP owners and financial institutions.",
  },
];

const additionalDetails = [
  {
    title: "CLEM's practical approach",
    description:
      "CLEM's practical approach that leverages innovative transition finance instruments to accelerate the phase-out of CFPPs and make it profitable.",
  },
  {
    title: "Model-driven approach",
    description:
      "Model-driven approach for identifying the right clean energy sources to replace traditional CFPP.",
  },
  {
    title: "Role of policies",
    description:
      "Detailed financial analysis, including a real-time customer demonstration, to illustrate the profitability of CFPP transitions using transition financing instruments.",
  },
  {
    title: "Detailed financial analysis",
    description:
      "Just transition and possibilities of unlocking investments as part of it.",
  },
  {
    title: "Detailed financial analysis",
    description:
      "Transition credits, as a new financial instrument to accelerate CFPP transitions.",
  },
];
