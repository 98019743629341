import React from "react";
import classes from "./PopUp.module.css";

const PopUp = ({ popupContent = <>Popup content</>, style = {} }) => {
  return (
    <div className={classes["popup-container"]} style={style}>
      {popupContent}
    </div>
  );
};

export default PopUp;
