import React, { useRef } from "react";
import { useEffect, useState } from "react";

import Form from "../../form/Form.js";
import Dropdown from "../../../component/Core/Dropdown/Dropdown.js";


import TransitionFinanceOpportunityBackground from "./transitionFinanceOpportunityBackground/transitionFinanceOpportunityBackground.js";

import SeMainLogo from "../../assets/SeMainLogo";
import SeLogo from "../../assets/companyLogos/SELogo";
import ACILogo from "../../assets/companyLogos/ACILogo";
import SMBCLogo from "../../assets/companyLogos/SMBCLogo";
import ClemInfoImage from "../../assets/clemStrategy.png";
import GPCLLogo from "../../assets/companyLogos/GPCLLogo";
import GFanzLogo from "../../assets/companyLogos/GFanzLogo";
import ClimateXLogo from "../../assets/companyLogos/ClimateXLogo";
import SinghaniaLogo from "../../assets/companyLogos/SinghaniaLogo";
import SUCOFINDOLogo from "../../assets/companyLogos/SucoFindoLogo";
import IDXCarbonLogo from "../../assets/companyLogos/IDXCarbonLogo";
import ClimateBondInitiativeLogo from "../../assets/companyLogos/ClimateBondInitiativeLogo";
import TheRockfellarFoundationLogo from "../../assets/companyLogos/TheRockfellarFoundationLogo";

import e from "./eventsClem2.module.css";

const EventsClem2 = () => {
    const [formVisibility, setFormVisibility] = useState(false);
    const [readMore, setReadMore] = React.useState(false);
    const [activeDay, setActiveDay] = React.useState(2);
    const registerBtnRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [mobileVersion, setMobileVersion] = useState(false);

    useEffect(() => {
        if (window.innerWidth < 1000) {
            setMobileVersion(true);
        }

        const handleResize = () => {
            if (window.innerWidth < 1000) {
                setMobileVersion(true);
            } else {
                setMobileVersion(false);
            }
        };

        // Add event listener for window resize
        window.addEventListener("resize", handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const registerClickHandler = () => {
        const element = registerBtnRef.current;
        const elementRect = element.getBoundingClientRect();
        const absoluteElementTop = elementRect.top + window.pageYOffset;
        const middle =
            absoluteElementTop - window.innerHeight / 2 + elementRect.height / 2;

        window.scrollTo({
            top: middle,
            behavior: "smooth",
        });

        setFormVisibility(true);
    };

    const blackScreenClickHandler = () => {
        setFormVisibility(false);
    };

    const successHandler = () => {
        setFormVisibility(false);
    };

    return (
        <>
          
            {/* <Bg img={ClemBackground} /> */}
            <TransitionFinanceOpportunityBackground />

            <div className={e.EventsParent}>
                <div className={e.AboutDiv}>
                    <div className={e.AboutDivHeader}>ABOUT</div>

                    <div className={e.AboutDivDes}>
                        This event focus on the opportunities available to equity and debt
                        players in the clean energy transitions, with an emphasis on
                        financial instruments enabled by CLEM (Clean Energy Mechanism). It
                        also aims to provide insights into transition credits as a new
                        financial instrument capable of generating cashflows. Additionally,
                        the discussion will highlight how CLEM facilitates transition
                        finance, enabling the shift from coal-fired power plants to
                        renewable energy sources, creating new avenues for investment and
                        growth in sustainability.
                    </div>
                </div>

                <div className={e.AboutDiv}>
                    <div className={e.AboutDivHeader}>
                        CLEM - PRACTICAL AND CRAFTED APPROACH
                    </div>

                    <div className={e.AboutDivDes}>
                        Coal-fired power generation remains a significant contributor to
                        global carbon emissions, particularly in Asia, where a concentration
                        of young CFPPs exists, averaging less than 15 years old. To address
                        this pressing issue, CLEM, a first-of-its-kind clean energy
                        transition tailor-made solution, takes a practical data-driven
                        approach working with CFPP asset owners, power purchasers and
                        policymakers by connecting the dots to automate, simplify and scale
                        the clean energy transitions.
                    </div>
                </div>

                {readMore && (
                    <>
                        <div className={e.AboutDiv}>
                            <div className={e.AboutDivHeader}>
                                CLEM VISION - MAKING ENERGY TRANSITIONS PROFITABLE
                            </div>

                            <div className={e.AboutDivDes}>
                                CLEM’s vision is to make energy transition not just practical
                                but profitable for CFPP asset owners and financiers. Leveraging
                                a cutting-edge, data-driven approach, CLEM’s tailor-made
                                solutions provide the most effective and strategic clean energy
                                transition planning while ensuring profitability with a
                                make-it-happen approach.
                            </div>

                            <img
                                src={ClemInfoImage}
                                alt="ClemInfoImage"
                                className={e.ClemInfoImage}
                            />
                        </div>

                        <div className={e.AboutDiv}>
                            <div className={e.AboutDivHeader}>KEY OUTCOMES:</div>

                            {keyOutcomesData.map((data, index) => (
                                <div className={e.OutcomesContainer} key={index}>
                                    <ul>
                                        <li>
                                            <span className={e.OutcomesContainerKey}>
                                                {data.title} -{" "}
                                            </span>{" "}
                                            {data.description}
                                        </li>
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </>
                )}

                <div
                    className={e.ReadMore}
                    style={{ cursor: "pointer" }}
                    onClick={() => setReadMore(!readMore)}
                >
                    {!readMore ? "Read more" : "Read less"}
                </div>

                <div className={e.AgendaDiv}>
                    <div className={e.AboutDivHeader}>AGENDA</div>

                    {/* <div className={e.AgendaDropdownContainer}>
                        Sept 26th : Transition Finance
                    </div> */}

                    <div className={e.AgendaTabContainer}>
                        {/* <div
                            onClick={() => setActiveDay(0)}
                            className={`${e.AgendaTab} ${activeDay === 0 ? `${e.ActiveAgendaTab}` : ""
                                }`}
                        >
                            Sept 24<sup>th</sup> : Clean Energy Source Technologies
                        </div>
                        <div
                            onClick={() => setActiveDay(1)}
                            className={`${e.AgendaTab} ${activeDay === 1 ? `${e.ActiveAgendaTab}` : ""
                                }`}
                        >
                            Sept 25<sup>th</sup> : Transition Credits
                        </div> */}
                        <div
                            onClick={() => setActiveDay(2)}
                            className={`${e.AgendaTab} ${activeDay === 2 ? `${e.ActiveAgendaTab}` : ""
                                }`}
                        >
                            Sept 26<sup>th</sup> : Transition Finance
                        </div>
                    </div>

                    <div className={e.Registration}>
                        <div className={e.RegistrationHeader}>
                            <div>Registration</div>
                            <div className={e.RegistrationSubHeader}>Networking</div>
                        </div>
                        <div className={e.AgendaTimings}>
                            {dayWiseDetails[activeDay].registrationTiming}
                        </div>
                    </div>

                    {!mobileVersion && (
                        <div className={e.Introduction}>
                            <div className={e.IntroductionHeader}>
                                <div>Introduction</div>
                                <div className={e.IntroductionSubHeader}>
                                    {dayWiseDetails[activeDay].introSubText}
                                </div>
                            </div>
                            <div
                                className={`${e["AgendaTimings"]} ${e["agenda-timings-intro"]}`}
                            >
                                <div>
                                    <SeLogo height={68} width={168} />
                                </div>
                                <div className={e.AgendaTimings}>
                                    {dayWiseDetails[activeDay].introductionTiming}
                                </div>
                            </div>
                        </div>
                    )}

                    {mobileVersion && (
                        <div className={e.RealityCheck}>
                            <div className={e.RealityCheckHeader}>
                                <div className={e.RealityCheckHeaderContainer}>
                                    Introduction
                                </div>
                                <div className={e.AgendaTimings}>8:30 AM - 8:50 AM</div>
                            </div>

                            <div className={e.RealityCheckSubHeader}>
                                {dayWiseDetails[activeDay].introSubText}
                            </div>

                            <div className={e.RealityCheckPanelContainer}>
                                <div className={e.RealityCheckPanelNames}></div>

                                <div className={e.RealityCheckPanelImages}>
                                    <div className={e.RealityCheckPanelImagesContainer}>
                                        <SeMainLogo height={68} width={168} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={e.RealityCheck}>
                        <div className={e.RealityCheckHeader}>
                            <div className={e.RealityCheckHeaderContainer}>
                                {dayWiseDetails[activeDay].realityCheck.header}
                            </div>
                            <div className={e.AgendaTimings}>
                                {dayWiseDetails[activeDay].realityCheck.timings}
                            </div>
                        </div>

                        <div className={e.RealityCheckSubHeader}>
                            {dayWiseDetails[activeDay].realityCheck.subHeader}
                        </div>

                        <div className={e.RealityCheckPanelContainer}>
                            <div className={e.RealityCheckPanelNames}>
                                Panelists :
                                <ul>
                                    {dayWiseDetails[activeDay].realityCheck.panelistsNames.map(
                                        (data, index) => (
                                            <li key={index} className={e.RealityCheckPanelListItem}>
                                                {data}
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>

                            <div className={e.RealityCheckPanelImages}>
                                <div className={e.RealityCheckPanelImagesContainer}>
                                    {dayWiseDetails[activeDay].realityCheck.panelistsImages[0]}
                                    {dayWiseDetails[activeDay].realityCheck.panelistsImages[1]}
                                </div>

                                <div className={e.RealityCheckPanelImagesContainer}>
                                    {dayWiseDetails[activeDay].realityCheck.panelistsImages[2]}
                                    {dayWiseDetails[activeDay].realityCheck.panelistsImages[3] &&
                                        dayWiseDetails[activeDay].realityCheck.panelistsImages[3]}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={e.Break}>
                        <div className={e.BreakHeader}>Networking Break</div>
                        <div className={e.AgendaTimings}>3:45 PM - 3:55 PM</div>
                    </div>

                    {dayWiseDetails[activeDay].financing && (
                        <div className={e.RealityCheck}>
                            <div className={e.RealityCheckHeader}>
                                <div className={e.RealityCheckHeaderContainer}>
                                    {dayWiseDetails[activeDay].financing.header}
                                </div>
                                <div className={e.AgendaTimings}>
                                    {dayWiseDetails[activeDay].financing.timings}
                                </div>
                            </div>

                            <div className={e.RealityCheckSubHeader}>
                                {dayWiseDetails[activeDay].financing.subHeader}
                            </div>

                            <div className={e.RealityCheckPanelContainer}>
                                <div className={e.RealityCheckPanelNames}>
                                    Panelists :
                                    <ul>
                                        {dayWiseDetails[activeDay].financing.panelistsNames.map(
                                            (data, index) => (
                                                <li key={index} className={e.RealityCheckPanelListItem}>
                                                    {data}
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>

                                <div className={e.RealityCheckPanelImages}>
                                    <div className={e.RealityCheckPanelImagesContainer}>
                                        {dayWiseDetails[activeDay].financing.panelistsImages[0]}
                                        {dayWiseDetails[activeDay].financing.panelistsImages[1]}
                                    </div>

                                    <div className={e.RealityCheckPanelImagesContainer}>
                                        {dayWiseDetails[activeDay].financing.panelistsImages[2]}
                                        {dayWiseDetails[activeDay].financing.panelistsImages[3] &&
                                            dayWiseDetails[activeDay].financing.panelistsImages[3]}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={e.RealityCheck}>
                        <div className={e.RealityCheckHeader}>
                            <div className={e.RealityCheckHeaderContainer}>
                                {dayWiseDetails[activeDay].policymakers.header}
                            </div>
                            <div className={e.AgendaTimings}>
                                {dayWiseDetails[activeDay].policymakers.timings}
                            </div>
                        </div>

                        <div className={e.RealityCheckSubHeader}>
                            {dayWiseDetails[activeDay].policymakers.subHeader}
                        </div>

                        <div className={e.RealityCheckPanelContainer}>
                            <div className={e.RealityCheckPanelNames}>
                                Panelists :
                                <ul>
                                    {dayWiseDetails[activeDay].policymakers.panelistsNames.map(
                                        (data, index) => (
                                            <li key={index} className={e.RealityCheckPanelListItem}>
                                                {data}
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>

                            <div className={e.RealityCheckPanelImages}>
                                <div className={e.RealityCheckPanelImagesContainer}>
                                    {dayWiseDetails[activeDay].policymakers.panelistsImages[0]}
                                    {dayWiseDetails[activeDay].policymakers.panelistsImages[1]}
                                </div>

                                <div className={e.RealityCheckPanelImagesContainer}>
                                    {dayWiseDetails[activeDay].policymakers.panelistsImages[2]}
                                    {dayWiseDetails[activeDay].policymakers.panelistsImages[3] &&
                                        dayWiseDetails[activeDay].policymakers.panelistsImages[3]}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={e.Break}>
                        <div className={e.BreakHeader}>Networking Break</div>
                        <div className={e.AgendaTimings}>5:25 PM - 5:35 PM</div>
                    </div>

                    <div className={e.RealityCheck}>
                        <div className={e.RealityCheckHeader}>
                            <div className={e.RealityCheckHeaderContainer}>
                                {dayWiseDetails[activeDay].transitionCredits.header}
                            </div>
                            <div className={e.AgendaTimings}>
                                {dayWiseDetails[activeDay].transitionCredits.timings}
                            </div>
                        </div>

                        <div className={e.RealityCheckSubHeader}>
                            {dayWiseDetails[activeDay].transitionCredits.subHeader}
                        </div>

                        <div className={e.RealityCheckPanelContainer}>
                            <div className={e.RealityCheckPanelNames}>
                                Panelists :
                                <ul>
                                    {dayWiseDetails[
                                        activeDay
                                    ].transitionCredits.panelistsNames.map((data, index) => (
                                        <li key={index} className={e.RealityCheckPanelListItem}>
                                            {data}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className={e.RealityCheckPanelImages}>
                                <div className={e.RealityCheckPanelImagesContainer}>
                                    {
                                        dayWiseDetails[activeDay].transitionCredits
                                            .panelistsImages[0]
                                    }
                                    {
                                        dayWiseDetails[activeDay].transitionCredits
                                            .panelistsImages[1]
                                    }
                                </div>

                                <div className={e.RealityCheckPanelImagesContainer}>
                                    {
                                        dayWiseDetails[activeDay].transitionCredits
                                            .panelistsImages[2]
                                    }
                                    {dayWiseDetails[activeDay].transitionCredits
                                        .panelistsImages[3] &&
                                        dayWiseDetails[activeDay].transitionCredits
                                            .panelistsImages[3]}
                                </div>
                            </div>
                        </div>
                    </div>

                    {dayWiseDetails[activeDay].networkingBreak2 && (
                        <div className={e.Break}>
                            <div className={e.BreakHeader}>Networking Break</div>
                            <div className={e.AgendaTimings}>
                                {dayWiseDetails[activeDay].networkingBreak2}
                            </div>
                        </div>
                    )}

                    <div className={e.RealityCheck}>
                        <div className={e.RealityCheckHeader}>
                            <div className={e.RealityCheckHeaderContainer}>
                                {dayWiseDetails[activeDay].clemDemonstration.header}
                            </div>
                            <div className={e.AgendaTimings}>
                                {dayWiseDetails[activeDay].clemDemonstration.timings}
                            </div>
                        </div>

                        <div className={e.RealityCheckSubHeader}>
                            {dayWiseDetails[activeDay].clemDemonstration.subHeader}
                        </div>

                        <div className={e.RealityCheckPanelContainer}>
                            <div className={e.RealityCheckPanelNames}></div>

                            <div className={e.RealityCheckPanelImages}>
                                <div className={e.RealityCheckPanelImagesContainer}>
                                    <SeMainLogo height={68} width={168} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={e.Break}>
                        <div className={e.BreakHeader}>Networking Break & Refreshments</div>
                        <div className={e.AgendaTimings}>
                            {dayWiseDetails[activeDay].networkingBreak3}
                        </div>
                    </div>
                </div>

                <div className={e.book}>
                    <button onClick={registerClickHandler} ref={registerBtnRef}>
                        Request for Invitation
                    </button>
                    {formVisibility && (
                        <>
                            <div
                                className={e["black-screen"]}
                                onClick={blackScreenClickHandler}
                            ></div>
                            <div className={e["form-container"]}>
                                <Form onSuccess={successHandler} />
                            </div>
                        </>
                    )}
                </div>

                <div className={e.AboutDiv}>
                    <div className={e.AboutDivHeader}>You'll get expert insights on-</div>

                    {additionalDetails.map((data, index) => (
                        <div className={e.OutcomesContainer} key={index}>
                            <ul>
                                <li> {data.description}</li>
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
           
        </>
    );
};

export default EventsClem2;

const dayWiseDetails = [
    {
        introSubText: "CLEM (Clean Energy Mechanism) - Energy mix",
        realityCheck: {
            header:
                "Reality check on the readiness of clean energy sources to replace traditional CFPPs as a round-the-clock source",
            subHeader:
                "A panel discussion about the readiness of clean energy sources to replace traditional CFPPs as a round-the-clock source. ",
            panelistsNames: [
                "Clean Energy Source Technology Experts",
                <>
                    {
                        <span
                            onClick={() =>
                                window.open(
                                    "https://www.linkedin.com/in/arun-mahesh-babu-m-s-ias-97a4b922/",
                                    "_blank"
                                )
                            }
                            className={e.panelistsNamesSpan}
                        >
                            Arun Mahesh Babu
                        </span>
                    }
                    , Managing Director, Gujarat Power Corporation Limited (India)
                </>,
                <>
                    {
                        <span
                            onClick={() =>
                                window.open(
                                    "https://www.linkedin.com/in/venkata-reddy-kasu-0580823/",
                                    "_blank"
                                )
                            }
                            className={e.panelistsNamesSpan}
                        >
                            Kasu Venkata Reddy
                        </span>
                    }
                    , CEO & Co-founder, Sustainability Economics
                </>,
            ],
            panelistsImages: [
                <GPCLLogo height={68} width={168} />,
                // <IEALogo height={68} width={168} />,
                // <IRENALogo height={68} width={168} />,
                <SeMainLogo height={68} width={168} />,
            ],
        },
        policymakers: {
            header: "The role of policymakers in accelerating CFPP transitions",
            subHeader:
                "A panel discussion to address the critical role of policymakers in accelerating the transition from CFPPs to clean energy sources.",
            panelistsNames: [
                "Policy Makers",
                "Legal Institutions (PPA Amendments)",
                <>
                    {
                        <span
                            onClick={() =>
                                window.open(
                                    "https://www.linkedin.com/in/arun-mahesh-babu-m-s-ias-97a4b922/",
                                    "_blank"
                                )
                            }
                            className={e.panelistsNamesSpan}
                        >
                            Arun Mahesh Babu
                        </span>
                    }
                    , Managing Director, Gujarat Power Corporation Limited (India)
                </>,
                <>
                    {
                        <span
                            onClick={() =>
                                window.open(
                                    "https://www.linkedin.com/in/venkata-reddy-kasu-0580823/",
                                    "_blank"
                                )
                            }
                            className={e.panelistsNamesSpan}
                        >
                            Kasu Venkata Reddy
                        </span>
                    }
                    , CEO & Co-founder, Sustainability Economics
                </>,
            ],
            panelistsImages: [
                <SUCOFINDOLogo height={68} width={168} />,
                <SinghaniaLogo height={68} width={168} />,
                <GPCLLogo height={68} width={168} />,
                <SeMainLogo height={68} width={168} />,
            ],

            timings: "10:00 AM - 10:50 AM",
        },
        networkingBreak2: "10:50 AM - 11:00 AM",
        clemDemonstration: {
            header: "CLEM demonstration with real time customer case study",
            subHeader:
                "Model driven approach to identify right renewable energy mix (solar, wind, battery) combination mainly covering capacity, land, CUF, cost required to replace CFPP based on location (lat-long).",
            timings: "11:00 AM - 11:30 AM",
        },
        networkingBreak3: "11:30 AM - 12:30 PM",
    },
    {
        introSubText: "CLEM (Clean Energy Mechanism) - Transition credits",
        realityCheck: {
            header:
                "Transition credits, a new financial instrument, to accelerate and enable profitable CFPP transitions",
            subHeader:
                "A panel discussion about leveraging transition credits to accelerate CFPP transitions while making it profitable. ",
            panelistsNames: [
                <>
                    {
                        <span
                            onClick={() =>
                                window.open(
                                    "https://www.linkedin.com/in/venkata-reddy-kasu-0580823/",
                                    "_blank"
                                )
                            }
                            className={e.panelistsNamesSpan}
                        >
                            Kasu Venkata Reddy
                        </span>
                    }
                    , CEO & Co-founder, Sustainability Economics
                </>,
                <>
                    {
                        <span
                            onClick={() =>
                                window.open(
                                    "https://www.linkedin.com/in/john-lo-a2738225a/",
                                    "_blank"
                                )
                            }
                            className={e.panelistsNamesSpan}
                        >
                            John Lo
                        </span>
                    }
                    , Founder, Asia Carbon Institute
                </>,
                <>
                    {
                        <span
                            onClick={() =>
                                window.open(
                                    "https://www.linkedin.com/in/cedric-rimaud-6695936/",
                                    "_blank"
                                )
                            }
                            className={e.panelistsNamesSpan}
                        >
                            Cedric Rimaud
                        </span>
                    }
                    , Deputy General Manager, SMBC Group
                </>,
                "Policy Makers",
            ],
            panelistsImages: [
                <SUCOFINDOLogo height={68} width={168} />,
                <ACILogo height={68} width={168} />,
                <SMBCLogo height={68} width={168} />,
                <SeMainLogo height={68} width={168} />,
            ],
        },
        policymakers: {
            header: "Marketplace and off-takers for transition credits",
            subHeader:
                "A panel discussion exploring the marketplace and off-takers for transition credits.",
            panelistsNames: [
                <>
                    {
                        <span
                            onClick={() =>
                                window.open(
                                    "https://www.linkedin.com/in/mark-glossoti-79189854/",
                                    "_blank"
                                )
                            }
                            className={e.panelistsNamesSpan}
                        >
                            Mark Glossoti
                        </span>
                    }
                    , Chief Executive Officer, Climate Impact X
                </>,
                <>
                    {
                        <span
                            onClick={() =>
                                window.open(
                                    "https://www.linkedin.com/in/cedric-rimaud-6695936/",
                                    "_blank"
                                )
                            }
                            className={e.panelistsNamesSpan}
                        >
                            Cedric Rimaud
                        </span>
                    }
                    , Deputy General Manager, SMBC Group
                </>,
                <>
                    {
                        <span
                            onClick={() =>
                                window.open(
                                    "https://www.linkedin.com/in/ignatius-denny-wicaksono-cfa-frm-cipm-3622b236/",
                                    "_blank"
                                )
                            }
                            className={e.panelistsNamesSpan}
                        >
                            Ignatius Denny Wicaksono
                        </span>
                    }
                    , Executive Vice President & Business Development, Indonesia Carbon
                    Exchange
                </>,
                <>
                    {
                        <span
                            onClick={() =>
                                window.open(
                                    "https://www.linkedin.com/in/venkata-reddy-kasu-0580823/",
                                    "_blank"
                                )
                            }
                            className={e.panelistsNamesSpan}
                        >
                            Kasu Venkata Reddy
                        </span>
                    }
                    , CEO & Co-founder, Sustainability Economics
                </>,
            ],
            panelistsImages: [
                <ClimateXLogo height={68} width={208} />,
                <IDXCarbonLogo height={68} width={88} />,
                <SMBCLogo height={68} width={168} />,
                <SeMainLogo height={68} width={168} />,
            ],

            timings: "10:00 AM - 10:50 AM",
        },
        networkingBreak2: "10:50 AM - 11:00 AM",
        clemDemonstration: {
            header: "CLEM demonstration with real time customer case study",
            subHeader:
                "Detailed financial analysis of NPV and IRRs for firm and equity stakeholders with transition credit cashflows.",
            timings: "11:00 AM - 11:30 AM",
        },
        networkingBreak3: "11:30 AM - 12:30 PM",
    },
    {
        registrationTiming: "2:00 PM - 2:30 PM",
        introductionTiming: "2:30 PM - 2:50 PM",
        introSubText: "CLEM (Clean Energy Mechanism) - Transition finance",
        realityCheck: {
            header: "Equity players opportunities in transition financing",
            subHeader:
                "Panel discussion exploring investment avenues for equity players in the evolving landscape of transition financing.",
            panelistsNames: [

                "Private Equity / Philanthropic funds",
                "Sustainability Economics",
            ],
            panelistsImages: [
                <GFanzLogo height={68} width={168} />,
            ],
            timings: "2:55 PM - 3:45 PM",
        },
        financing: {
            header: "Debt players opportunities in transition financing",
            subHeader:
                "Panel discussion exploring investment avenues for debt players in the evolving landscape of transition financing.",
            panelistsNames: [
                "GFANZ",
                "SMBC",
                "Climate Bond Initiative",
                "Sustainability Economics",
            ],
            panelistsImages: [
                <GFanzLogo height={68} width={168} />,
                <SMBCLogo height={68} width={168} />,
                <SeMainLogo height={68} width={168} />,
                <ClimateBondInitiativeLogo />
            ],
            timings: "4:00 PM - 4:50 PM",
        },
        policymakers: {
            header: "Just transition financing opportunities",
            subHeader:
                "Panel discussion around unlocking investment potential in just transition financing opportunities.",
            panelistsNames: [
                "SMBC",
                "The Rockefeller Foundation",
                "Sustainability Economics",
            ],
            panelistsImages: [
                <SMBCLogo height={68} width={168} />,
                <TheRockfellarFoundationLogo height={168} width={168} />,
                <SeMainLogo height={68} width={168} />,
            ],

            timings: "4:55 PM - 5:25 PM",
        },
        transitionCredits: {
            header:
                "Transition credits - as a new financial opportunity to generate cashflows",
            subHeader:
                "Panel discussion about harnessing transition credits to generate cashflows for transition finance.",
            timings: "5:35 PM - 6:15 PM",
            panelistsNames: [
                "Sustainability Economics",
                "Asia Carbon Institute",
                "SMBC",
                "Climate Impact X",
            ],
            panelistsImages: [
                <ClimateXLogo height={68} width={208} />,
                <ACILogo height={68} width={168} />,
                <SMBCLogo height={68} width={168} />,
                <SeMainLogo height={68} width={168} />,
            ],
        },
        clemDemonstration: {
            header: "CLEM demonstration with real time customer case study",
            subHeader:
                "Detailed financial analysis to demonstrate CFPP transitions profitable with transition financing instruments and transition credit cashflows combinations.",
            timings: "6:20 PM - 6:50 PM",
        },
        networkingBreak3: "6:50 PM - 7:50 PM",
    },
];

const keyOutcomesData = [
    {
        title: "Current state discovery",
        description:
            "Data-driven extraction of technical and financials of current state of CFPP.",
    },
    {
        title: "Transition planning",
        description:
            "Practical location/country-specific transition plan (phased approach) by collaborating with CFPP owners, power purchasers, financial institutions, and policymakers.",
    },
    {
        title: "Economic gap",
        description:
            "Practical data-driven mechanism to nail down exact dollars CFPP owners have to forego as part of the transition.",
    },
    {
        title: "Energy mix",
        description:
            "Model-driven approaches covering region/country-specific policies to identify the potential clean energy source technologies and the cost associated along with financial analysis. Build clean energy source technologies working with region/country specific partners.",
    },
    {
        title: "Transition finance",
        description:
            "Data-driven, practical approach to identify transition financing requirements mapping with clear timelines covering different business models. Constructing transition financing requirements by using innovative global financial instruments to make transition profitable and working with our equity, debt global financial partners to raise funds.",
    },
    {
        title: "Blockchain-driven Marketplace",
        description:
            "Source the right clean energy source technology by working with clean energy source developers to speed up the transition.",
    },
    {
        title: "Financial analysis",
        description:
            "Provide detailed financial analysis with innovative transition financing instruments and transition credit cash flows for both equity and debt players.",
    },
    {
        title: "Summary",
        description:
            "Demonstrate profitability with transitions by comparing different options for CFPP owners and financial institutions.",
    },
];

const additionalDetails = [
    {
        title: "CLEM's practical approach",
        description:
            "CLEM's practical approach that leverages innovative transition finance instruments to accelerate the phase out of CFPPs and make it profitable.",
    },
    {
        title: "Model-driven approach",
        description:
            "Various investment opportunities for equity players in the evolving landscape of transition financing"
    },
    {
        title: "Role of policies",
        description:
            "Various investment avenues for debt players in the evolving landscape of transition financing"
    },
    {
        title: "Detailed financial analysis",
        description:
            "Just transition and possibilities of unlocking investments as part of it"
    },
    {
        title: "Detailed financial analysis",
        description:
            "Transition credits, as a new financial instrument to accelerate CFPP transitions."
    },
];
