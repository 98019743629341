import React from 'react'
import { useEffect, useState } from 'react'
import fin from "./finCards.module.css"
import Carbon from "../../../assets/Carbon.png"
import Reporting from "../../../assets/Reporting.png"
import Target from "../../../assets/Target.png"
import Climate from "../../../assets/Climate.png"
import Transition from "../../../assets/Transition.png"
import CarbonOffset from "../../../assets/CarbonOffset.png"
const FinCards = () => {

    const Cards = [{
        id: 1,
        title: "01 Carbon accounting",
        content: "Simplify and automate Scope 1, Scope 2 and Scope 3 including financed emissions accounting, for your portfolio and operations.",
        img: Carbon
    }, {
        id: 2,
        title: "02 Reporting",
        content: "Generate an automated report, disclosing climate related risks in compliance with regulations and standards across several jurisdictions.",
        img: Reporting
    },
    {
        id: 3,
        title: "03 Target-setting and Benchmarking",
        content: "Set Science Based Targets and track performance in real-time, benchmarking across industry leaders.",
        img: Target
    },
    {
        id: 4,
        title: "04 Climate-Scenario Analysis",
        content: "Assess the impact of transition, physical and portfolio risks on your returns while tracking your investment portfolio’s alignment to Net-Zero.",
        img: Climate
    },
    {
        id: 5,
        title: "05 Transition financing (Green bonds)",
        content: "Digitalise and automate the green bond issuance process from end-to-end with workflow and blockchain.",
        img: Transition
    },
    {
        id: 6,
        title: "06 Carbon Offsets",
        content: "Decarbonize your portfolio by investing in high quality carbon offset projects across the world.",
        img: CarbonOffset
    }]

    const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 767px)").matches);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 767px)");
        const handleResize = () => {
            setIsMobile(mediaQuery.matches);
        };
        mediaQuery.addEventListener('change', handleResize);
        return () => {
            mediaQuery.removeEventListener('change', handleResize);
        };
    }, []);


    function Card({ id, title, content, img, isOdd }) {
        return (<>
            {!isMobile ?
                <div data-aos="fade-up" data-aos-once="true" data-aos-delay="150" className={fin.finCard}>
                    {isOdd ? (
                        <>
                            <div className={fin.OddcontentPart}>
                                <h3 className={fin.title}>{title}</h3>
                                <div className={fin.content}>{content}</div>
                            </div>
                            <div className={fin.OddimgPart}>
                                <img src={img} alt={title} className={fin.cardImg} />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={fin.EvenimgPart}>
                                <img src={img} alt={title} className={fin.cardImg} />
                            </div>
                            <div className={fin.EvencontentPart}>
                                <h3 className={fin.title}>{title}</h3>
                                <div className={fin.content}>{content}</div>
                            </div>

                        </>
                    )}
                </div> :
                <div data-aos="fade-up" data-aos-once="true" data-aos-delay="150" className={fin.MfinCard}>
                    <img src={img} alt={title} className={fin.McardImg} />
                    <h3 className={fin.Mtitle}>{title}</h3>
                    <div className={fin.Mcontent}>{content}</div>
                </div>}
        </>
        );
    }
    return (
        <div className={fin.cardList}>
            {Cards.map((card, index) => (
                <Card
                    key={card.id}
                    id={card.id}
                    title={card.title}
                    content={card.content}
                    img={card.img}
                    isOdd={index % 2 === 0}
                />
            ))}
        </div>
    )
}

export default FinCards