import Navbar from "react-bootstrap/Navbar";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import Logo from "../../assets/Logonew1.svg";
import SusPDF from "../../assets/SustainabilityEconomicsPressRelease.pdf";

import "./header.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NotificationStrip from "./notificationStrip/NotificationStrip";

function BasicExample() {
  const navigate = useNavigate();

  const location = useLocation();

  const navigateContact = () => {
    navigate("/");
  };

  const currentPath1 = window.location.pathname.split("/");
  const [originalTitle, setOriginalTitle] = useState(document.title); // Store the original title

  const [isEnlarged, setIsEnlarged] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsEnlarged((prevIsEnlarged) => !prevIsEnlarged);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const openPdfInNewTab = () => {
    document.title = "Your Custom Title"; // Update the document title before generating the PDF
    window.open(SusPDF, "_blank");
  };

  useEffect(() => {
    return () => {
      document.title = originalTitle; // Restore the original document title
    };
  }, [originalTitle]);

  const handleSubDropDownClick = (event) => {
    event.stopPropagation();

    if (subDropRef.current) {
      subDropRef.current.classList.toggle("active-sub-drop-down");
    }
  };

  const subDropRef = useRef(null);

  return (
    <div className="firstDiv">
      <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          {/*Change */}
          <div className="logo_img1" onClick={navigateContact}>
            <div className="navbar-brand">
              <img className="img_title1" src={Logo} alt=".svg" />
            </div>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link
                  className={`nav-link nav-effect ${currentPath1[1] === "" ? "active" : ""
                    }`}
                  aria-current="page"
                  to="/"
                >
                  Home
                </Link>
              </li>

              <li className="nav-item dropdown">
                <Link
                  className={`nav-link nav-effect ${currentPath1[1] === "solutions" ? "active" : ""
                    } dropdown-toggle`}
                  to="/solutions"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Solutions
                </Link>
                <ul
                  className="dropdown-menu nav-effect"
                  style={{ textAlign: "center" }}
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <Link
                      className={`dropdown-item nav-effect ${currentPath1[1] === "solutions" &&
                        currentPath1[2] === "finance"
                        ? "active"
                        : ""
                        }`}
                      to="/solutions/finance"
                    >
                      Financial Institutions
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link nav-effect ${currentPath1[1] === "about" ? "active" : ""
                    }`}
                  to="/about"
                >
                  About us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link nav-effect ${currentPath1[1] === "event" ? "active" : ""
                    }`}
                  to="/events"
                >
                  Events
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link nav-effect ${currentPath1[1] === "contact" ? "active" : ""
                    }`}
                  to="/contact"
                >
                  Contact
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className={`nav-link nav-effect ${currentPath1[1] === "media" ? "active" : ""
                    } dropdown-toggle`}
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Media
                </Link>
                <ul
                  className="dropdown-menu nav-effect"
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <Link
                      className={`dropdown-item nav-effect ${currentPath1[1] === "media" &&
                        currentPath1[2] !== "pressrelease"
                        ? "active"
                        : ""
                        }`}
                      to="/media"
                    >
                      Media Coverage
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`dropdown-item nav-effect  ${currentPath1[1] === "media" &&
                        currentPath1[2] === "press-release"
                        ? "active"
                        : ""
                        }`}
                      to="/media/press-release"
                    >
                      Press Release
                    </Link>
                  </li>
                </ul>
              </li>

              <li
                className={`nav-item cheader ${isEnlarged ? "enlarging" : "shrinking"
                  }`}
              >
                <Link
                  className={`nav-link nav-effect fireworks-link ${currentPath1[1] === "clem" ? "active" : ""
                    }`}
                  to="/clem"
                >
                  CLEM
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {location.pathname !== "/events"  && <NotificationStrip />}
    </div>
  );
}

<Navbar.Toggle classNameName="toggle" aria-controls="basic-navbar-nav" />;
export default BasicExample;
