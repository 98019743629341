import React, { useState } from "react";
import classes from "./LandingPage.module.scss";
import SolarWind from "../../assets/icons/solarWindImg.png";
import { ReactComponent as CloseIcon } from "../../../component/assets/icons/close.svg";

const LandingPage = () => {
  const [videoVisible, setVideoVisible] = useState(false);

  const watchOverviewClickHandler = () => {
    setVideoVisible(true);
  };

  const blackScreenClickHandler = () => {
    setVideoVisible(false);
  };

  return (
    <div className={classes["landing-page-container"]}>
      <div className={classes["landing-page-image-container"]}>
        <img src={SolarWind} alt="logo" width={"100%"} height={"100%"} />
      </div>
      <div className={classes["landing-page-content"]}>
        <p className={classes["landing-page-content-heading"]}>
          Making Clean Energy Transitions <span>Profitable</span>
        </p>
        <p className={classes["landing-page-content-desc"]}>
          Embark on a transformative journey with our expert guidance as you
          assess your current energy landscape, strategically plan your
          transition from fossil fuels to clean energy.
        </p>
        <div className={classes.button} onClick={watchOverviewClickHandler}>
          Watch overview
        </div>
      </div>

      {videoVisible && (
        <div
          className={classes["black-screen"]}
          onClick={blackScreenClickHandler}
        >
          <div className={classes["video-container"]}>
            <iframe
              height={"100%"}
              width={"100%"}
              src="https://sustainabilityeconomicsnews.com/wp-content/uploads/2024/08/CLEM-final-3.mp4"
            ></iframe>
            <div className={classes["close-icon-container"]}>
              <CloseIcon />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingPage;
