import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import ClemBlueBackground from "../../assets/ClemBlueBackground.svg";
import GFanzLogo from "../../assets/companyLogos/GFanzLogo";
import SMBCLogo from "../../assets/companyLogos/SMBCLogo";
import ACILogo from "../../assets/companyLogos/ACILogo";
import GPCLLogo from "../../assets/companyLogos/GPCLLogo";
import GCFLogo from "../../assets/companyLogos/GCFLogo.png"
import ClimateImpactXLogo from "../../assets/companyLogos/ClimateXLogo";
import { ReactComponent as CalenderIcon } from "../../../component/assets/calander.svg";
import { ReactComponent as LocationIcon } from "../../../component/assets/location.svg";
import { ReactComponent as IdxIcon } from "../../../component/assets/idxcarbon.svg";
import { ReactComponent as JacobsonLogo } from "../../../component/assets/jacobSolutions.svg";
import { ReactComponent as CleanLogo } from "../../../component/assets/companyLogos/clean.svg"
import { ReactComponent as CFAsia } from "../../../component/assets/companyLogos/CFAsia.svg";
import { ReactComponent as ClimateBondsInitiative } from "../../../component/assets/icons/climateBondsInitiative.svg";
import { ReactComponent as NRELOGO } from "../../../component/assets/icons/nrelLogo.svg";
import { ReactComponent as Newmarketcapital } from "../../../component/assets/icons/NewmarketCapital.svg";
import { ReactComponent as AnthropoceneFixed } from "../../../component/assets/icons/anthropoceneFixed .svg";
import { ReactComponent as TaraClimateFoundation } from "../../../component/assets/icons/taraClimate.svg";
import { ReactComponent as ArticleSixGroup } from "../../../component/assets/icons/articleSix.svg";


import Form from "../../form/Form";

import g from "./bg.module.css";

const Bg = ({ img,mobileVersion }) => {
  const [formVisibility, setFormVisibility] = useState(false);
  const registerBtnRef = useRef(null);

  const navigate = useNavigate();

  const navigateContact = () => {
    navigate("/contact");
  };

  const registerClickHandler = () => {
    const element = registerBtnRef.current;
    const elementRect = element.getBoundingClientRect();
    const absoluteElementTop = elementRect.top + window.pageYOffset;
    const middle =
      absoluteElementTop - window.innerHeight / 2 + elementRect.height / 2;

    window.scrollTo({
      top: middle,
      behavior: "smooth",
    });

    setFormVisibility(true);
  };

  const blackScreenClickHandler = () => {
    setFormVisibility(false);
  };

  const successHandler = () => {
    setFormVisibility(false);
  };

  return (
    <div className={g.imgDiv}>
      <div
        className={g.imgWrapper}
        style={{
          backgroundImage: `url(${img})`,
        }}
      />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div
        data-aos="fade-right"
        data-aos-once="true"
        className={`${g.headingPart} ${g.clemBlueBackground}`}
      // style={{
      //   backgroundImage: `url(${img})`,
      //   backgroundSize: "cover",
      //   backgroundPosition: "center",
      //   backgroundRepeat: "no-repeat",
      // }}
      >
        <div className={g.heading}>
          CLEM (Clean Energy Mechanism) - Making Clean Energy Transitions
          Profitable
        </div>

        <div className={g.heading2}>
          <div className={g.heading2sub}>Main Event</div>
          <div className={g.heading2Span}>
            <div className={g.heading2Icon}>
              <CalenderIcon />
            </div>
            <div>
              Thur, 26<sup>th</sup> Sep
            </div>
          </div>
          <div className={g.heading2Span}>
            <div className={g.heading2LocationIcon}>
              <LocationIcon />
            </div>
            <div>
              One World Trade Center 285 Fulton Street, 64th Floor New York, NY
              10007
            </div>
          </div>
        </div>

        <div className={g.heading2}>
          <div className={g.heading2sub}>Breakout Sessions</div>
          <div className={g.heading2Span}>
            <div className={g.heading2Icon}>
              <CalenderIcon />
            </div>
            <div>
              23<sup>rd</sup> - 24<sup>th</sup> Sep
            </div>
          </div>
          <div className={g.heading2Span}>
            <div className={g.heading2LocationIcon}>
              <LocationIcon />
            </div>
            <div>One World Trade Center, Suite 8500, NY 10048</div>
          </div>
        </div>

        <div className={g.book}>
          {/* <button onClick={registerClickHandler} ref={registerBtnRef}>
            Register Now
          </button> */}
          {formVisibility && (
            <>
              <div
                className={g["black-screen"]}
                onClick={blackScreenClickHandler}
              ></div>
              <div className={g["form-container"]}>
                <Form onSuccess={successHandler} />
              </div>
            </>
          )}
        </div>

        <div className={g.heading2res}>You’ll hear from</div>

        <div className={g.companyDiv2}>
          <div className={g.companyNames}>
            <div className={g.companyNamesContainer}>
              <div>
                Global Financial Institutions{" "}
                <span className={g.companyNameSpan}>
                  {" "}
                  (equity, debt, philanthropic funds)
                </span>
              </div>
              <div>Clean Energy Source Technology Experts</div>
              <div>CFPP Owners / RE Developers</div>
            </div>

            <div className={g.companyNamesContainer}>
              <div>
                Legal Institutions{" "}
                <span className={g.companyNameSpan}>(PPA amendments)</span>
              </div>
              <div>Carbon Registry / Verifiers</div>
              <div>Policy Makers</div>
            </div>

            <div className={g.companyNamesContainer}>
              <div>Methodology Developers</div>
              <div>Project Proponent</div>
              <div>Power Purchasers</div>
            </div>
          </div>
        </div>

        <div className={g.heading2res}>Speakers from</div>

        <div className={g.companyDiv}>
          <div
            className={g.companyLogoContainer}
          >
            <GFanzLogo height={58} width={128} />
            <SMBCLogo height={48} width={128} />
            <ACILogo height={78} width={208} />
            <GPCLLogo height={78} width={148} />
            <ClimateImpactXLogo height={78} width={208} />

            <IdxIcon height={78} width={148} />
            <AnthropoceneFixed height={88} width={168} />
            <CFAsia height={68} width={148} />
            <NRELOGO height={78} width={148} />
            <ClimateBondsInitiative height={78} width={148} />

            <Newmarketcapital height={56} width={290} />
            <CleanLogo width={168} height={68} />
            <TaraClimateFoundation width={168} height={68} />
            <ArticleSixGroup width={168} height={88} />

            {!mobileVersion?<img src={GCFLogo} alt="GCF Logo" style={{width:'15%', height: '88px' }} />:<img src={GCFLogo} alt="GCF Logo" style={{width:'60%', height: '48px' }} />}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Bg;
