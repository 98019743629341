import React from 'react'
import Header from '../home/header/header'
import Content from "./content/content"
import Footer from '../home/Footer/footer'
export default function Page() {
  return (
    <>
      
      <Content />
     
    </>
  )
}
