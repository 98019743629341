import React from 'react'
import Contact from './contact';
import Form from './form';

function Main() {
  return (
    <div>
        
        <Contact />
        <Form />
      
    </div>
  )
}

export default Main