import React from "react";
import useMobile from "../useMobile";
import SeMainLogo from "../../../assets/SeMainLogo";
import SeLogo from "../../../assets/companyLogos/SELogo";

import e from "../eventsClem1.module.css";

function IntroCard({ showLogo = true, data }) {

  const mobileVersion = useMobile();

  return mobileVersion ? (
    <div className={e.RealityCheck}>
      <div className={e.RealityCheckHeader}>
        <div className={e.RealityCheckHeaderContainer}>{data.text}</div>
        <div className={e.AgendaTimings}>{data.timings}</div>
      </div>

      <div className={e.RealityCheckSubHeader} style={{position: 'relative', bottom: '10px'}}>
        {data.subText}
      </div>

      {showLogo ? <div className={e.RealityCheckPanelContainer}>
        <div className={e.RealityCheckPanelNames}></div>

        <div className={e.RealityCheckPanelImages}>
          <div className={e.RealityCheckPanelImagesContainer}>
            <SeMainLogo height={40} width={85} />
          </div>
        </div>
      </div> : null}
    </div>
  ) : (
    <div className={e.Introduction}>
      <div className={e.IntroductionHeader}>
        <div>{data.text}</div>
        <div className={e.IntroductionSubHeader}>
          {data.subText}
        </div>
      </div>

      <div className={`${e["AgendaTimings"]} ${e["agenda-timings-intro"]}`}>

        {showLogo ? <div>
          <SeLogo height={68} width={168} />
        </div> : null}

        
        <div className={e.AgendaTimings}>{data.timings}</div>
      </div>
    </div>
  );
}

export default IntroCard;
