import React, { useState } from "react";
import classes from "./Dropdown.module.scss";
import { ReactComponent as DownArrow } from "../../../component/assets/down_arrow.svg";

const Dropdown = ({ options, onChange, defaultSelected = false }) => {
  const [optionsVisibility, setOptionsVisibility] = useState(false);
  const [selectedValue, setSelectedValue] = useState((defaultSelected === true ? options[0] : ""));

  const dropdownClickHandler = () => {
    setOptionsVisibility((prevVal) => !prevVal);
  };

  const dropdownElClickHandler = (el, index) => () => {
    onChange?.(index);
    setSelectedValue(el);
  };

  const dropdownFunc = (index) => {
    if (index === 0) {
      return `${classes["dropdown-ele"]} ${classes["dropdown-ele-first"]}`;
    } else if (index === options.length - 1) {
      return `${classes["dropdown-ele"]} ${classes["dropdown-ele-last"]}`;
    } else {
      return `${classes["dropdown-ele"]} ${classes["dropdown-ele-mid"]}`;
    }
  };

  return (
    <div
      className={classes["dropdown-container"]}
      onClick={dropdownClickHandler}
    >
      {selectedValue}
      {optionsVisibility && (
        <div className={classes["options-container"]}>
          {options?.map((el, index) => (
            <div
              className={dropdownFunc(index)}
              onClick={dropdownElClickHandler(el, index)}
            >
              {el}
            </div>
          ))}
        </div>
      )}
      <div className={classes["form-container"]}>
        <DownArrow />
      </div>
    </div>
  );
};

export default Dropdown;
