import React from 'react';
import Header from "../../home/header/header";

function PrivacyPolicy() {
  return (
    <>
      <Header />
    </>
  )
}

export default PrivacyPolicy;