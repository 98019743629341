import Aos from "aos";
import { v4 as uuidv4 } from "uuid";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import "aos/dist/aos.css";

import Home from "./component/home/home";
import Clem from "./component/clem/clem.js";
import EventsClem1 from "./component/events/eventsClem1/eventsClem1";
import EventsClem2 from "./component/events/eventsClem2/eventsClem2";
import About from "./component/about/about";
import Main from "./component/contact/main";
import Events from "./component/events/events";
import Press from "../src/component/press/page.js";
import Solutions from "./component/solutions/solution";
import PressRelease from "./component/pressRelease/page";
import BlogMain from "../src/component/blog/blogMain.js";
import SolutionsEnergy from "./component/solutions/energyPage/main";
import Financial from "./component/solutions/financialPage/main.js";
import PressReleaseClem from "./component/pressRelease/pressReleaseClem/pressReleaseClem";
import PressReleaseCfpp from "./component/pressRelease/pressReleaseCfpp/pressReleaseCfpp";
import PrivacyPolicy from "./component/legal/privacypolicy/PrivacyPolicy.js";
import TermsAndConditions from "./component/legal/termsAndConditions/TermsAndConditions.js";

import Layout from "./component/layout/layout";

import "./App.css";

function App() {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const cookieName = "cookieNoticeShown";
    const existingCookieValue = getCookie(cookieName);

    if (existingCookieValue === null) {
      // Cookie does not exist, show the banner
      setShowBanner(true);
    } else {
      // Cookie exists, do not show the banner
      setShowBanner(false);
    }
  }, []);

  const handleCookieAccept = async () => {
    const cookieName = "cookieNoticeShown";
    const cookieValue = uuidv4();
    const expirationDate = new Date();

    /* Too see The View Date */
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "UTC",
    };
    const formattedDate = new Date();
    formattedDate.setUTCHours(formattedDate.getUTCHours() + 5);
    formattedDate.setUTCMinutes(formattedDate.getUTCMinutes() + 30);
    const ViewDate = formattedDate.toLocaleDateString("en-US", options);

    expirationDate.setDate(expirationDate.getDate() + 365); // Cookie expires in 365 days
    document.cookie = `${cookieName}=${cookieValue}; expires=${expirationDate.toUTCString()}; path=/`;

    const res = await fetch(
      "https://sustainabilityeconomics-4fca5-default-rtdb.firebaseio.com/Cookies.json",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cookieValue,
          ViewDate,
        }),
      }
    );


    // Convert to the desired format

    setShowBanner(false);
  };

  const handleCookieDismiss = () => {
    setShowBanner(false);
  };

  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const [cookieName, cookieValue] = cookies[i].split("=");
      if (cookieName === name) {
        return cookieValue;
      }
    }
    return null;
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="App">
      {/* {showCookieBanner &&  */}
      {showBanner && (
        <div className="cookie-banner">
          <p>
            We use cookies to improve your browsing experience. By clicking
            "Accept", you consent to the use of cookies.
          </p>
          <div className="cookie-button">
            <button onClick={handleCookieAccept}>Accept</button>
            <button onClick={handleCookieDismiss}>Dismiss</button>
          </div>
        </div>
      )}
      <Routes>
        <Route exact path="/" element={  <Layout>
          <Home />
        </Layout> } />

        <Route exact path="/contact" element={ <Layout>
          <Main />
        </Layout>  } />
        <Route exact path="/about" element={  <Layout>

         <About />
        </Layout>
         } />
        <Route exact path="/clem" element={  <Layout>
          <Clem />
        </Layout> } />
        <Route exact path="/event" element={   <Layout>
          <Events />
        </Layout> 
        
       
        
        } />
        <Route exact path="/events" element={
        <Layout>

        <EventsClem1 />
        </Layout>

        } />
        <Route exact path="/events/clem-event2" element={
        <Layout>

        <EventsClem2 />
        </Layout>
        } 

        />
        <Route exact path="/media" element={
        <Layout>

        <Press />
        </Layout>
        } />
        <Route exact path="/solutions/finance" element={<Layout><Financial /></Layout>} />
        <Route exact path="/media/press-release" element={  <Layout>

         <PressRelease />
        </Layout>
        
         } />
        <Route
          exact
          path="/media/press-release/sustainabilityeconomics-aci-partnership"
          element={   <Layout> 
          <PressReleaseCfpp />
          </Layout>  
          
          
          }
        />
        <Route
          exact
          path="/media/press-release/se-ptsucofindo-partnership"
          element={ <Layout>

           <PressReleaseClem />
          </Layout> 
          
           }
        />

        <Route
          exact
          path="/solutions/energy-power"
          element={
            <Layout>
              <SolutionsEnergy />
            </Layout>
          }
        />

        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route
          exact
          path="/terms"
          element={
            <Layout>
              <TermsAndConditions />
            </Layout>
          }
        />
        {/* <Route exact path="/blogs" element={<BlogMain />} /> */}
      </Routes>
    </div>
  );
}

export default App;
