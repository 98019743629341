import React from "react";
import ClimateBondInitiativeCompanyLogo from "../../assets/companyLogos/ClimateBondInitiativeLogo.png"
import classes from "./ClimateBondInitiativeLogo.module.css"

const ClimateBondInitiativeLogo = ({
    color = "#666666",
    height = "44px",
    width = "208px",
}) => {
    return (
        <img
            src={ClimateBondInitiativeCompanyLogo}
            height={height}
            width={width}
            className={classes.ClimateBondInitiativeLogoClass}
        />
    )
};

export default ClimateBondInitiativeLogo;
