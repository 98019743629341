import React from "react";


import c from "./pressReleaseClem.module.css";
import ClemPic from "../../../component/assets/clemPic.png";

import yahooLogo from "../../../component/assets/yahooLogo.svg";
import quantLogo from "../../../component/assets/quantumLogo.svg";
import IEPLogo from "../../../component/assets/eveningLogo.svg";
import ClemInfoImage from "../../../component/assets/clemStrategy.png";

import GlobeIcon from "../../../component/assets/GolbeNewsWireIcon.svg";
import AsiaOneIcon from "../../../component/assets/AsiaOneIcon.svg";
import DailyIcon from "../../../component/assets/DailyGuardianIcon.svg";

const pressReleaseClem = () => {
    const redirectToPath = (link) => {
        window.open(link)
    }

    return (
        <>
           

            <div className={c.ParentDiv}>
                <div className={c.mainTitle}>
                    Sustainability Economics partners with PT SUCOFINDO, a state-owned
                    company of Indonesia, to accelerate the transition of coal-fired power
                    plants to clean energy sources in Indonesia
                </div>

                <div className={c.imageContainer}>
                    <img src={ClemPic}></img>
                </div>

                <p className={c.subject}>
                    <span className={c.subjectWords}>Singapore,26 July 2024 -</span>{" "}
                    Sustainability Economics, an international entity, providing
                    end-to-end net-zero solutions for high emitting sectors brings in
                    first-of-its-kind CLEM (Clean Energy Mechanism) an end-to-end solution
                    for CFPP transitions with a vision of making transition profitable,
                    has announced to partner with PT SUCOFINDO, a state owned company
                    supporting government policies and capacity building to accelerate
                    CFPPs transitions across Indonesia.
                </p>

                <div className={c.secondPara}>
                    <span className={c.articleDescription}>
                        Established in 1956, PT SUCOFINDO is a joint venture company between
                        the Government of the Republic of Indonesia and SGS, Geneve,
                        Switzerland. As the first inspection in Indonesia, the company
                        currently works closely with the Indonesian policymakers, CFPP asset
                        owners, power purchasers and financial institutions to accelerate
                        clean energy transitions across Indonesia.
                    </span>
                </div>

                <div className={c.secondPara}>
                    <span className={c.articleDescription}>
                        Sustainability Economics, an international entity headquartered in
                        Singapore and US, with a big development center in Bangalore
                        providing end-to-end net-zero solutions across high emitting sectors
                        for global businesses. Sustainability Economics brings CLEM (Clean
                        Energy Mechanism), a first-of-its-kind end-to-end solution to
                        accelerate clean energy transitions globally.
                    </span>
                </div>

                <article className={c.articleDiv}>
                    <div className={c.articleHeading}>
                        Kasu Venkata Reddy, Co-Founder/CEO of Sustainability Economics :
                        <span className={c.articleDescription}>
                            "We are excited to partner with PT SUCOFINDO in our shared mission
                            to accelerate the clean energy transition across Indonesia. This
                            collaboration highlights our strong commitment towards achieving
                            net zero goals globally"
                        </span>
                    </div>
                </article>

                <article className={c.articleDiv}>
                    <div className={c.articleHeading}>
                        Budi Utomo, Director of Industrial Services of SUCOFINDO:
                        <span className={c.articleDescription}>
                            "Partnership with Sustainability Economics will equip our
                            competence and end-to-end service with an international presence
                            that bring values to the Independence Power Producers’ agenda of
                            energy transition towards net zero emissions with the principles
                            of just and affordability.”
                        </span>
                    </div>
                </article>

                <article className={c.articleDiv}>
                    <div className={c.articleHeading}>
                        CLEM – Practical & Crafted Approach
                        <div className={c.articleDescription}>
                            Coal-fired power generation remains a significant contributor to
                            global carbon emissions, particularly in Asia, where a
                            concentration of young CFPPs exists, averaging less than 15 years
                            old. To address this pressing issue, CLEM, a first-of-its-kind
                            clean energy transition tailor-made solution, takes a practical
                            data-driven approach working with CFPP asset owners, power
                            purchasers and policymakers by connecting the dots to automate,
                            simplify and scale the clean energy transitions.
                        </div>
                    </div>
                </article>

                <article className={c.articleDiv}>
                    <div className={c.articleHeading}>
                        CLEM VISION: MAKING ENERGY TRANSITIONS PROFITABLE
                        <div className={c.articleDescription}>
                            CLEM’s vision is to make energy transition not just practical but
                            profitable for CFPP asset owners and financiers. Leveraging a
                            cutting-edge, data-driven approach, CLEM’s tailor-made solutions
                            provide the most effective and strategic clean energy transition
                            planning while ensuring profitability with a make-it-happen
                            approach
                        </div>
                    </div>
                </article>

                <div
                    style={{
                        textAlign: "center",
                        marginTop: "12px",
                        marginBottom: "12px",
                    }}
                >
                    <img className={c.clemInfoImage} src={ClemInfoImage}></img>
                </div>

                <div className={c.articleHeadingContact}>
                    Key outcomes:
                    <span className={c.articleDescription}></span>
                </div>

                <div className={c.articleHeadingContact}>
                    Current state discovery –
                    <span className={c.articleDescription}>
                        {" "}
                        Data-driven extraction of technical and financials of current state
                        of CFPP
                    </span>
                </div>

                <div className={c.articleHeadingContact}>
                    Transition planning –
                    <span className={c.articleDescription}>
                        {" "}
                        Practical location/country-specific transition plan (phased
                        approach) by collaborating with CFPP owners, power purchasers,
                        financial institutions and policymakers.
                    </span>
                </div>

                <div className={c.articleHeadingContact}>
                    Economic gap –
                    <span className={c.articleDescription}>
                        {" "}
                        Practical data-driven mechanism to nail down exact dollars CFPP
                        owners have to forego as part of the transition.
                    </span>
                </div>

                <div className={c.articleHeadingContact}>
                    Energy mix –
                    <span className={c.articleDescription}>
                        {" "}
                        Model-driven approaches covering region/country-specific policies to
                        identify the potential clean energy source technologies and the cost
                        associated along with financial analysis. Build clean energy source
                        technologies working with region/country specific partners.
                    </span>
                </div>

                <div className={c.articleHeadingContact}>
                    Transition finance –
                    <span className={c.articleDescription}>
                        {" "}
                        Data-driven, practical approach to identify transition financing
                        requirements mapping with clear timelines covering different
                        business models. Constructing transition financing requirements by
                        using innovative global financial instruments to make transition
                        profitable and working with our equity, debt global financial
                        partners to raise funds.
                    </span>
                </div>

                <div className={c.articleHeadingContact}>
                    Blockchain-driven Marketplace –
                    <span className={c.articleDescription}>
                        {" "}
                        Source the right clean energy source technology by working with
                        clean energy source developers to speed up the transition.
                    </span>
                </div>

                <div className={c.articleHeadingContact}>
                    Financial analysis –
                    <span className={c.articleDescription}>
                        {" "}
                        Provide detailed financial analysis with innovative transition
                        financing instruments and transition credit cash flows for both
                        equity and debt players.
                    </span>
                </div>

                <div className={c.articleHeadingContact}>
                    Summary –
                    <span className={c.articleDescription}>
                        {" "}
                        Demonstrate profitability with transitions by comparing different
                        options for CFPP owners and financial institutions
                    </span>
                </div>

                <div className={c.articleHeadingContact}>Contact:</div>

                <div className={c.contactMain}>
                    <div className={c.contactSubContainer}>
                        <div className={c.articleHeading}>Suzanne Linville</div>

                        <div className={c.contactInfoContainer}>Communications manager</div>

                        <div className={c.contactInfoContainer}>Phone: +720.708.9806</div>

                        <div className={c.contactInfoContainer}>
                            Email:
                            <a
                                className={c.emailContainer}
                                href="mailto:esther@sustainabilityeconomics.com"
                            >
                                {" "}
                                suzanne@sustainabilityeconomics.com
                            </a>
                        </div>
                    </div>
                </div>

                <div className={c.adContainer}>
                    <div className={c.adContainerHeading}>Coverage Across</div>

                    <div className={c.adLogoContainer}>
                        {/* <div
                            onClick={() =>
                                redirectToPath(
                                    "https://www.globenewswire.com/en/news-release/2024/07/26/2919404/0/en/Sustainability-Economics-partners-with-PT-SUCOFINDO-a-state-owned-company-of-Indonesia-to-accelerate-the-transition-of-coal-fired-power-plants-to-clean-energy-sources-in-Indonesia.html"
                                )
                            }
                        >
                            <img src={GlobeIcon} alt="Yahoo Logo"></img>
                        </div> */}

                        <div
                            onClick={() =>
                                redirectToPath(
                                    "https://sg.finance.yahoo.com/news/sustainability-economics-partners-pt-sucofindo-085000562.html"
                                )
                            }
                        >
                            <img src={yahooLogo}></img>
                        </div>

                        <div
                            onClick={() =>
                                redirectToPath(
                                    "https://www.asiaone.com/business/sustainability-economics-partners-pt-sucofindo-state-owned-company-indonesia-accelerate"
                                )
                            }
                        >
                            <img src={AsiaOneIcon} alt="Asia One Logo"></img>
                        </div>

                        <div
                            onClick={() =>
                                redirectToPath(
                                    "https://dailyguardian.ca/sustainability-economics-partners-with-pt-sucofindo-a-state-owned-company-of-indonesia-to-accelerate-the-transition-of-coal-fired-power-plants-to-clean-energy-sources-in-indonesia/?amp=1"
                                )
                            }
                        >
                            <img src={DailyIcon} alt="Daily Guarding Logo"></img>
                        </div>
                    </div>
                </div>
            </div>

            
        </>
    );
};
export default pressReleaseClem;
