import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import React, { useRef, useState } from "react";

import Form from "../../../form/Form";
import SeLogo from "../../../assets/companyLogos/SELogo";
import SMBCLogo from "../../../assets/companyLogos/SMBCLogo";
import TransitionFinanceOpportunityBackgroundImage from "../../../assets/TransitionFinanceOpportunityBackgroundImage.png";

import g from "./transitionFinanceOpportunityBackground.module.css";

const TransitionFinanceOpportunityBackground = () => {

    const registerBtnRef = useRef(null);
    const [formVisibility, setFormVisibility] = useState(false);

    const navigate = useNavigate();

    const navigateContact = () => {
        navigate("/contact");
    };

    const registerClickHandler = () => {
        const element = registerBtnRef.current;
        const elementRect = element.getBoundingClientRect();
        const absoluteElementTop = elementRect.top + window.pageYOffset;
        const middle =
            absoluteElementTop - window.innerHeight / 2 + elementRect.height / 2;

        window.scrollTo({
            top: middle,
            behavior: "smooth",
        });

        setFormVisibility(true);
    };

    const blackScreenClickHandler = () => {
        setFormVisibility(false);
    };

    const successHandler = () => {
        setFormVisibility(false);
    };

    return (
        <div className={g.imgDiv}>
            <div>
                <img
                    className={g.clemBlueBackground}
                    src={TransitionFinanceOpportunityBackgroundImage}
                    alt="TransitionFinanceOpportunityBackgroundImage"
                />
            </div>

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <div data-aos="fade-right" data-aos-once="true" className={g.headingPart}>
                <div className={g.heading}>
                    Transition Financing Opportunities: Making CFPP to Clean Energy Transitions Profitable Through CLEM (Clean Energy Mechanism)
                </div>

                <div className={g.heading2}>
                    One World Trade Center, Suite 8500, NY 10048
                    <div className={g.heading2Span}>
                        26<sup>th</sup> September 2024
                    </div>
                </div>

                <div className={g.book}>
                    <button onClick={registerClickHandler} ref={registerBtnRef}>
                        Request for Invitation
                    </button>
                    {formVisibility && (
                        <>
                            <div
                                className={g["black-screen"]}
                                onClick={blackScreenClickHandler}
                            ></div>
                            <div className={g["form-container"]}>
                                <Form onSuccess={successHandler} />
                            </div>
                        </>
                    )}
                </div>

                <div className={g.heading2res}>You’ll hear from</div>

                <div className={g.companyDiv2}>
                    {financeExperts.map((data, dindex) => (
                        <div key={dindex} className={g.companyDiv2Container}>
                            <div className={g.companyDiv2Heading}>
                                {data.heading}
                            </div>
                            <div>
                                <ul className={g.companyDiv2List} >
                                    {data.experts.map((expert, eindex) => (
                                        <li className={g.companyDiv2ListItem} key={eindex}>
                                            {expert}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}

                    <div
                        className={g.companyDiv2PointerContainer}
                    >
                        <div className={g.companyDiv2Heading}>
                            Just Transition
                        </div>

                        <div className={g.companyDiv2Heading}>
                            Transition Credits
                        </div>
                    </div>
                </div>

                <div className={g.heading2res}>Supported by</div>

                <div className={g.companyDiv}>
                    <div className={g.companyLogoContainer}>
                        <SeLogo height={78} width={128} />
                        <SMBCLogo height={78} width={208} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransitionFinanceOpportunityBackground;

const financeExperts = [
    {
        heading: "Equity Experts",
        experts: [
            "Private Equity",
            "Strategic / Impact Equity",
            "Grants"
        ]
    },
    {
        heading: "Debt Experts",
        experts: [
            "Green Bonds / Sustainability Bonds / Sustainability Linked Bonds",
            "Green Loans / Sustainability Linked Loans",
            "Concessional Loans",
            "Standard Loans",
            "Secured Loans",
            "Debentures",
            "Secure Credit Lines",
            "High Yield Debt"
        ]
    }
];
