import React from "react";
import whoCSS from "./who.module.css";

export default function Who() {
  return (
    <div className={whoCSS.parentDiv}>
      <div className={whoCSS.mainDiv}>
        {/* <h1 data-aos="fade-up" data-aos-once="true" className={whoCSS.sehead}>Net-zero Sustainability Economics</h1> */}
        <div data-aos="fade-up" data-aos-once="true" className={whoCSS.who}>
          Who We Are
        </div>
        <div data-aos="fade-up" data-aos-once="true" className={whoCSS.text}>
          <h1 style={{ display: "inline" }}>Sustainability Economics </h1>
          <span>
            focuses on the solutions covering e2e NetZero transitions across
            multiple sectors. We use cutting edge technology to simplify,
            automate and scale. Our solutions embrace global standards by
            collaborating with multiple industry forums and ecosystem partners.
            We are a global player, and our team is a combination of seasoned
            corporate executives and global leaders with diverse expertise who
            can connect the dots and simplify the customer's net-zero transition
            journey.
          </span>
        </div>
      </div>
      <div data-aos="fade-up" data-aos-once="true" className={whoCSS.img}>
        <div className={whoCSS.videopart}>
          <video
            className={whoCSS.video}
            controls={true}
            // autostart="true"
            // autoPlay
            // loop
            // muted
            // controlsList="nodownload"
            src="https://sen-website-video.s3.ap-south-1.amazonaws.com/Introduction.mp4"
            type="video/mp4"
          />
        </div>
      </div>
    </div>
  );
}
