import React from "react";
import Header from "../home/header/header";
import "./about.css";
import Footer from "../home/Footer/footer";
import Aboutus from "./aboutus/aboutus";
import Cards from "./cards/cards";
import Team from "./team/team";
import Work from "./work/work";
import { useEffect } from "react";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="background">
     
      <Aboutus />
      <Cards />
      <Work />
      <Team />
     
    </div>
  );
}

export default About;
