import classes from "./PracticalCraftedApproach.module.scss";
import SolarWind from "../../assets/icons/worldMap.png";
import { ReactComponent as PncApproach } from "../../../component/assets/icons/PncApproach.svg";
import { ReactComponent as PncApproachMobile } from "../../../component/assets/icons/PnCApproachMobile.svg";

const PracticalCraftedApproach = ({ mobileVersion }) => {
  return (
    <div className={classes["practical-presence-page"]}>
      {mobileVersion ? (
        <>
          <div className={classes["practical-presence-content"]}>
            <div className={classes["title"]}>Practical & Crafted Approach</div>

            <div className={classes["passage"]}>
              <div className={classes["passage1"]}>
                Coal-fired power generation remains a significant contributor to
                global carbon emissions, particularly in Asia, where a
                concentration of young CFPPs exists, averaging less than 15
                years old. To address this pressing issue, CLEM, a first-of-its-
                kind clean energy transition, tailor-made solution, takes a
                practical data-driven approach.
              </div>
              <div className={classes["passage2"]}>
                CLEM works with CFPP asset owners, power purchasers, and
                policymakers by connecting the dots to automate, simplify, and
                scale clean energy transitions.
              </div>
            </div>
            <div className={classes["practical-logo-container"]}>
              <PncApproachMobile />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={classes["practical-presence-content"]}>
            <div className={classes["title"]}>Practical & Crafted Approach</div>
            <div className={classes["passage"]}>
              <div className={classes["passage1"]}>
                Coal-fired power generation remains a significant contributor to
                global carbon <br />
                emissions, particularly in Asia, where a concentration of young
                CFPPs exists, <br />
                averaging less than 15 years old. To address this pressing
                issue, CLEM, a first-of-its- <br />
                kind clean energy transition, tailor-made solution, takes a
                practical data-driven
                <br />
                approach.
                <br />
              </div>
              <div className={classes["passage2"]}>
                CLEM works with CFPP asset owners, power purchasers, and
                policymakers by
                <br />
                connecting the dots to automate, simplify, and scale clean
                energy transitions.
              </div>
            </div>
          </div>
          <div className={classes["practical-logo-container"]}>
            <PncApproach />
          </div>
        </>
      )}
    </div>
  );
};

export default PracticalCraftedApproach;
