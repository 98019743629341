export const formatDate = (dateString) => {
  const months = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };

  const dateParts = dateString.match(/(\w+)\s(\d+)\w*,?\s?(\d{4})?/);

  if (!dateParts) {
    throw new Error("Invalid date format");
  }

  const month = months[dateParts[1].substr(0, 3)];
  const day = dateParts[2].padStart(2, "0");
  const year = dateParts[3] || new Date().getFullYear(); // Use the current year if not provided

  return `${day}-${month}-${year}`;
};
