import React from "react";
import notificationStrip from "./NotificationStrip.module.css";
import { ReactComponent as AnnouncmentIcon } from "../../../assets/AnnouncmentIcon.svg";
import { useNavigate } from 'react-router-dom';

const NotificationStrip = () => {
    const navigate = useNavigate();

    const handleNavigation = () => {
        navigate('/events'); 
    };

   
  return (
    <div className={notificationStrip.stripContainer}>
      <div className={notificationStrip.stripContent}>
      <div className={notificationStrip.stripContentLeft}>
      <AnnouncmentIcon/>
      </div>
        <div className={notificationStrip.stripContentCenter}>Join Sustainability Economics at Climate Week NYC 2024</div>
        <div className={notificationStrip.stripContentRight1}>
            |
        </div>
        <div className={notificationStrip.stripContentRight2} onClick={()=> handleNavigation()} > Register Now</div>
      </div>
    </div>
  );
};

export default NotificationStrip;
