import classes from "./GlobalPresencePage.module.scss";
import WorldMap from "../../assets/gifs/WorldMap.gif";
import WorldMapVersion from "../../assets/gifs/WorldMapMobileVer.gif";

const GlobalPresencePage = ({ mobileVersion }) => {
  return (
    <div className={classes["global-presence-page"]}>
      {!mobileVersion ? (
        <>
          <div className={classes["global-page-image-container"]}>
            <img src={WorldMap} alt="logo" width={"100%"} height={"100%"} />
          </div>
          <div className={classes["global-presence-content"]}>
            <div className={classes["title"]}>Our Global Presence</div>
            <div className={classes["passage"]}>
              At our core, we focus on building strong relationships with our
              customers, enabling us to deeply understand their unique needs and
              challenges. Our global footprint, with offices strategically
              located in the US, UK, Singapore, and Japan, combined with our
              state-of-the-art development center in India, empowers us to
              deliver tailored solutions for our global clients.
            </div>
          </div>
        </>
      ) : (
        <div className={classes["global-presence-content"]}>
          <div className={classes["title"]}>Our Global Presence</div>
          <div className={classes["global-page-image-container-mobile"]}>
            <img
              src={WorldMapVersion}
              alt="logo"
              width={"100%"}
              height={"100%"}
            />
          </div>
          <div className={classes["passage"]}>
            At our core, we prioritize customer intimacy and proximity, enabling
            us to deeply understand your unique needs and challenges. Our global
            footprint, with offices strategically located in the US, UK,
            Singapore, and Japan, combined with our state-of-the-art Innovation
            Capability Centre in India, empowers us to deliver tailored
            solutions.
          </div>
        </div>
      )}
    </div>
  );
};

export default GlobalPresencePage;
