import { useState, useEffect } from "react";
import { useRef } from "react";
import "./AllSector.css"; // import your CSS file with the animation styles
import ghg from "../../assets/ghg.svg";
import PCAF from "../../assets/PCAF.svg";
import IFRS from "../../assets/IFRS.svg";
import TCFD from "../../assets/TCFD.svg";
import Vector from "../../assets/Vector.svg";
import EFRAG from "../../assets/EFRAG.svg";
import CDP from "../../assets/CDP.svg";
import SASB from "../../assets/SASB1.svg";
import SBT from "../../assets/SBT1.svg";
import GFANZ from "../../assets/GFANZ1.svg";
import ICMA from "../../assets/ICMA1.svg";
import CB from "../../assets/Climate1.svg";
import ASEAN from "../../assets/ASEAN1.svg";
import VCMI from "../../assets/VCMI1.svg";
import CAR from "../../assets/CAR1.svg";
import GS from "../../assets/GS1.svg";
import ACG from "../../assets/ACR.svg";
import VCS from "../../assets/VCS1.svg";
import IEA from "../../assets/IEA.svg";
import IPCC from "../../assets/IPCC.svg";
import NGFS from "../../assets/NGFS.svg";
import GGP from "../../assets/GGP.svg";
import US from "../../assets/US_SEC.svg";
import IRENA from "../../assets/IRENA.svg";

const elements = [
  { id: 1, name: "Accounting", image: [GGP, PCAF] },
  { id: 2, name: "Reporting", image: [IFRS, TCFD, US, EFRAG, CDP, SASB] },
  { id: 3, name: "Target Setting", image: [SBT] },
  { id: 4, name: "Transition Finance", image: [GFANZ] },
  { id: 5, name: "Green Bonds", image: [ICMA, CB, ASEAN] },
  { id: 6, name: "Carbon Markets", image: [VCMI, CAR, GS, ACG, VCS] },
  { id: 7, name: "Energy", image: [IEA, IRENA] },
  { id: 8, name: "Scenario Analysis", image: [IEA, IPCC, NGFS] },
];

function Allsector() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [displayedImage, setDisplayedImage] = useState([]);
  const isMobile = window.matchMedia("(max-width: 767px)").matches;

  const intervalId = useRef(null);

  useEffect(() => {
    intervalId.current = setInterval(() => {
      setActiveIndex((activeIndex) => (activeIndex + 1) % elements.length);
      setCurrentIndex((prevIndex) => {
        if (prevIndex === elements.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      });
    }, 3000);
    return () => clearInterval(intervalId.current);
  }, [elements.length]);

  useEffect(() => {
    setDisplayedImage(elements[activeIndex].image);
  }, [activeIndex]);

  const handleMouseEnter = (index) => {
    if (window.innerWidth >= 768) {
      clearInterval(intervalId);
    }
    clearInterval(intervalId.current); // Pause the animation loop
    setDisplayedImage(elements[index].image); // Show the images associated with the element
  };

  const handleMouseLeave = () => {
    // Resume the animation loop
    intervalId.current = setInterval(() => {
      setActiveIndex((activeIndex) => (activeIndex + 1) % elements.length);
      setCurrentIndex((prevIndex) => {
        if (prevIndex === elements.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      });
    }, 2500);
  };

  return (
    <div className="sec_parentDiv">
      <div className="container-sector">
        {isMobile ? (
          <div className="elements">
            <h3
              className="mobile_active"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {elements[currentIndex].name}
              {/* Changed for SEO*/}
            </h3>
          </div>
        ) : (
          <div
            className="elements"
            data-aos="zoom-in"
            data-aos-once="true"
            data-aos-delay="200"
          >
            {elements.map((element, index) => (
              <h2
                key={element.id}
                className={`element ${index === activeIndex ? "active" : ""}`}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                {element.name}
                {/* Changed for SEO*/}
              </h2>
            ))}
          </div>
        )}
        <div
          className="sector_images"
          data-aos="zoom-in"
          data-aos-once="true"
          data-aos-delay="200"
        >
          {displayedImage.map((images, index) => (
            <div className="img-ctn">
              <img
                key={images}
                src={images}
                alt="Displayed Image"
                className="img_sector"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Allsector;
