import React from "react";

const GFanzLogo = ({ color = "#666666", height = 19, width = 19 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 162 64" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M80.0012 33.4311H75.7505V30.6697H83.489V40.4879C81.4181 41.4084 79.0203 41.9197 75.8595 41.9197C69.3199 41.9197 64.7422 37.8288 64.7422 31.897C64.7422 25.9652 69.5379 21.9766 75.7505 21.9766C78.9113 21.9766 81.8541 22.897 83.489 24.3288L80.9821 26.8857C80.0012 25.8629 77.9303 24.9425 75.8595 24.9425C71.6087 24.9425 68.7749 28.0107 68.7749 31.897C68.7749 35.7834 71.4998 38.9538 75.9685 38.9538C77.4944 38.9538 78.8023 38.7493 80.0012 38.2379V33.4311Z" fill="black" />
            <path d="M90.5753 33.4315V41.4087H86.9785V22.4883H100.058V25.3519H90.5753V30.5678H99.5127V33.4315H90.5753Z" fill="black" />
            <path d="M116.731 41.4095L114.878 37.114H106.05L104.197 41.4095H100.164L108.992 22.3867H112.153L120.873 41.4095H116.731ZM110.409 26.4776L107.249 34.2504H113.57L110.409 26.4776Z" fill="black" />
            <path d="M136.899 41.4087L127.307 26.7837H127.198V41.4087H123.602V22.4883H128.397L137.88 36.7042V22.4883H141.476V41.4087H136.899Z" fill="black" />
            <path d="M145.619 41.4087V38.3406L156.627 25.3519H145.728V22.4883H160.878V25.4542L149.761 38.4428H161.096V41.4087H145.619Z" fill="black" />
            <path d="M39.2374 47.0341C41.9623 43.3523 43.4882 38.0341 43.4882 32C43.4882 25.9659 42.0713 20.5455 39.2374 16.8636C37.2756 14.3068 34.8777 12.6705 31.8259 11.9545C33.5698 11.4432 35.4227 11.1364 37.6026 11.1364C50.4637 11.1364 55.3684 21.875 55.3684 32C55.3684 42.125 50.4637 52.8636 37.6026 52.8636C35.4227 52.8636 33.5698 52.5568 31.8259 52.0455C34.8777 51.2273 37.3846 49.5909 39.2374 47.0341ZM37.6026 60.8409C29.3191 60.8409 22.4525 57.6704 17.7658 51.8409C13.7331 46.7273 11.5532 39.875 11.5532 32C11.5532 24.125 13.7331 17.2727 17.7658 12.1591C22.4525 6.22727 29.3191 3.15909 37.6026 3.15909C38.4745 3.15909 39.4554 3.15909 40.3274 3.26136C36.6216 1.42045 32.2619 0.5 27.6842 0.5C18.7468 0.5 11.4443 3.97727 6.43058 10.5227C2.17986 16.0455 0 23.5114 0 32C0 40.4886 2.28885 47.8523 6.43058 53.4773C11.4443 60.0227 18.7468 63.5 27.6842 63.5C32.2619 63.5 36.6216 62.5795 40.3274 60.7386C39.3464 60.8409 38.4745 60.8409 37.6026 60.8409Z" fill="black" />
        </svg>

    );
};

export default GFanzLogo;
