import React from "react";
import classes from "./ClemFooter.module.scss";
import Footer from "../../../component/assets/wind_solar.jpg";
import { useNavigate } from "react-router-dom";

function ClemFooter() {

  const navigate = useNavigate();
  const navigateContact = () => {
    navigate("/contact");
  };

  return (
    <div className={classes["footer-container"]}>
      <div className={classes["footer-image-container"]}>
        <div className={classes["img-overlay"]}></div>
        <img className={classes.footer} src={Footer} alt="aboutus" />
      </div>
      <div
        data-aos="fade-right"
        data-aos-once="true"
        className={classes["footer-heading"]}
      >
        <div className={classes.heading}>
          Start your transition from coal to renewables with us
        </div>
        <div onClick={navigateContact} className={classes.button}> Get in touch</div>
      </div>
    </div>
  );
}

export default ClemFooter;
