
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyASj-2luyVLC6b9VgemHEl7iNV_JRmhrZg",
  authDomain: "sustainabilityeconomicswebsite.firebaseapp.com",
  databaseURL: "https://sustainabilityeconomicswebsite-default-rtdb.firebaseio.com",
  projectId: "sustainabilityeconomicswebsite",
  storageBucket: "sustainabilityeconomicswebsite.appspot.com",
  messagingSenderId: "911589693803",
  appId: "1:911589693803:web:67a3e0598a804976e1142e",
  measurementId: "G-H1BV1JXP5P"
};
