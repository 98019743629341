import React, { useState } from "react";
import classes from "./WhatWeDo.module.css";

import CalcSheet from "../../../assets/calcSheet.svg";
import MoneyPlant from "../../../assets/money_plant.svg";
import CloudArrow from "../../../assets/cloudUpArrow.svg";
import GraphMoney from "../../../assets/moneyCoin.svg";
import Analysis from "../../../assets/analysis.svg";

import TargetBoard from "../../../assets/TargetBoard.svg";
import { ReactComponent as InfinitySeLogo } from "../../../assets/solutions/energy/infinity.svg";
import { ReactComponent as GrayedOutInfinity } from "../../../assets/blackAndWhiteInfinity.svg";
import CustomerBehaviour from "../../../assets/customer-behavior.svg";

import PopUp from "../../../Core/PopUp";

const WhatWeDo = () => {
  const [logoHovered, setLogoHovered] = useState(false);
  const [activeLogo, setActiveLogo] = useState("");

  const hoveredHandler = (receivedLogo) => () => {
    setLogoHovered((prevState) => !prevState);
    setActiveLogo(receivedLogo);
  };

  return (
    <div className={classes["what-we-do-section"]}>
      <div
        data-aos="fade-up"
        data-aos-once="true"
        className={classes["what-we-do-section-header"]}
      >
        <h1>What We Do </h1>
        <p>
          E2E Lifecycle Management of Net-Zero Transition using an
          Automation-First Approach.
        </p>
      </div>
      <div
        data-aos="zoom-in"
        data-aos-once="true"
        className={classes["infinity-logo-section"]}
      >
        <div className={classes["infinity-logo-container"]}>
          <div className={classes["infinity-logo-container-infinity"]}>
            {logoHovered ? <GrayedOutInfinity /> : <InfinitySeLogo />}
          </div>
          {logoHovered && <div className={classes["white-screen"]}></div>}
          {/* Icon 1 */}
          <div
            className={classes["infinity-logo-container-calc-sheet"]}
            onMouseEnter={hoveredHandler("calcsheet")}
            onMouseLeave={hoveredHandler(" ")}
          >
            <img src={CalcSheet} /> <div className={classes["cs-strip"]}></div>
            <div className={classes["icon-description"]}>
              <span className={classes["icon-description-number"]}>
                <b>01</b>
              </span>
              <span
                className={`${logoHovered && activeLogo === "calcsheet"
                  ? `${classes["icon-description-text"]} ${classes["bold-text"]}`
                  : classes["icon-description-text"]
                  }`}
              >
                {" "}
                CARBON ACCOUNTING
              </span>
              {logoHovered && activeLogo !== "calcsheet" && (
                <div className={classes["white-screen"]}></div>
              )}
            </div>
            {logoHovered && activeLogo !== "calcsheet" && (
              <div className={classes["white-screen"]}></div>
            )}
            {logoHovered && activeLogo === "calcsheet" && (
              <PopUp
                style={{
                  top: "0rem",
                  left: "4.5rem",
                  width: "18rem",
                  padding: "1rem",
                  zIndex: "23",
                }}
                popupContent={
                  <>
                    Simplify and automate Scope 1, Scope 2 and Scope 3 including
                    financed emissions accounting, for your portfolio and
                    operations.
                  </>
                }
              />
            )}
          </div>

          {/* Icon 2 */}
          <div
            className={classes["infinity-logo-container-target-setting"]}
            onMouseEnter={hoveredHandler("targetsetting")}
            onMouseLeave={hoveredHandler(" ")}
          >
            <img src={TargetBoard} />{" "}
            <div className={classes["ts-strip"]}></div>
            <div className={classes["icon-description-ts"]}>
              <span className={classes["icon-description-number"]}>
                <b>02</b>
              </span>
              <span
                className={`${logoHovered && activeLogo === "targetsetting"
                  ? `${classes["icon-description-text"]} ${classes["bold-text"]}`
                  : classes["icon-description-text"]
                  }`}
              >
                {" "}
                TARGET SETTING & BENCHMARKING
              </span>
              {logoHovered && activeLogo !== "targetsetting" && (
                <div className={classes["white-screen"]}></div>
              )}
            </div>
            {logoHovered && activeLogo !== "targetsetting" && (
              <div className={classes["white-screen"]}></div>
            )}
            {logoHovered && activeLogo === "targetsetting" && (
              <PopUp
                style={{
                  bottom: "4rem",
                  left: "4.5rem",
                  width: "17rem",
                  padding: "1rem",
                }}
                popupContent={
                  <>
                    Set Science Based Targets and track performance in
                    real-time, benchmarking across industry leaders
                  </>
                }
              />
            )}
          </div>

          {/* Icon 3 */}
          <div
            className={classes["infinity-logo-container-pie-chart"]}
            onMouseEnter={hoveredHandler("piechart")}
            onMouseLeave={hoveredHandler(" ")}
          >
            <img src={Analysis} /> <div className={classes["pc-strip"]}></div>
            <div className={classes["icon-description"]}>
              <span className={classes["icon-description-number"]}>
                <b>03</b>
              </span>
              <span
                className={`${logoHovered && activeLogo === "piechart"
                  ? `${classes["icon-description-text"]} ${classes["bold-text"]}`
                  : classes["icon-description-text"]
                  }`}
              >
                {" "}
                TRANSITION RISK
              </span>
              {logoHovered && activeLogo !== "piechart" && (
                <div className={classes["white-screen"]}></div>
              )}
            </div>
            {logoHovered && activeLogo !== "piechart" && (
              <div className={classes["white-screen"]}></div>
            )}
            {logoHovered && activeLogo === "piechart" && (
              <PopUp
                style={{
                  bottom: "1rem",
                  left: "4.5rem",
                  width: "18rem",
                  padding: "1rem",
                }}
                popupContent={
                  <>
                    Generate an automated report, disclosing climate related
                    risks in compliance with regulations and standards across
                    several jurisdictions.
                  </>
                }
              />
            )}
          </div>

          {/* Icon 4 */}

          <div
            className={classes["infinity-logo-container-de-carbonize"]}
            onMouseEnter={hoveredHandler("de-carbonize")}
            onMouseLeave={hoveredHandler(" ")}
          >
            <img src={CustomerBehaviour} />
            <div className={classes["de-carbonize-strip"]}></div>
            <div className={classes["icon-description"]}>
              <span className={classes["icon-description-number"]}>
                <b>04</b>
              </span>
              <span
                className={`${logoHovered && activeLogo === "de-carbonize"
                  ? `${classes["icon-description-text"]} ${classes["bold-text"]}`
                  : classes["icon-description-text"]
                  }`}
              >
                {" "}
                DECARBONIZE PATHWAYS
              </span>
              {logoHovered && activeLogo !== "de-carbonize" && (
                <div className={classes["white-screen"]}></div>
              )}
            </div>
            {logoHovered && activeLogo !== "de-carbonize" && (
              <div className={classes["white-screen"]}></div>
            )}
            {logoHovered && activeLogo === "de-carbonize" && (
              <PopUp
                style={{
                  top: "-2rem",
                  right: "5.5rem",
                  width: "20rem",
                  padding: "1rem",
                  zIndex: "43",
                }}
                popupContent={
                  <>
                    Curtail potential physical and transition risks and optimize
                    your business plan with decarbonization pathways.
                  </>
                }
              />
            )}
          </div>

          {/* Icon 5 */}

          <div
            className={classes["infinity-logo-container-ts-financing"]}
            onMouseEnter={hoveredHandler("ts-financing")}
            onMouseLeave={hoveredHandler(" ")}
          >
            <img src={MoneyPlant} /> <div className={classes["tf-strip"]}></div>
            <div className={classes["icon-description-tf"]}>
              <span className={classes["icon-description-number"]}>
                <b>05</b>
              </span>
              <span
                className={`${logoHovered && activeLogo === "ts-financing"
                  ? `${classes["icon-description-text"]} ${classes["bold-text"]}`
                  : classes["icon-description-text"]
                  }`}
              >
                {" "}
                TRANSITION FINANCING
              </span>
              {logoHovered && activeLogo !== "ts-financing" && (
                <div className={classes["white-screen"]}></div>
              )}
            </div>
            {logoHovered && activeLogo !== "ts-financing" && (
              <div className={classes["white-screen"]}></div>
            )}
            {logoHovered && activeLogo === "ts-financing" && (
              <PopUp
                style={{
                  top: "4.5rem",
                  right: "-7.5rem",
                  width: "20rem",
                  padding: "1rem",
                  zIndex: "43",
                }}
                popupContent={
                  <>
                    Secure transition finance as a borrower or an issuer by
                    automating the End-to-End issuance process. As an investor,
                    identify priority investees in your portfolio unlock
                    Transition Financing opportunities ahead of the market.
                  </>
                }
              />
            )}
          </div>

          {/* Icon 6 */}
          <div
            className={classes["infinity-logo-container-cloud-arrow"]}
            onMouseEnter={hoveredHandler("cloudarrow")}
            onMouseLeave={hoveredHandler(" ")}
          >
            <img src={CloudArrow} /> <div className={classes["ca-strip"]}></div>
            <div className={classes["icon-description-cm"]}>
              <span className={classes["icon-description-number"]}>
                <b>06</b>
              </span>
              <span
                className={`${logoHovered && activeLogo === "cloudarrow"
                  ? `${classes["icon-description-text"]} ${classes["bold-text"]}`
                  : classes["icon-description-text"]
                  }`}
              >
                {" "}
                CARBON MARKETPLACE
              </span>
              {logoHovered && activeLogo !== "cloudarrow" && (
                <div className={classes["white-screen"]}></div>
              )}
            </div>
            {logoHovered && activeLogo !== "cloudarrow" && (
              <div className={classes["white-screen"]}></div>
            )}
            {logoHovered && activeLogo === "cloudarrow" && (
              <PopUp
                style={{
                  top: "-9rem",
                  right: "0.5rem",
                  width: "10rem",
                  padding: "1rem",
                  width: "17rem",
                }}
                popupContent={
                  <>
                    We are methodology developer & project proponent in
                    generating Transition & Carbon Credits and provide
                    Blockchain driven transparent marketplace.
                  </>
                }
              />
            )}
          </div>
          {/* Icon 7 */}
          <div
            className={classes["infinity-logo-container-graph-money"]}
            onMouseEnter={hoveredHandler("graphmoney")}
            onMouseLeave={hoveredHandler(" ")}
          >
            <img src={GraphMoney} /> <div className={classes["gm-strip"]}></div>
            <div className={classes["icon-description-gm"]}>
              <span className={classes["icon-description-number"]}>
                <b>07</b>
              </span>
              <span
                className={`${logoHovered && activeLogo === "graphmoney"
                  ? `${classes["icon-description-text"]} ${classes["bold-text"]}`
                  : classes["icon-description-text"]
                  }`}
              >
                {" "}
                SUSTAINABLE PRODUCT MARKETPLACE
              </span>
              {logoHovered && activeLogo !== "graphmoney" && (
                <div className={classes["white-screen"]}></div>
              )}
            </div>
            {logoHovered && activeLogo !== "graphmoney" && (
              <div className={classes["white-screen"]}></div>
            )}
            {logoHovered && activeLogo === "graphmoney" && (
              <PopUp
                style={{
                  bottom: "-6rem",
                  right: "6.5rem",
                  width: "20rem",
                  padding: "1rem",
                }}
                popupContent={
                  <>
                    We provide a marketplace for sustainable products, covering
                    both the buy & sell side. Gain access to sustainable
                    products like green cement & green steel in our
                    Sustainability Marketplace.
                  </>
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
