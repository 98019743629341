import React from "react";
import dpdc from "./digitalPlatformDecarbCard.module.css";

export default function DigitalPlatformDecarbCard({ pointers }) {
  return (
    <div className={dpdc.dpdCardDiv}>
      <ul>
        {pointers.map((str, index) => (
          <li key={index} className={dpdc.dpdCardPointers}>

            {index === 0 ? <strong>{str}</strong> : str}
          </li>
        ))}
      </ul>
    </div>
  );
}
