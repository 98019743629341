import React from "react";

const TheRockfellarFoundationLogo = ({ color = "#666666", height = 19, width = 19 }) => {
	return (
		<svg
			height={height}
			width={width}
			fill={color}
			version="1.1" id="layer" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 652 652" space="preserve">
			{/* <style type="text/css">
                .st0{fill:#353332;}
                .st1{fill:#008062;}
            </style> */}
			<g>
				<path class="st0" d="M387.4,317.8h-1.7c-1.6,6.1-4.5,7.9-9.3,7.9h-9.9v-18.5h5.3c3.8,0,6.1,1.2,6.7,6.2h1.7v-15h-1.7
		c-0.6,5-2.9,6.2-6.7,6.2h-5.3V287h10.3c4.8,0,6.9,2.6,8.1,7.9h1.7l-0.3-10.3h-31.8v1.7c4.3,0.3,4.8,0.4,4.8,2.7v34.5
		c0,2.3-0.5,2.4-4.8,2.7v1.7h32.2L387.4,317.8z M204,309.3c2.1,1.6,3.9,4.4,5.4,6.9l3.2,5.3c1.2,2.3,3,4.8,4.5,6.6h10.6v-1.7
		c-2.2-0.3-4.2-0.6-6.3-3.7l-5-7.6c-1.5-2.2-3.4-4.5-6.6-6.2c8.6-1.6,11.9-7.1,11.9-12.4c0-6.8-5.3-11.8-14.6-11.8h-18.8v1.7
		c4.3,0.3,4.8,0.4,4.8,2.7v34.5c0,2.3-0.5,2.4-4.8,2.7v1.7H205v-1.7c-4.3-0.3-4.8-0.4-4.8-2.7v-14.3H204z M200.3,287h5.2
		c6.8,0,8.8,5.1,8.8,10.1c0,5.5-2.1,9.6-10.1,9.6h-3.8V287z M292.9,328.7c6.2,0,10.8-2.2,13.2-3.7l0.8-8.9h-1.7
		c-1.2,5-4.1,10-12.3,10c-7.9,0-14.1-7-14.1-19.7c0-12.7,6.2-19.7,14.1-19.7c7.5,0,10.9,4.7,12.1,10h1.7l-0.6-8.9
		c-2.5-1.5-7-3.7-13.2-3.7c-12.2,0-21.7,9.3-21.7,22.3C271.2,319.4,280.7,328.7,292.9,328.7 M188.3,339v1.7c4.3,0.3,4.8,0.4,4.8,2.7
		V378c0,2.3-0.5,2.4-4.8,2.7v1.7H205v-1.7c-4.3-0.3-4.8-0.4-4.8-2.7v-15.1h5.3c3.8,0,6.1,1.2,6.7,6.2h1.7v-15h-1.7
		c-0.6,5-2.9,6.2-6.7,6.2h-5.3v-18.9h10.3c4.8,0,6.9,2.6,8.1,7.9h1.7l-0.3-10.3H188.3z M248.6,338.4c-11.9,0-21.6,9.3-21.6,22.3
		c0,13,9.8,22.4,21.6,22.4s21.6-9.3,21.6-22.4C270.3,347.7,260.5,338.4,248.6,338.4 M248.6,380.5c-8.5,0-14-7.2-14-19.7
		c0-12.5,5.6-19.7,14-19.7c8.5,0,14,7.2,14,19.7C262.7,373.3,257.1,380.5,248.6,380.5 M191.7,256h1.8v18.3c0,1.1-0.3,1.2-2.4,1.4
		v0.9h8.4v-0.9c-2.1-0.2-2.4-0.2-2.4-1.4V256h1.8c2.4,0,3.3,1.3,4,4h0.9l-0.2-5.2h-16.5l-0.2,5.2h0.9
		C188.4,257.3,189.3,256,191.7,256 M326.5,326.3c-4.3-0.3-4.8-0.4-4.8-2.7v-15.9c2.2,0.1,3.7,0.7,5.4,3l6.3,8.6
		c1.2,1.9,4.2,6,6.9,8.8h11.2v-1.7c-3.4-0.4-5.2-2.1-7.3-4.8l-7.5-9.8c-1.7-2.3-4.7-5.8-7.1-7.6l11.6-14.3c1.9-2.4,3.5-2.9,7.5-3.3
		v-1.7h-15.4v1.7c5.2,0.3,5.5,2.2,3.7,4.3l-9.1,11.5c-1.7,2.1-3.5,3-6.3,3.1v-16.3c0-2.3,0.5-2.4,4.8-2.7v-1.7h-16.7v1.7
		c4.3,0.3,4.8,0.4,4.8,2.7v34.5c0,2.3-0.5,2.4-4.8,2.7v1.7h16.7V326.3z M231.8,276.9c3.4,0,5.2-2,5.8-2.5l-0.4-0.8
		c-0.9,0.5-2.1,1.1-3.9,1.1c-3,0-5.5-2.1-5.5-6.4v-0.7h9.8c0.2-0.6,0.2-1.3,0.2-1.9c0-3.7-2.9-4.9-5.6-4.9c-3.9,0-7.8,3-7.8,8.2
		C224.2,273.1,226.8,276.9,231.8,276.9 M231.9,261.9c1.4,0,2.5,0.7,2.5,2.7c0,0.4,0,1-0.2,1.6h-6.4
		C228.3,263.5,229.8,261.9,231.9,261.9 M213.2,275.7c-2.2-0.2-2.4-0.2-2.4-1.4V265c0.9-0.9,2.4-1.9,4.2-1.9c1.5,0,2.2,1,2.2,2.5v8.8
		c0,1.2-0.2,1.2-1.9,1.4v0.8h7.7v-0.8c-2.1-0.2-2.4-0.2-2.4-1.4v-9c0-3.4-1.9-4.7-4.1-4.7c-2.3,0-4.4,1.4-5.8,3.3h-0.2l0.2-10.1
		h-0.4l-5.4,0.7v0.8c2.1,0.2,2.4,0.2,2.4,1.5v17.5c0,1.2-0.3,1.2-2.4,1.4v0.8h8.2V275.7z M226,306.3c0,13,9.8,22.4,21.6,22.4
		c11.9,0,21.6-9.3,21.6-22.4c0-13-9.8-22.3-21.6-22.3C235.8,284,226,293.3,226,306.3 M247.6,286.6c8.5,0,14,7.2,14,19.7
		c0,12.5-5.6,19.7-14,19.7c-8.5,0-14-7.2-14-19.7C233.6,293.8,239.2,286.6,247.6,286.6 M305,340.8c5.3,0.3,5.8,0.4,5.8,2.7v21.6
		c0,8.7-3.8,14.1-11.9,14.1c-7.6,0-11.5-4.9-11.5-14.1v-21.6c0-2.3,0.5-2.4,4.8-2.7V339h-16.7v1.7c4.3,0.3,4.8,0.4,4.8,2.7v22.2
		c0,10.7,5.3,17.4,16.8,17.4c10.1,0,16.7-6.2,16.7-17.4v-22.2c0-2.3,0.5-2.4,5.5-2.7V339H305V340.8z M551.4,338.4
		c-11.9,0-21.6,9.3-21.6,22.3c0,13,9.8,22.4,21.6,22.4c11.9,0,21.6-9.3,21.6-22.4C573,347.7,563.2,338.4,551.4,338.4 M551.4,380.5
		c-8.5,0-14-7.2-14-19.7c0-12.5,5.6-19.7,14-19.7c8.5,0,14.1,7.2,14.1,19.7C565.4,373.3,559.8,380.5,551.4,380.5 M506.2,340.8
		c4.3,0.3,4.8,0.4,4.8,2.7V378c0,2.3-0.5,2.4-4.8,2.7v1.7h16.7v-1.7c-4.3-0.3-4.8-0.4-4.8-2.7v-34.5c0-2.3,0.5-2.4,4.8-2.7V339
		h-16.7V340.8z M562.6,325.7h-9.9v-18.5h5.3c3.8,0,6.1,1.2,6.7,6.2h1.7v-15h-1.7c-0.6,5-2.9,6.2-6.7,6.2h-5.3V287h10.3
		c4.8,0,6.9,2.6,8.1,7.9h1.7l-0.3-10.3h-31.8v1.7c4.3,0.3,4.8,0.4,4.8,2.7v34.5c0,2.3-0.5,2.4-4.8,2.7v1.7h32.2l0.6-10.3h-1.7
		C570.2,323.9,567.3,325.7,562.6,325.7 M606.2,340.8c5.4,0.3,5.9,0.4,5.9,2.7v27.1l-20-31.6h-13.2v1.7c5,0.3,5.5,0.4,5.5,2.7V378
		c0,2.3-0.5,2.4-5.5,2.7v1.7h14.4v-1.7c-5.4-0.3-5.9-0.4-5.9-2.7v-33l23.9,37.8h3.9v-39.3c0-2.3,0.5-2.4,5.5-2.7V339h-14.4V340.8z
		 M536.3,317.8h-1.7c-1.6,6.1-4.5,7.9-9.3,7.9H516v-36.6c0-2.3,0.5-2.4,4.8-2.7v-1.7h-16.7v1.7c4.3,0.3,4.8,0.4,4.8,2.7v34.5
		c0,2.3-0.5,2.4-4.8,2.7v1.7h31.6L536.3,317.8z M614.3,322.7l-5-7.6c-1.5-2.2-3.4-4.5-6.6-6.2c8.6-1.6,11.9-7.1,11.9-12.4
		c0-6.8-5.3-11.8-14.6-11.8h-18.8v1.7c4.3,0.3,4.8,0.4,4.8,2.7v34.5c0,2.3-0.5,2.4-4.8,2.7v1.7h16.7v-1.7c-4.3-0.3-4.8-0.4-4.8-2.7
		v-14.3h3.7c2.1,1.6,3.9,4.4,5.4,6.9l3.2,5.3c1.2,2.3,3,4.8,4.5,6.6h10.6v-1.7C618.4,326,616.4,325.7,614.3,322.7 M596.9,306.7h-3.8
		V287h5.2c6.8,0,8.8,5.1,8.8,10.1C607,302.5,604.9,306.7,596.9,306.7 M487.9,325.7h-9.3v-36.6c0-2.3,0.5-2.4,4.8-2.7v-1.7h-16.7v1.7
		c4.3,0.3,4.8,0.4,4.8,2.7v34.5c0,2.3-0.5,2.4-4.8,2.7v1.7h31.6l0.6-10.3h-1.7C495.6,323.9,492.7,325.7,487.9,325.7 M414.1,287
		c4.8,0,6.9,2.6,8.1,7.9h1.7l-0.3-10.3h-31.8v1.7c4.3,0.3,4.8,0.4,4.8,2.7v34.5c0,2.3-0.5,2.4-4.8,2.7v1.7h16.7v-1.7
		c-4.3-0.3-4.8-0.4-4.8-2.7v-15.1h5.3c3.8,0,6.1,1.2,6.7,6.2h1.7v-15h-1.7c-0.6,5-2.9,6.2-6.7,6.2h-5.3V287H414.1z M395.4,339h-19.6
		v1.7c4.3,0.3,4.8,0.4,4.8,2.7V378c0,2.3-0.5,2.4-4.8,2.7v1.7h19.6c11.9,0,21.6-8.7,21.6-21.7C417,347.7,407.2,339,395.4,339
		 M395.4,380.1h-7.7v-38.7h7.7c8.3,0,14,6.8,14,19.4C409.4,373.3,403.8,380.1,395.4,380.1 M456.4,377.5L442,338.7h-4.2l-12.9,38.5
		c-0.9,2.5-1.7,2.9-5.1,3.5v1.7h13.4v-1.7c-5-0.6-6-1.3-4.8-5l2.4-7.1h14.9l3,8.3c1,2.9,0.1,3.3-4.2,3.8v1.7h16.9v-1.7
		C457.9,380.3,457.2,379.6,456.4,377.5 M431.6,366l6.2-18.9h0.1l6.8,18.9H431.6z M463.8,339l-0.3,10.3h1.7c1.2-5.3,3.1-7.9,7.9-7.9
		h3.5V378c0,2.3-0.5,2.4-4.8,2.7v1.7h16.7v-1.7c-4.3-0.3-4.8-0.4-4.8-2.7v-36.6h3.5c4.8,0,6.7,2.6,7.9,7.9h1.7l-0.3-10.3H463.8z
		 M451.3,325.7h-9.9v-18.5h5.3c3.8,0,6.2,1.2,6.7,6.2h1.7v-15h-1.7c-0.6,5-2.9,6.2-6.7,6.2h-5.3V287h10.2c4.8,0,6.9,2.6,8.2,7.9h1.7
		l-0.3-10.3h-31.8v1.7c4.3,0.3,4.8,0.4,4.8,2.7v34.5c0,2.3-0.5,2.4-4.8,2.7v1.7h32.2l0.6-10.3h-1.7
		C458.9,323.9,456,325.7,451.3,325.7 M353.3,340.8c5.4,0.3,5.9,0.4,5.9,2.7v27.1l-20-31.6h-13.2v1.7c5,0.3,5.5,0.4,5.5,2.7V378
		c0,2.3-0.5,2.4-5.5,2.7v1.7h14.4v-1.7c-5.4-0.3-5.9-0.4-5.9-2.7v-33l23.9,37.8h3.9v-39.3c0-2.3,0.5-2.4,5.5-2.7V339h-14.4V340.8z"
				/>
				<path class="st1" d="M126.2,314.2c2.2-0.4,4.4-0.7,6.5-1.1c0-1.9,0-3.8,0-5.7c-2.2-0.3-4.5-0.6-6.8-0.9
		C126,309.1,126.1,311.6,126.2,314.2"/>
				<path class="st1" d="M113.9,251.6c-4.6-0.4-9.3-0.7-14.2-0.7c0.3,3.4,0.6,6.9,0.9,10.3c4.7-0.1,9.3-0.1,13.7,0
		C114.2,258,114.1,254.8,113.9,251.6"/>
				<path class="st1" d="M100,391.9c5-0.1,9.8-0.5,14.4-1.1c0.2-3.2,0.3-6.5,0.4-9.9c-4.4,0.1-9.1,0.1-14,0
		C100.6,384.5,100.3,388.2,100,391.9"/>
				<path class="st1" d="M114.9,277c-0.1-3.7-0.2-7.5-0.4-11.2c-4.4-0.4-9-0.7-13.7-0.9c0.3,4.2,0.6,8.4,0.8,12.6
		C106.2,277.2,110.6,277,114.9,277"/>
				<path class="st1" d="M115.5,358.1c0.1-3.5,0.1-7.1,0.2-10.7c-4.3-0.2-8.8-0.5-13.2-0.8c-0.1,4.3-0.2,8.6-0.4,12.9
		C106.6,359.1,111.1,358.6,115.5,358.1"/>
				<path class="st1" d="M115.8,337.2c0-3.4,0-6.8,0-10.3c-4.3-0.5-8.6-0.9-13-1.4c0,4.4,0,8.8-0.1,13.3
		C107.1,338.4,111.4,337.8,115.8,337.2"/>
				<path class="st1" d="M125.1,295.1c2.6-0.1,5.1-0.2,7.5-0.2c0-2.8-0.1-5.5-0.2-8.1c-2.7-0.5-5.5-0.9-8.3-1.3
		C124.5,288.6,124.8,291.8,125.1,295.1"/>
				<path class="st1" d="M115.7,315.8c0-3.5-0.1-6.9-0.1-10.5c-4.2-0.4-8.5-0.8-12.9-1.2c0.1,4.4,0.2,8.8,0.2,13.2
		C107.3,316.8,111.5,316.3,115.7,315.8"/>
				<path class="st1" d="M102.4,296.3c4.6-0.3,8.9-0.6,13-0.8c-0.1-3.7-0.2-7.4-0.3-11.2c-4.3-0.5-8.7-0.8-13.2-1.1
		C102.1,287.5,102.3,291.9,102.4,296.3"/>
				<path class="st1" d="M82.5,392.1c0.1,1.9,0.3,3.8,0.4,5.7l-0.4,0.4l-1.2-4.8c-0.1-0.5-0.1-1-0.2-1.5c-4.7-0.6-8.7-1.2-11.7-1.8
		l-2.1-0.8c4.7,0.8,9.2,1.4,13.7,1.9c-0.6-3.8-1.1-7.7-1.6-11.8c-5.3-0.6-10.9-1.3-16.7-2.2c0.5,3,1,6.1,1.6,9.3
		c0.4,2-0.4,0-0.3,0.4c-0.7-3.3-1.4-6.6-2-9.8c-0.1,0-0.3,0-0.4-0.1l-0.8-0.2l-1-0.6c0.7,0.1,1.3,0.1,2,0.2
		c-1-5.2-1.9-10.3-2.6-15.3c-4.5-0.6-9-1.3-13.4-2l-0.9-0.5c4.7,0.6,9.4,1,14.2,1.3c-0.8-5.8-1.4-11.6-1.8-17.5
		c-5.2-0.5-10.4-0.9-15.5-1.4c0.3,3.8,0.8,8,1.3,12.9l-0.6-1.1c-0.7-4.3-1.1-8.3-1.5-12l-1.2-0.3c0.4,0,0.8,0,1.2,0
		c-1.6-15.7-0.8-26.4,0.4-38.3c-1.8,0-3.6,0.1-5.5,0.1c1.9-0.2,3.7-0.4,5.5-0.6c0.4-3.3,0.7-6.6,1.1-10.2l0.6-2
		c-0.4,4.6-0.7,8.5-1,12.1c5.4-0.6,10.6-1.1,15.6-1.6c0.5-5.6,1.1-11.4,1.8-17.4c-3.3,0.1-6.5,0.3-9.9,0.4l-0.2-0.1
		c3.5-0.5,6.9-1,10.3-1.5c0.6-4.9,1.4-10,2.2-15.3c-1.8,0.2-3.7,0.4-5.5,0.6h-1c2.2-0.5,4.4-0.9,6.6-1.3c0.4-2.8,0.9-5.7,1.4-8.6
		c-0.4,2.9-0.8,5.7-1.1,8.5c5.7-1.1,11.4-2,16.9-2.7c0.5-4.1,1.1-7.8,1.7-11.2c-3.7,0.3-7.4,0.6-11.2,1.1l0.7-0.3
		c3.6-0.6,7.1-1.1,10.6-1.5c0.1-0.6,0.2-1.2,0.4-1.8l1.3-5.3c-35.5,7.7-62.2,39.5-62.2,77.3c0,43.3,34.9,78.6,78,79.1
		c-0.2-2.3-0.3-4.5-0.5-6.8C92.4,393.1,87.1,392.7,82.5,392.1"/>
				<path class="st1" d="M129.8,251.7c-0.3-1.6-0.7-3-1-4.2c-4.4-1.8-8.9-3.2-13.7-4.1c0.7,2.1,1.4,4.3,2.1,6.5
		C121.4,250.3,125.6,250.9,129.8,251.7"/>
				<path class="st1" d="M143.1,255.1c-3.7-2.5-7.6-4.6-11.8-6.5c0.8,1.3,1.5,2.6,2.2,3.9C136.8,253.2,140,254.1,143.1,255.1" />
				<path class="st1" d="M152.7,267c2,0.6,3.8,1.3,5.5,2c-0.1-0.4-0.2-0.8-0.4-1.2c-2.4-2.6-4.9-5.1-7.7-7.4c-0.4-0.2-0.9-0.4-1.3-0.5
		C150.2,262,151.5,264.4,152.7,267"/>
				<path class="st1" d="M132.7,347.8c-2.4,0-4.9,0-7.4-0.1c-0.3,3-0.6,6.1-0.9,9.1c2.8-0.5,5.5-0.9,8.2-1.5
		C132.6,352.8,132.6,350.3,132.7,347.8"/>
				<path class="st1" d="M152.7,375.1c-1.2,2.4-2.5,4.7-3.9,6.9c0.5-0.2,1.1-0.4,1.6-0.6c2.7-2.3,5.3-4.7,7.6-7.4
		c0.1-0.3,0.2-0.7,0.3-1C156.5,373.8,154.7,374.5,152.7,375.1"/>
				<path class="st1" d="M132,393.1c3.8-1.7,7.4-3.7,10.8-6c-3,1-5.9,2-8.9,2.7C133.3,390.9,132.7,392,132,393.1" />
				<path class="st1" d="M101.7,365.2c-0.2,4.1-0.4,8.3-0.7,12.5c4.8-0.2,9.5-0.6,14-1.1c0.1-3.6,0.2-7.3,0.3-11
		C110.9,365.6,106.4,365.5,101.7,365.2"/>
				<path class="st1" d="M117.1,392.7c-0.6,2-1.3,4-2,5.9c4.8-1,9.4-2.4,13.9-4.2c0.3-1.1,0.7-2.3,0.9-3.6
		C125.6,391.6,121.3,392.3,117.1,392.7"/>
				<path class="st1" d="M98,249.5c0.2-2.5,0.3-5.1,0.5-7.6c-5.3,0.1-10.5,0.7-15.5,1.8c-0.2,2.3-0.3,4.7-0.5,7
		C87.6,250,92.8,249.6,98,249.5"/>
				<path class="st1" d="M96.1,359.9c-0.2-4.7-0.3-9.3-0.4-13.9c-5.2-0.4-10.5-0.9-15.8-1.4c0.1,5.3,0.3,10.6,0.6,15.9
		C85.7,360.4,90.9,360.2,96.1,359.9"/>
				<path class="st1" d="M146.1,265.1c-0.5-2.6-1.2-5-1.8-7.1c-3-1.1-6.1-2-9.2-2.9c1.3,2.7,2.5,5.5,3.6,8.4
		C141.2,264.1,143.7,264.6,146.1,265.1"/>
				<path class="st1" d="M79,264.9c-5.5,0.2-11.1,0.7-16.8,1.3c-0.6,5.2-1.2,10.3-1.7,15.3c5.8-0.8,11.6-1.5,17.1-2.1
		C78,274.2,78.5,269.4,79,264.9"/>
				<path class="st1" d="M77.4,282.4c-5.5,0-11.1,0.1-17,0.3c-0.6,5.8-1.1,11.6-1.4,17.3c6.2-0.6,12.1-1.2,17.6-1.6
		C76.8,292.8,77.1,287.5,77.4,282.4"/>
				<path class="st1" d="M56.7,322c-5.1-0.3-10.3-0.5-15.8-0.7c-0.1,5.6,0.1,11.5,0.8,19.3c5.2,0.2,10.3,0.3,15.4,0.3
		C56.8,334.7,56.6,328.4,56.7,322"/>
				<path class="st1" d="M131.2,262.5c-0.3-3.1-0.6-5.9-1-8.4c-4-0.9-8.2-1.6-12.4-2.1c0.8,3,1.6,6.2,2.3,9.5
		C124,261.7,127.7,262,131.2,262.5"/>
				<path class="st1" d="M76.8,344.3c-6-0.6-12-1.2-18-1.7c0.3,5.7,0.8,11.5,1.5,17.4c5.8,0.4,11.6,0.5,17.4,0.5
		C77.3,355.1,77,349.7,76.8,344.3"/>
				<path class="st1" d="M97.9,250.9c-5,0-10.2,0.2-15.6,0.6c-0.3,3.7-0.6,7.4-0.8,11c5.4-0.6,10.7-1,15.8-1.2
		C97.5,257.8,97.7,254.3,97.9,250.9"/>
				<path class="st1" d="M113.8,249.7c-0.1-2.2-0.2-4.4-0.3-6.6c-4.5-0.8-9.1-1.3-13.9-1.3h-0.3c-0.1,0-0.3,0-0.4,0
		c0.2,2.5,0.4,5,0.7,7.5C104.4,249.3,109.2,249.4,113.8,249.7"/>
				<path class="st1" d="M56.7,320.4c0.1-5.9,0.4-11.9,0.9-18.2c-5.1,0-10.2,0-15.5,0.1c-0.6,7.1-1.1,12.7-1.2,18.7
		C46.4,320.8,51.6,320.6,56.7,320.4"/>
				<path class="st1" d="M99.9,393.3c-0.2,2.3-0.3,4.5-0.5,6.8c0,0,0.1,0,0.1,0c4.9,0,9.8-0.5,14.5-1.4c0.1-1.9,0.2-3.9,0.3-5.8
		C109.4,393.3,104.5,393.4,99.9,393.3"/>
				<path class="st1" d="M81.5,379.6c0.3,3.9,0.6,7.8,0.9,11.7C87.8,391.8,93,392,98,392c-0.3-3.7-0.6-7.5-0.9-11.2
		C92.2,380.6,87,380.2,81.5,379.6"/>
				<path class="st1" d="M120.3,380.7c-0.8,3.3-1.6,6.5-2.5,9.6c4.3-0.7,8.5-1.5,12.7-2.5c0.4-2.4,0.7-5.1,1-8.1
		C128,380.1,124.2,380.5,120.3,380.7"/>
				<path class="st1" d="M60.4,361.3c0.6,5,1.3,10,2.1,15.2c5.6,0.6,11.1,1,16.6,1.2c-0.5-4.7-1-9.5-1.3-14.4
		C72.1,362.8,66.2,362.1,60.4,361.3"/>
				<path class="st1" d="M126.3,328.1c0,2.5-0.1,5.1-0.2,7.6c2.2-0.4,4.5-0.8,6.7-1.2c0-1.9,0-3.7,0-5.6
		C130.6,328.6,128.4,328.3,126.3,328.1"/>
				<path class="st1" d="M158,361.8c-0.7,2.1-1.4,4.1-2.1,6.1c1.5-0.5,2.9-1.1,4.3-1.7c0.5-1.8,1-3.7,1.4-5.4
		C160.4,361.1,159.2,361.5,158,361.8"/>
				<path class="st1" d="M135.6,386.4c3.2-0.9,6.3-1.9,9.3-3c0.6-2.1,1.2-4.3,1.7-6.6c-2.4,0.6-5,1.2-7.8,1.7
		C137.9,381.3,136.8,383.9,135.6,386.4"/>
				<path class="st1" d="M81.4,264.8c-0.3,4.8-0.6,9.5-0.8,14.3c5.4-0.5,10.8-1,15.9-1.3c0.2-4.4,0.4-8.7,0.6-13
		C92,264.6,86.7,264.6,81.4,264.8"/>
				<path class="st1" d="M58.2,320.3c6.3-0.3,12.3-0.6,18-0.9c0-5.7,0.1-11.4,0.2-16.9c-5.7-0.2-11.6-0.3-17.6-0.4
		C58.5,308.2,58.3,314.3,58.2,320.3"/>
				<path class="st1" d="M95.8,296.8c0.1-4.7,0.3-9.3,0.5-13.9c-5.1-0.3-10.4-0.4-15.9-0.4c-0.2,5.2-0.4,10.4-0.6,15.6
		C85.5,297.6,90.8,297.1,95.8,296.8"/>
				<path class="st1" d="M95.4,318c0-4.8,0.1-9.6,0.3-14.4c-5.2-0.4-10.5-0.6-15.9-0.9c-0.1,5.5-0.2,11-0.2,16.5
		C85.1,318.9,90.3,318.5,95.4,318"/>
				<path class="st1" d="M76.6,340.6c-0.2-5.8-0.3-11.6-0.3-17.4c-5.8-0.4-11.8-0.8-18-1.2c0,6.2,0.1,12.5,0.5,18.8
		C64.7,340.9,70.7,340.8,76.6,340.6"/>
				<path class="st1" d="M123,277c3.2,0.1,6.2,0.2,9.2,0.3c-0.1-3.2-0.3-6.3-0.5-9.3c-3.4-0.6-6.9-1.1-10.5-1.6
		C121.8,269.9,122.4,273.4,123,277"/>
				<path class="st1" d="M95.4,324.8c-5.1-0.5-10.4-0.9-15.8-1.4c0,5.6,0.1,11.3,0.2,17c5.3-0.2,10.5-0.5,15.7-1
		C95.4,334.6,95.4,329.7,95.4,324.8"/>
				<path class="st1" d="M146.2,294.9c0.8,0,1.6,0.1,2.4,0.1c0-1.7,0-3.4,0-5c-1.1-0.3-2.1-0.5-3.2-0.7
		C145.7,291.1,146,293,146.2,294.9"/>
				<path class="st1" d="M161.9,281.7c-0.5-2.1-1.1-4.2-1.7-6.3c-1.5-0.5-3.1-1.1-4.7-1.6c0.8,2.2,1.5,4.5,2.2,6.9
		C159.1,281,160.5,281.3,161.9,281.7"/>
				<path class="st1" d="M140.8,269.8c0.9,2.7,1.6,5.5,2.3,8.4c1.7,0.2,3.3,0.4,4.9,0.7c-0.2-2.6-0.5-5.1-0.9-7.5
		C145.1,270.8,143,270.3,140.8,269.8"/>
				<path class="st1" d="M148.4,363.7c-1.7,0.3-3.5,0.5-5.3,0.7c-0.7,2.7-1.4,5.3-2.1,7.9c2.4-0.6,4.6-1.2,6.8-1.8
		C148.1,368.4,148.3,366.1,148.4,363.7"/>
				<path class="st1" d="M97,377.8c-0.3-4.3-0.5-8.6-0.7-12.9c-5.2-0.3-10.4-0.7-15.7-1.3c0.2,4.7,0.5,9.4,0.8,14.2
		C86.7,378,91.9,378,97,377.8"/>
				<path class="st1" d="M132.3,365.3c-3,0.2-6,0.3-9.1,0.3c-0.5,3.5-1.1,6.9-1.8,10.3c3.6-0.5,7.2-1,10.5-1.7
		C132.1,371.4,132.2,368.4,132.3,365.3"/>
				<path class="st1" d="M146.3,347.3c-0.2,1.7-0.5,3.4-0.8,5.1c1-0.2,1.9-0.5,2.9-0.7c-0.1-1.4-0.2-2.9-0.3-4.4
		C147.5,347.2,146.9,347.2,146.3,347.3"/>
			</g>
		</svg>


	);
};

export default TheRockfellarFoundationLogo;
