import React, { useState } from "react";
import formm from "./form.module.css";
import { insertUsers } from "../../API/user";
import { Alert, AlertTitle } from "@mui/material";
import { firebaseConfig } from "../util";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

function Form() {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const UserdetailsUrl = firebaseConfig.databaseURL + "/userdetails.json";

  const [details, setDetails] = useState({
    email: "",
    name: "",
    organisation: "",
    message: "",
  });

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setDetails({ ...details, [name]: value });
  };
  const [successMessage, setShowSuccessMessage] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, name, organisation, message } = details;
    if (email && name && organisation) {
      const res = await fetch(UserdetailsUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          name,
          organisation,
          message,
        }),
      });
      if (res) {
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000);
      }
    }
    setDetails({
      email: "",
      name: "",
      organisation: "",
      message: "",
    });
    //
  };

  return (
    <>
      <div className={formm.parentDiv}>
        <div className={formm.upperDiv}>
          <div className={formm.get}>Get in Touch</div>
          <div className={formm.text}>
            Please fill in the form and our team will get back to you.
          </div>
        </div>
        <div className={formm.parentFormDiv}>
          <div className={formm.formDiv}>
            <form
              className={formm.mainForm}
              onSubmit={(e) => handleSubmit(e)}
              method="POST"
            >
              <input
                className={formm.input}
                type="text"
                placeholder="Your e-mail adddress"
                name="email"
                value={details.email}
                required
                onChange={handleInput}
              />
              <input
                className={formm.input}
                type="text"
                placeholder="Your name"
                name="name"
                value={details.name}
                required
                onChange={handleInput}
              />
              <input
                className={formm.input}
                type="text"
                placeholder="Your organisation"
                name="organisation"
                value={details.organisation}
                required
                onChange={handleInput}
              />
              <textarea
                className={formm.input1}
                type="text"
                placeholder="Your message(optional)"
                name="message"
                value={details.message}
                onChange={handleInput}
              />
              <div className={formm.btnDiv}>
                <button className={formm.btn} type="submit">
                  Submit
                </button>
              </div>
              {successMessage && (
                <Alert severity="success">
                  <AlertTitle>Success</AlertTitle>
                  User Details Submitted Successfully!
                </Alert>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Form;
