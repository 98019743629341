import React from "react";

const SMBCLogo = ({ color = "#666666", height = 19, width = 19 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 200 56" fill={color} xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5023_15315)">
                <path d="M26.2186 32.0153V5.69286C26.2186 5.43267 26.3401 5.25133 26.5872 5.15277L37.57 0.631012C37.6956 0.579763 37.7604 0.619185 37.7604 0.753222V27.8759C37.7604 28.0454 37.6794 28.1637 37.5215 28.2307L26.4211 32.7879C26.3158 32.8313 26.1903 32.7879 26.1457 32.6815C26.1457 32.6815 26.1457 32.6775 26.1457 32.6736C26.1012 32.5475 26.1012 32.4292 26.1538 32.3227C26.1984 32.2242 26.2227 32.1217 26.2227 32.0153H26.2186Z" fill="#C4D700" />
                <path d="M24.8808 6.07836L24.9051 33.2996C24.9051 33.3232 24.897 33.3508 24.8808 33.3705L13.493 49.8294C13.4525 49.8886 13.3674 49.9043 13.3067 49.861C13.2702 49.8373 13.25 49.7979 13.25 49.7585L13.2621 22.5175C13.2621 22.4939 13.2702 22.4663 13.2864 22.4466L24.6379 6.0074C24.6784 5.94827 24.7634 5.9325 24.8241 5.97586C24.8606 5.99952 24.8808 6.03894 24.8808 6.07836Z" fill="#C4D700" />
                <path d="M61.2281 14.8372C65.1321 10.1696 71.2877 11.1078 76.7062 12.3299C76.852 12.3615 76.9249 12.4482 76.9208 12.594L76.771 18.5705C76.7669 18.8583 76.6252 19.1264 76.3863 19.2959C75.9205 19.631 75.5763 19.5797 75.3495 19.15C75.2766 19.0081 75.2159 18.7637 75.1713 18.4167C74.8352 15.7952 73.2032 14.3444 70.2833 14.0566C62.6941 13.3076 61.0742 19.765 66.2417 24.3971C68.5014 26.4235 72.3244 29.0056 74.819 31.8125C78.1398 35.5616 78.7594 40.722 76.0501 44.9047C72.5552 50.3017 64.31 50.8733 58.8469 48.437C57.2513 47.7274 58.1463 41.8889 58.288 40.3751C58.3042 40.178 58.4135 40.0794 58.616 40.0794L59.507 40.0676C59.6973 40.0676 59.7945 40.1543 59.7986 40.3396C59.8593 43.998 61.6817 46.7615 65.5613 47.1439C73.6041 47.9403 75.714 39.902 70.3441 35.1398C68.8335 33.8033 66.6993 31.986 63.9414 29.6916C59.4705 25.974 57.1784 19.6743 61.2281 14.8372Z" fill="#004831" />
                <path d="M171.879 17.1013C179.112 9.40993 188.718 11.1761 197.992 12.4967C198.154 12.5204 198.235 12.611 198.235 12.7727L198.093 21.6782C198.093 21.8438 197.984 21.9936 197.826 22.0567C197.352 22.2341 197.012 22.2222 196.797 22.0212C196.672 21.899 196.603 21.6427 196.591 21.2524C196.453 16.3325 192.189 14.2865 187.762 14.0026C178.424 13.3995 173.191 17.4837 172.065 26.2552C171.737 28.8058 171.928 31.3643 172.628 33.9307C174.775 41.76 180.606 46.6405 189.309 46.6563C191.379 46.6563 193.262 46.1004 194.955 44.9848C196.826 43.7548 197.226 41.6023 197.741 39.6273C197.818 39.3316 198.121 39.1503 198.429 39.2173C198.895 39.3158 199.114 39.6076 199.093 40.0846C198.988 42.308 198.717 44.5078 198.279 46.6878C198.073 47.7049 197.563 48.0873 196.603 48.3633C185.219 51.6432 172.005 49.0611 168.032 36.7415C165.987 30.4024 167.262 22.0054 171.879 17.1013Z" fill="#004831" />
                <path d="M117.921 23.305C117.88 22.8004 117.755 22.7806 117.544 23.2458C113.847 31.4497 110.121 39.7126 106.371 48.0426C106.164 48.5117 105.998 48.768 105.873 48.8192C105.164 49.1109 104.654 48.9138 104.334 48.2318L92.8244 23.3799C92.8041 23.3286 92.7393 23.301 92.6867 23.3247C92.6502 23.3404 92.6219 23.3759 92.6219 23.4154C92.1319 30.0344 91.6054 36.6219 91.0506 43.17C90.9696 44.1122 90.9736 45.3461 91.0587 46.8757C91.0668 46.9979 91.1559 47.1043 91.2774 47.1359L93.6141 47.7272C93.8328 47.7824 94.0109 47.9322 94.096 48.1333C94.2377 48.4565 94.2094 48.697 94.0028 48.8547C93.8935 48.9414 93.7153 48.9848 93.4642 48.9808C90.3581 48.9532 87.2965 48.9651 84.2794 49.0084C83.9757 49.0124 83.7287 48.7798 83.7246 48.4841C83.7246 48.3856 83.7489 48.287 83.8015 48.2042C84.6074 46.8954 86.851 48.2279 87.1061 45.8113C88.2725 34.7375 89.4428 23.6677 90.6213 12.5939C90.7307 11.5452 91.9375 10.8829 92.4801 12.0577C97.0158 21.8306 101.499 31.5048 105.925 41.0885C106.075 41.4078 106.221 41.4078 106.367 41.0885C110.635 31.4851 114.843 22.0632 118.986 12.8186C119.208 12.3179 119.472 11.8961 119.775 11.5492C119.852 11.4585 119.974 11.4151 120.095 11.4309C120.723 11.5137 121.063 11.7739 121.112 12.2036C122.165 21.9922 123.189 31.7887 124.181 41.5852C124.384 43.5602 124.514 46.9033 127.069 47.3487C127.948 47.5025 128.835 47.6168 129.73 47.6957C129.928 47.7114 130.021 47.8139 130.009 48.0071L129.969 48.6497C129.96 48.7956 129.843 48.9099 129.693 48.9217C126.445 49.1622 123.205 49.1425 119.97 48.8626C119.65 48.8389 119.411 48.563 119.435 48.2515C119.443 48.1806 119.464 48.1096 119.496 48.0426C119.86 47.329 119.994 46.4854 119.901 45.5117C119.156 37.9701 118.496 30.5705 117.921 23.3089V23.305Z" fill="#004831" />
                <path d="M152.265 28.1919C162.296 32.0632 162.741 45.0844 152.062 48.4708C147.47 49.9255 141.626 48.593 137.69 48.9636C136.556 49.07 135.616 49.0621 134.871 48.936C134.733 48.9163 134.62 48.8177 134.579 48.6876C134.438 48.2067 134.624 47.8637 135.138 47.6587C136.135 47.2605 137.277 47.6508 137.58 46.1922C137.86 44.8479 138.014 42.9398 137.997 41.3274C137.92 33.5218 137.912 25.7161 137.973 17.9105C137.985 16.6411 137.908 15.6949 137.75 15.076C137.204 12.963 134 14.26 133.79 12.8408C133.741 12.5175 133.968 12.2179 134.3 12.1706C134.328 12.1666 134.357 12.1627 134.385 12.1627C136.43 12.139 138.5 12.1469 140.585 12.1824C145.36 12.2652 151.479 11.185 154.694 15.62C157.562 19.5741 155.784 24.9159 152.22 27.9317C152.086 28.0421 152.103 28.1288 152.265 28.1919ZM148.284 27.5375C151.669 26.5913 151.888 21.1628 151.013 18.6556C149.827 15.2455 147.267 13.8263 143.343 14.3979C143.06 14.4374 142.853 14.6739 142.853 14.9499V27.2654C142.853 27.3876 142.914 27.4626 143.039 27.4862C145.246 27.8804 146.996 27.8962 148.284 27.5375ZM153.723 44.2565C156.918 39.9989 153.471 32.7846 149.195 30.6597C147.207 29.6702 145.08 30.025 143.043 29.8634C142.95 29.8555 142.869 29.9186 142.861 30.0092C142.861 30.0132 142.861 30.0211 142.861 30.025C142.845 34.6296 142.841 39.1986 142.853 43.7322C142.853 45.0292 143.436 45.8689 144.594 46.2592C147.749 47.3118 151.56 47.1344 153.723 44.2565Z" fill="#004831" />
                <path d="M0.265792 27.9472L11.5443 23.2559C11.6901 23.1967 11.8561 23.2638 11.9169 23.4057C11.9331 23.4372 11.9371 23.4727 11.9371 23.5082L11.9452 50.4732C11.9452 50.5835 11.8764 50.6821 11.7711 50.7255L0.488528 55.3734C0.342737 55.4325 0.176698 55.3655 0.115952 55.2236C0.0997529 55.1881 0.0957031 55.1526 0.0957031 55.1171V28.1995C0.0957031 28.0891 0.164549 27.9905 0.265792 27.9472Z" fill="#C4D700" />
            </g>
            <defs>
                <clipPath id="clip0_5023_15315">
                    <rect width="199" height="54.7854" fill="white" transform="translate(0.0957031 0.607422)" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default SMBCLogo;
