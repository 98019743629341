import React from 'react';
import Header from "./header/header";
import Footer from "../home/Footer/footer";

function Layout(props) {
  const {showHeader = true, showFooter = true, children} = props; 

  return (
    <>
        {showHeader && <Header />}
        {children}
        {showFooter && <Footer />}
    </>
  )
}

export default Layout;