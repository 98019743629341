import { React } from "react";
import "./video.css";
import { useNavigate } from "react-router-dom";
function Video() {
  const navigate = useNavigate();
  const navigateContact = () => {
    navigate("/contact");
  };
  return (
    <div className="video1">
      <div className="overlay"></div>
      <div className="vs">
        <video
          className="video"
          controls={false}
          autostart="true"
          autoPlay
          loop
          muted
          src={
            "https://sen-website-video.s3.ap-south-1.amazonaws.com/enenrgy-page-video.mp4"
          }
          type="video/mp4"
        />
      </div>
      <div data-aos="fade-right" data-aos-once="true" className={`headingPart`}>
        <div className={`heading`}>
          Unlocking Asia's Green Potential: A Clean Energy Revolution
        </div>
        {/* <div className={`contact`}>
          <button onClick={navigateContact} className={`btn`}>
            Contact Us
          </button>
        </div> */}
      </div>
    </div>
  );
}

export default Video;
