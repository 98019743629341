import React from "react";

const ClimateXLogo = ({ color = "#666666", height = 19, width = 19 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 253 51" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M227.33 17.9609L232.259 23.7457L237.213 17.9609H241.443L234.512 25.8804L241.219 33.5758H236.989L232.259 28.0151L227.554 33.5758H223.298L230.005 25.8804L223.074 17.9609H227.33Z" fill="#7FB955" />
            <path d="M47.6758 25.1829C47.6758 24.1551 47.8603 23.1536 48.2292 22.1785C48.5982 21.2034 49.1384 20.3206 49.85 19.5168C50.5616 18.7261 51.4181 18.0805 52.4327 17.6061C53.4473 17.1317 54.5938 16.8945 55.8719 16.8945C57.4005 16.8945 58.7314 17.2371 59.8382 17.896C60.9583 18.568 61.7753 19.4377 62.3155 20.5182L59.8514 22.2312C59.5747 21.6119 59.2057 21.1112 58.7445 20.7554C58.2965 20.3996 57.809 20.1361 57.2819 19.978C56.7548 19.8198 56.2541 19.7408 55.7665 19.7408C54.9495 19.7408 54.2511 19.8989 53.645 20.2283C53.0389 20.5578 52.5381 20.9794 52.1428 21.5197C51.7475 22.0468 51.4444 22.6397 51.2468 23.2986C51.0491 23.9574 50.9437 24.6163 50.9437 25.2883C50.9437 26.0131 51.0623 26.7115 51.2995 27.3835C51.5367 28.0687 51.8661 28.6617 52.3009 29.1888C52.7358 29.7158 53.2497 30.1243 53.8427 30.4274C54.4488 30.7305 55.1077 30.8886 55.8192 30.8886C56.3199 30.8886 56.8339 30.7964 57.3609 30.6251C57.888 30.4537 58.3888 30.177 58.8368 29.8081C59.2848 29.4391 59.6537 28.9384 59.9436 28.3322L62.5659 29.874C62.2233 30.7041 61.683 31.4025 60.9319 31.9823C60.194 32.5621 59.3639 32.9969 58.4546 33.3C57.5454 33.6031 56.6362 33.748 55.727 33.748C54.541 33.748 53.4605 33.4977 52.4854 33.0101C51.5103 32.5226 50.667 31.8637 49.9422 31.0467C49.2175 30.2297 48.6641 29.3073 48.2687 28.2795C47.8734 27.2649 47.6758 26.2239 47.6758 25.1829Z" fill="black" />
            <path d="M64.4238 16.5352H67.56V29.7122C67.56 30.292 67.6522 30.6742 67.8367 30.8718C68.0212 31.0695 68.2715 31.1617 68.5878 31.1617C68.8645 31.1617 69.1412 31.1354 69.3916 31.0695C69.6551 31.0036 69.8791 30.9245 70.0768 30.8059L70.4985 33.2173C70.0768 33.415 69.5892 33.5731 69.0358 33.6917C68.4824 33.7971 67.9685 33.8498 67.5073 33.8498C66.519 33.8498 65.7679 33.5863 65.2276 33.0592C64.6874 32.5321 64.4238 31.7942 64.4238 30.8455V16.5352Z" fill="black" />
            <path d="M71.8691 19.6201V16.5234H74.9789V19.6201H71.8691ZM71.8691 33.6273V21.3594H74.9789V33.6273H71.8691Z" fill="black" />
            <path d="M96.9056 33.6267H93.7695V26.8405C93.7695 25.8654 93.5982 25.167 93.2688 24.719C92.9261 24.271 92.465 24.047 91.872 24.047C91.2527 24.047 90.6597 24.2842 90.1194 24.7454C89.5792 25.2197 89.1839 25.8127 88.9467 26.5506V33.6135H85.7842V26.8273C85.7842 25.8522 85.626 25.1539 85.2966 24.7058C84.9672 24.2578 84.506 24.0338 83.913 24.0338C83.3069 24.0338 82.7139 24.2578 82.1605 24.719C81.5939 25.1802 81.1985 25.7732 80.9614 26.5243V33.6135H77.8516V21.4774H80.6846V23.7439C81.159 22.9533 81.7783 22.3471 82.569 21.9255C83.3596 21.5038 84.2556 21.293 85.2571 21.293C86.2717 21.293 87.0623 21.5433 87.629 22.0309C88.1956 22.5184 88.5645 23.1378 88.7227 23.8625C89.2234 23.0323 89.8559 22.3867 90.6333 21.9518C91.4108 21.517 92.2805 21.293 93.2688 21.293C94.0198 21.293 94.626 21.4247 95.1135 21.7015C95.5879 21.965 95.9569 22.334 96.2204 22.7952C96.484 23.2564 96.6684 23.7703 96.7739 24.3369C96.8793 24.9035 96.932 25.4833 96.932 26.0499V33.6267H96.9056Z" fill="black" />
            <path d="M98.8027 30.0416C98.8027 29.2509 99.0267 28.5657 99.4748 27.9859C99.9228 27.393 100.542 26.9449 101.32 26.6023C102.11 26.2729 103.019 26.1016 104.074 26.1016C104.587 26.1016 105.115 26.1411 105.642 26.2202C106.169 26.2993 106.63 26.4179 107.025 26.5892V25.9171C107.025 25.166 106.801 24.5863 106.34 24.1646C105.892 23.7561 105.207 23.5453 104.298 23.5453C103.612 23.5453 102.967 23.6639 102.347 23.901C101.741 24.1382 101.096 24.4677 100.424 24.9025L99.4352 22.8601C100.226 22.3198 101.056 21.9113 101.913 21.6478C102.769 21.3842 103.652 21.2656 104.601 21.2656C106.353 21.2656 107.71 21.6873 108.686 22.5438C109.661 23.4003 110.148 24.639 110.148 26.2466V30.1074C110.148 30.4369 110.201 30.6609 110.32 30.8058C110.438 30.9508 110.623 31.0298 110.899 31.0562V33.6126C110.623 33.6653 110.359 33.7048 110.135 33.7311C109.911 33.7575 109.713 33.7707 109.555 33.7575C108.936 33.7575 108.462 33.6257 108.132 33.349C107.816 33.0723 107.618 32.7429 107.552 32.3344L107.486 31.7019C106.933 32.3871 106.274 32.9142 105.47 33.2963C104.68 33.6653 103.863 33.8629 103.033 33.8629C102.216 33.8629 101.491 33.6916 100.845 33.349C100.2 33.0064 99.6856 32.5452 99.3166 31.9786C98.9872 31.3988 98.8027 30.7531 98.8027 30.0416ZM106.445 30.6345C106.617 30.4764 106.762 30.3051 106.867 30.1074C106.973 29.9098 107.025 29.7385 107.025 29.6067V28.3944C106.656 28.2363 106.248 28.1309 105.813 28.0386C105.378 27.9596 104.956 27.9069 104.574 27.9069C103.757 27.9069 103.098 28.0782 102.571 28.4208C102.044 28.7634 101.781 29.2114 101.781 29.778C101.781 30.0811 101.873 30.371 102.044 30.6609C102.216 30.9376 102.466 31.1616 102.782 31.3329C103.098 31.5042 103.494 31.5833 103.968 31.5833C104.443 31.5833 104.904 31.491 105.352 31.3066C105.787 31.1353 106.156 30.9113 106.445 30.6345Z" fill="black" />
            <path d="M120.333 33.0226C120.057 33.1412 119.727 33.273 119.358 33.4047C118.989 33.5365 118.594 33.6419 118.159 33.7342C117.724 33.8264 117.303 33.8659 116.855 33.8659C116.262 33.8659 115.721 33.7605 115.234 33.5629C114.746 33.3652 114.364 33.0358 114.074 32.6009C113.784 32.1661 113.639 31.5863 113.639 30.8747V23.8645H112.045V21.4795H113.639V17.5H116.802V21.4795H119.385V23.8645H116.802V29.8074C116.815 30.2291 116.934 30.5321 117.158 30.7166C117.382 30.9143 117.658 31.0065 118.001 31.0065C118.317 31.0065 118.62 30.9538 118.937 30.8352C119.253 30.7166 119.503 30.6112 119.714 30.519L120.333 33.0226Z" fill="black" />
            <path d="M127.504 33.8654C126.515 33.8654 125.633 33.6941 124.829 33.3646C124.038 33.0352 123.353 32.574 122.786 31.9942C122.22 31.4144 121.798 30.7556 121.495 30.0177C121.192 29.2797 121.047 28.4759 121.047 27.6326C121.047 26.4862 121.31 25.432 121.837 24.4701C122.365 23.5082 123.103 22.7307 124.064 22.1509C125.026 21.5711 126.173 21.2812 127.517 21.2812C128.848 21.2812 129.981 21.5711 130.943 22.1509C131.892 22.7307 132.63 23.495 133.143 24.4306C133.657 25.3661 133.921 26.4071 133.921 27.514C133.921 27.7117 133.908 27.9093 133.895 28.0806C133.881 28.2651 133.868 28.4101 133.842 28.5286H124.381C124.433 29.1743 124.605 29.7146 124.908 30.1626C125.211 30.6106 125.606 30.9532 126.081 31.2036C126.555 31.454 127.056 31.5726 127.596 31.5726C128.242 31.5726 128.835 31.4144 129.388 31.1114C129.941 30.8083 130.324 30.3998 130.521 29.8859L133.196 30.637C132.919 31.2563 132.498 31.8097 131.958 32.2973C131.404 32.7848 130.758 33.1538 130.007 33.4305C129.256 33.7336 128.426 33.8654 127.504 33.8654ZM124.288 26.5784H130.706C130.64 25.9723 130.468 25.432 130.179 24.984C129.889 24.536 129.507 24.1802 129.045 23.9298C128.571 23.6795 128.057 23.5477 127.477 23.5477C126.911 23.5477 126.41 23.6795 125.949 23.9298C125.488 24.1802 125.119 24.536 124.829 24.984C124.526 25.4452 124.354 25.9723 124.288 26.5784Z" fill="black" />
            <path d="M71.8691 19.6201V16.5234H74.9789V19.6201H71.8691ZM71.8691 33.6273V21.3594H74.9789V33.6273H71.8691Z" fill="black" />
            <path d="M139.506 33.6295V17H142.734V33.6295H139.506Z" fill="black" />
            <path d="M164.964 33.6267H161.828V26.8405C161.828 25.8654 161.657 25.167 161.327 24.719C160.985 24.271 160.524 24.047 159.931 24.047C159.311 24.047 158.718 24.2842 158.178 24.7454C157.638 25.2197 157.242 25.8127 157.005 26.5506V33.6135H153.843V26.8273C153.843 25.8522 153.685 25.1539 153.355 24.7058C153.026 24.2578 152.565 24.0338 151.972 24.0338C151.365 24.0338 150.772 24.2578 150.219 24.719C149.652 25.1802 149.257 25.7732 149.02 26.5243V33.6135H145.91V21.4774H148.743V23.7439C149.218 22.9533 149.837 22.3471 150.628 21.9255C151.418 21.5038 152.314 21.293 153.316 21.293C154.33 21.293 155.121 21.5433 155.688 22.0309C156.254 22.5184 156.623 23.1378 156.781 23.8625C157.282 23.0323 157.914 22.3867 158.692 21.9518C159.469 21.517 160.339 21.293 161.327 21.293C162.078 21.293 162.685 21.4247 163.172 21.7015C163.647 21.965 164.015 22.334 164.279 22.7952C164.543 23.2564 164.727 23.7703 164.832 24.3369C164.938 24.9035 164.991 25.4833 164.991 26.0499V33.6267H164.964Z" fill="black" />
            <path d="M175.06 33.8639C174.111 33.8639 173.268 33.6531 172.53 33.2182C171.792 32.7834 171.225 32.2168 170.817 31.5052V38.6208H167.707V21.4774H170.448V23.5858C170.922 22.8742 171.515 22.3208 172.227 21.9123C172.952 21.5038 173.768 21.293 174.691 21.293C175.521 21.293 176.285 21.4511 176.971 21.7805C177.669 22.1099 178.275 22.558 178.802 23.1378C179.329 23.7176 179.725 24.3896 180.014 25.1407C180.291 25.8918 180.436 26.6956 180.436 27.5521C180.436 28.7248 180.199 29.7922 179.751 30.7541C179.29 31.716 178.657 32.4671 177.853 33.0337C177.036 33.5872 176.114 33.8639 175.06 33.8639ZM174.006 31.2021C174.48 31.2021 174.902 31.0967 175.31 30.8991C175.706 30.7014 176.048 30.4379 176.325 30.0953C176.602 29.7527 176.826 29.3573 176.984 28.9093C177.142 28.4613 177.221 28.0001 177.221 27.5126C177.221 27.025 177.142 26.5638 176.971 26.129C176.799 25.6941 176.562 25.312 176.259 24.9957C175.943 24.6795 175.587 24.4159 175.165 24.2315C174.757 24.047 174.296 23.9547 173.795 23.9547C173.518 23.9547 173.215 24.0074 172.912 24.0997C172.609 24.1919 172.319 24.3369 172.029 24.5214C171.752 24.7058 171.502 24.9167 171.304 25.1539C171.107 25.391 170.935 25.6546 170.817 25.9708V28.8171C171.014 29.2651 171.278 29.6604 171.607 30.0294C171.924 30.3983 172.293 30.6882 172.714 30.8859C173.149 31.0967 173.571 31.2021 174.006 31.2021Z" fill="black" />
            <path d="M181.713 30.0455C181.713 29.2548 181.937 28.5696 182.385 27.9898C182.833 27.3969 183.452 26.9489 184.23 26.6062C185.02 26.2768 185.93 26.1055 186.984 26.1055C187.498 26.1055 188.025 26.1451 188.552 26.2241C189.079 26.3032 189.54 26.4218 189.935 26.5931V25.921C189.935 25.1699 189.711 24.5902 189.25 24.1685C188.802 23.76 188.117 23.5492 187.208 23.5492C186.523 23.5492 185.877 23.6678 185.258 23.9049C184.651 24.1421 184.006 24.4716 183.334 24.9064L182.345 22.864C183.136 22.3237 183.966 21.9152 184.823 21.6517C185.679 21.3881 186.562 21.2695 187.511 21.2695C189.263 21.2695 190.621 21.6912 191.596 22.5477C192.571 23.4042 193.058 24.6429 193.058 26.2505V30.1114C193.058 30.4408 193.111 30.6648 193.23 30.8097C193.348 30.9547 193.533 31.0338 193.809 31.0601V33.6165C193.533 33.6692 193.269 33.7087 193.045 33.7351C192.821 33.7614 192.624 33.7746 192.465 33.7614C191.846 33.7614 191.372 33.6296 191.042 33.3529C190.726 33.0762 190.528 32.7468 190.462 32.3383L190.397 31.7058C189.843 32.391 189.184 32.9181 188.38 33.3002C187.59 33.6692 186.773 33.8668 185.943 33.8668C185.126 33.8668 184.401 33.6955 183.755 33.3529C183.11 33.0103 182.596 32.5491 182.227 31.9825C181.897 31.4027 181.713 30.757 181.713 30.0455ZM189.356 30.6384C189.527 30.4803 189.672 30.309 189.777 30.1114C189.883 29.9137 189.935 29.7424 189.935 29.6106V28.3983C189.566 28.2402 189.158 28.1348 188.723 28.0426C188.288 27.9635 187.867 27.9108 187.484 27.9108C186.667 27.9108 186.009 28.0821 185.482 28.4247C184.954 28.7673 184.691 29.2153 184.691 29.7819C184.691 30.085 184.783 30.3749 184.954 30.6648C185.126 30.9415 185.376 31.1655 185.692 31.3368C186.009 31.5081 186.404 31.5872 186.878 31.5872C187.353 31.5872 187.814 31.4949 188.262 31.3105C188.71 31.1392 189.066 30.9152 189.356 30.6384Z" fill="black" />
            <path d="M201.716 33.8639C200.701 33.8639 199.792 33.6926 199.015 33.3632C198.224 33.0337 197.552 32.5726 196.985 31.9796C196.419 31.3866 195.997 30.7146 195.694 29.9503C195.391 29.1992 195.246 28.3954 195.246 27.5653C195.246 26.432 195.51 25.391 196.024 24.4423C196.537 23.4804 197.275 22.7161 198.25 22.1495C199.212 21.5829 200.372 21.293 201.69 21.293C203.021 21.293 204.154 21.5829 205.116 22.1627C206.064 22.7424 206.776 23.4935 207.224 24.4291L204.18 25.3647C203.917 24.9167 203.561 24.5609 203.126 24.3237C202.691 24.0865 202.19 23.9547 201.65 23.9547C201.07 23.9547 200.543 24.0997 200.056 24.4028C199.568 24.7058 199.186 25.1143 198.896 25.6546C198.606 26.1948 198.461 26.8273 198.461 27.5653C198.461 28.2636 198.606 28.8961 198.896 29.4496C199.186 30.003 199.568 30.4247 200.056 30.7409C200.543 31.044 201.084 31.2021 201.677 31.2021C202.072 31.2021 202.428 31.1362 202.757 31.0177C203.086 30.8991 203.39 30.7146 203.653 30.4906C203.93 30.2666 204.127 30.0162 204.246 29.7527L207.29 30.6882C207.013 31.2944 206.605 31.8478 206.064 32.3222C205.524 32.8097 204.892 33.1787 204.167 33.4554C203.442 33.7321 202.625 33.8639 201.716 33.8639Z" fill="black" />
            <path d="M216.566 33.0226C216.289 33.1412 215.96 33.273 215.591 33.4047C215.222 33.5365 214.826 33.6419 214.392 33.7342C213.957 33.8264 213.535 33.8659 213.087 33.8659C212.494 33.8659 211.954 33.7605 211.466 33.5629C210.979 33.3652 210.597 33.0358 210.307 32.6009C210.017 32.1661 209.872 31.5863 209.872 30.8747V23.8645H208.277V21.4795H209.872V17.5H213.034V21.4795H215.617V23.8645H213.034V29.8074C213.047 30.2291 213.166 30.5321 213.39 30.7166C213.614 30.9143 213.891 31.0065 214.233 31.0065C214.55 31.0065 214.853 30.9538 215.169 30.8352C215.485 30.7166 215.736 30.6112 215.946 30.519L216.566 33.0226Z" fill="black" />
            <path d="M29.4116 12.7037C32.4292 13.7316 34.8142 14.5485 34.6429 14.1532C34.5639 14.0478 34.4716 13.9424 34.353 13.8633L34.274 13.8106L34.3662 13.8765C34.0499 13.6393 33.7732 13.4417 33.5097 13.2835C33.2461 13.1254 32.864 12.875 32.4555 12.6642L32.3501 12.6115L32.271 12.572L32.1524 12.5193L32.0602 12.4797H32.047C31.2959 12.124 30.5317 11.8209 29.741 11.5573C29.0822 11.3465 28.4102 11.2147 27.7381 11.1225C27.0002 11.0171 26.2623 10.9775 25.5112 10.9907C24.8919 11.0303 24.0881 11.083 23.4688 11.162C24.1144 11.1093 25.2345 11.2806 29.4116 12.7037Z" fill="#354D86" />
            <path d="M12.3346 29.7392L12.664 30.5298C12.4532 29.726 14.0476 29.9632 18.2511 31.2809C34.2744 36.3804 34.2744 36.3804 34.986 35.9851C35.2759 35.7216 35.5526 35.4317 35.8029 35.1286C36.6858 33.9954 36.7912 34.0876 37.2656 33.4288C38.0958 32.1638 38.0958 32.1901 38.4384 31.4917L38.781 30.7802C38.1353 31.584 37.1207 31.2546 19.8587 25.7597C14.1793 23.994 11.7152 23.5196 11.5439 24.3498L11.5176 25.4039C11.5439 26.1418 11.6362 26.8666 11.7679 27.5781C11.9129 28.3424 12.0974 29.054 12.3346 29.7392Z" fill="url(#paint0_linear_5144_22566)" />
            <path d="M22.7454 19.935C37.8727 24.8106 39.8097 25.4431 39.9546 24.5602C39.9151 24.1517 39.8888 23.6115 39.8229 23.203C39.7174 22.4782 39.5461 21.7667 39.309 21.0815C39.0981 20.3831 38.8346 19.6979 38.5183 19.0522L38.2943 18.6042C38.6369 19.5397 35.514 18.4856 26.1187 15.3626C22.4423 14.1635 18.6209 12.9512 17.6854 13.4783L17.3164 13.7418C16.7234 14.1767 16.1568 14.6642 15.6297 15.1913C15.1027 15.7184 14.6151 16.2982 14.1803 16.9175C13.9299 17.2996 13.5873 17.8004 13.3633 18.1957C14.1012 19.5134 15.2871 17.5632 22.7454 19.935Z" fill="url(#paint1_radial_5144_22566)" />
            <path d="M19.4107 37.8826C21.216 38.581 21.9539 38.9368 23.3111 39.1872L23.7723 39.2662C24.5102 39.3716 25.2482 39.4243 25.9861 39.398C26.724 39.3848 27.4487 39.3189 28.1735 39.1872C28.5688 39.1081 29.0959 38.9763 29.4912 38.8709C29.1354 38.9236 28.7269 38.9368 26.8821 38.4097C23.9173 37.5532 21.0315 36.4595 18.0667 35.5635C17.3156 35.3394 15.5367 34.786 15.8793 35.3658C15.9583 35.4844 16.0506 35.5898 16.1692 35.682C16.3536 35.8402 16.5776 36.0246 16.8017 36.2091C17.0257 36.3936 17.2497 36.5649 17.4473 36.7099C17.7504 36.9339 18.0139 37.1052 18.2511 37.2501C18.4883 37.3951 18.7255 37.54 19.0286 37.6981L19.134 37.7508L19.2131 37.7904L19.4107 37.8826Z" fill="url(#paint2_linear_5144_22566)" />
            <path d="M29.5028 38.8986C29.1075 38.9908 28.5804 39.1358 28.1851 39.2148C27.4604 39.3334 26.7357 39.3993 25.9977 39.3993C25.2598 39.452 24.5087 39.4125 23.784 39.2676L23.3228 39.1885C23.4414 39.228 23.5732 39.228 23.6917 39.1885C23.7576 38.8327 22.7035 38.108 19.5805 35.9865C15.1793 32.9821 13.0051 31.3877 12.5703 30.518L12.2804 29.7273C12.0432 29.0421 11.8587 28.3306 11.7401 27.619C11.6084 26.8943 11.5425 26.1695 11.5293 25.4448L11.5557 24.3906C13.1896 26.9733 15.5088 28.4228 23.7181 33.9308C27.0914 36.2236 30.5702 38.6087 29.5028 38.8986Z" fill="url(#paint3_radial_5144_22566)" />
            <path d="M38.8203 30.8191L38.504 31.5043C38.1614 32.2027 37.7661 32.8615 37.3181 33.494C36.8833 34.1002 36.3957 34.6668 35.8686 35.1939C35.6051 35.431 35.2756 35.7605 35.0121 35.9977C35.5128 35.2202 32.0077 32.7297 27.3826 29.5409C18.8306 23.6639 12.8219 19.3945 13.3885 18.1691C13.6257 17.7869 13.9024 17.2335 14.166 16.8514C14.6272 16.2189 15.1279 15.6259 15.6813 15.0856C16.1821 14.5849 16.7355 14.1237 17.3416 13.7284L17.7106 13.4648C17.223 14.6903 22.059 18.0768 30.7691 24.046C34.8408 26.8264 39.1497 29.8835 38.8203 30.8191Z" fill="url(#paint4_radial_5144_22566)" />
            <path d="M29.9531 11.6217C29.3997 11.4372 29.2416 11.4636 29.0966 11.5163C29.0966 11.8589 30.1113 12.6495 33.4714 14.8896C36.5944 16.9848 38.0175 18.0653 38.3074 18.6055C38.3074 18.6055 39.7964 21.3859 39.9414 24.5089C39.4011 23.4679 38.1097 22.6377 38.1097 22.6377L32.9443 18.9481C26.7247 14.7446 22.0732 11.5031 23.47 11.1737C23.47 11.1737 26.7775 10.528 29.9531 11.6217Z" fill="url(#paint5_radial_5144_22566)" />
            <defs>
                <linearGradient id="paint0_linear_5144_22566" x1="38.4882" y1="26.8214" x2="13.266" y2="32.2262" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#527A43" />
                    <stop offset="1" stop-color="#976B27" />
                </linearGradient>
                <radialGradient id="paint1_radial_5144_22566" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.76127 11.9187) rotate(-11.5346) scale(52.7243 52.7139)">
                    <stop offset="0.13" stop-color="#36804A" />
                    <stop offset="0.27" stop-color="#31735B" />
                    <stop offset="0.55" stop-color="#214F89" />
                    <stop offset="0.59" stop-color="#1D4A8E" />
                </radialGradient>
                <linearGradient id="paint2_linear_5144_22566" x1="15.8424" y1="37.2555" x2="29.4975" y2="37.2555" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#814F20" />
                    <stop offset="0.28" stop-color="#9A5F22" />
                    <stop offset="0.49" stop-color="#B06A28" />
                    <stop offset="0.54" stop-color="#AD6A28" />
                    <stop offset="0.77" stop-color="#9E6125" />
                    <stop offset="1" stop-color="#9C6124" />
                </linearGradient>
                <radialGradient id="paint3_radial_5144_22566" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.2804 38.3822) rotate(-11.5346) scale(18.2596 18.256)">
                    <stop stop-color="#A46827" />
                    <stop offset="0.52" stop-color="#CF9349" />
                    <stop offset="0.66" stop-color="#D1954F" />
                    <stop offset="0.8" stop-color="#D7A05C" />
                    <stop offset="0.94" stop-color="#E0B071" />
                    <stop offset="0.99" stop-color="#E2B477" />
                </radialGradient>
                <radialGradient id="paint4_radial_5144_22566" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18.0438 26.0709) rotate(-11.5346) scale(16.6437 16.6405)">
                    <stop offset="0.35" stop-color="#AFD188" />
                    <stop offset="0.47" stop-color="#A8CD80" />
                    <stop offset="0.66" stop-color="#8CC269" />
                    <stop offset="0.84" stop-color="#71B650" />
                </radialGradient>
                <radialGradient id="paint5_radial_5144_22566" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.1878 10.1562) rotate(-11.5346) scale(28.6004 28.5948)">
                    <stop stop-color="#60C3F0" />
                    <stop offset="0.3" stop-color="#5FAEE1" />
                    <stop offset="0.44" stop-color="#4786C6" />
                    <stop offset="0.58" stop-color="#3D6AB0" />
                    <stop offset="0.72" stop-color="#3556A1" />
                    <stop offset="0.84" stop-color="#2F4997" />
                    <stop offset="0.95" stop-color="#2F4593" />
                </radialGradient>
            </defs>
        </svg>

    );
};

export default ClimateXLogo;
