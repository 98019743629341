import React from "react";
import useMobile from "../useMobile";
import e from "../eventsClem1.module.css";

function RealityCheckCard(props) {
  const { data = {} } = props;

  const mobileVersion = useMobile();

  return (
    <div className={e.RealityCheck}>
      <div className={e.RealityCheckHeader}>
        <div className={e.RealityCheckHeaderContainer}>{data.header}</div>
        <div className={e.AgendaTimings}>{data.timings}</div>
      </div>

      <div className={e.RealityCheckSubHeader}>{data.subHeader}</div>




      <div className={e.RealityCheckPanelContainer}>
        <div className={e.RealityCheckPanelNames}>
          <div>
        {data.mainTitle}
          <ul>
            {data.moderator?.map((data, index) => (
              <li key={index} className={e.RealityCheckPanelListItem}>
                {data}
              </li>
            ))}
          </ul>
          </div>
          <div>
          {data.title}
          <ul>
            {data.names.map((data, index) => (
              <li key={index} className={e.RealityCheckPanelListItem}>
                {data}
              </li>
            ))}
          </ul>
          </div>
        </div>

        {mobileVersion ? (
          <div className={e.imagesContainer}>
            {(data.panelistsImages || []).map((image) => (image))}
          </div>
        ) : (
          <div className={e.RealityCheckPanelImages}>
            <div className={e.RealityCheckPanelImagesContainer}>
              {data.panelistsImages[0]}
              {data.panelistsImages[1]}
            </div>

            <div className={e.RealityCheckPanelImagesContainer}>
              {data.panelistsImages[2]}
              {data.panelistsImages[3] && data.panelistsImages[3]}
            </div>

            <div className={e.RealityCheckPanelImagesContainer}>
              {data.panelistsImages[4] && data.panelistsImages[4]}
              {data.panelistsImages[5] && data.panelistsImages[5]}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RealityCheckCard;
