import style from "./TabSelector.module.css";

const TabSelector = (props) => {
  const { tabs, selectedTab, onClickHandler } = props;

  return (
    <div className={style.tabSelector}>
      {tabs.map((tab, index) => (
        <div
          key={index}
          className={`${style.tab} ${
            selectedTab === index ? style.selectedTab : ""
          }`}
          onClick={() => onClickHandler(index)}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};

export default TabSelector;
