import React from "react";
import classes from "./WhoWeHelp.module.css";
import FinancialCard from "../../../home/financial/FinancialCard/FinancialCard";

import BusinessOwners from "../../../assets/energy-owners.png";
import Lenders from "../../../assets/energy-lenders.png";
import Buyers from "../../../assets/energy-buyers.png";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
function WhoWeHelp() {
  const buyingCentres = [
    {
      image: BusinessOwners,
      name: "Business Owners",
    },
    {
      image: Lenders,
      name: "Lenders",
    },
    {
      image: Buyers,
      name: "Buyers",
    },
  ];

  return (
    <div className={classes.mainParentDiv}>
      <div className={classes.headingDiv}>Who We Help</div>
      <div>
        <FinancialCard cardData={buyingCentres} />
      </div>
    </div>
  );
}

export default WhoWeHelp;
