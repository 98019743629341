import React from "react";
import tec from "./tec.module.css";
import Cpu from "../../assets/cpu.svg";
import Techo from "../../assets/Tech.svg";
function tech() {
  return (
    <div className={tec.ParentDiv}>
      <div data-aos="fade-up" data-aos-once="true" className={tec.our}>Our Techonology</div>
      <div className={tec.textDiv}>
        <h2 data-aos="fade-up" data-aos-once="true" className={tec.text}>
          Our unique SaaS platform leverages high-quality proprietary data sets and models to enable an end-to-end lifecycle management of your net-zero transition, with an automation first approach.
        </h2>
      </div>
      <div className={tec.imgDiv}>
        <div data-aos="zoom-in" data-aos-once="true" className={tec.imgPart}>
          <img src={Techo} className={tec.img1} />
        </div>
      </div>
    </div>
  );
}

export default tech;

{/* <div className={tec.mainRingDiv}>
<div data-aos="zoom-in" data-aos-once="true" className={tec.ring}>
  <img data-aos="fade-up" data-aos-once="true" className={tec.imgg} src={Techo} alt="svg" />
</div>
<div className={tec.mainInsideDiv}>
  <div data-aos="zoom-in" data-aos-once="true"  className={tec.insideRing}>
    <img className={tec.cpu} src={Cpu} alt="svg" />
  </div>
</div>
</div> */}