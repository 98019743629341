import Header from "../home/header/header";
import GlobalPresencePage from "./GlobalPresencePage/GlobalPresencePage";
import LandingPage from "./LandingPage/LandingPage";
import PracticalCraftedApproach from "./PracticalCraftedApproach/PracticalCraftedApproach";
import OurStrategy from "./OurStrategy/OurStrategy";
import ClemFooter from "./ClemFooter/ClemFooter";
import { useEffect, useState } from "react";
import Footer from "../home/Footer/footer";
const Clem = () => {
  const [mobileVersion, setMobileVersion] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 1000) {
      setMobileVersion(true);
    }

    const handleResize = () => {
      if (window.innerWidth < 1000) {
        setMobileVersion(true);
      } else {
        setMobileVersion(false);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
     
      <LandingPage />
      <GlobalPresencePage mobileVersion={mobileVersion} />
      <PracticalCraftedApproach mobileVersion={mobileVersion} />
      <OurStrategy mobileVersion={mobileVersion} />
      <ClemFooter />
     
    </>
  );
};

export default Clem;
