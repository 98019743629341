import React from "react";
import { useEffect } from "react";
import Video from "./video/video";
import Who from "./Who/who";

import Tec from "./techonology/tech";
import "./home.css";
import Financial from "./financial/financial";
import Approach from "./approach/approach";
import What from "./what/what";
import DigitalPlatformDecarb from "./digitalPlatformDecarb/digitalPlatformDecarb";
import AllSector from "./allSector/AllSector";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     
      <Video />
      <Who />
      <Financial />
      <What />
      <AllSector />
      <DigitalPlatformDecarb />
      {/* <Approach /> */}
      <Tec />
     
    </>
  );
}

export default Home;
