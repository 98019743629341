import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { Alert, AlertTitle } from "@mui/material";
import { useNavigate } from "react-router-dom";
import foot from "./footer.module.css";
import Mail from "../../assets/Email.svg";
import { firebaseConfig } from "../../util";
import Medium from "../../assets/medium.svg";
import Twitter from "../../assets/twitterLogo2.svg";
import Instagram from "../../assets/insta1.svg";
import { insertEmail } from "../../../API/user";
import Linkedin from "../../assets/linkedin2.svg";

function Footer() {
  // Firebase Configuration
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const emailurl = firebaseConfig.databaseURL + "/email.json";

  const navigate = useNavigate();

  const [uemail, setEmail] = useState({
    email: "",
  });

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setEmail({ ...uemail, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email } = uemail;
    const res = await fetch(emailurl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    });
    if (res) {
      // alert('Email submitted successfully!');
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
    }
    setEmail({
      email: "",
    });
  };

  return (
    <div className={foot.Parent}>
      {/* <div className={foot.MainContent}>
        <div className={foot.content1}>
          <div className={foot.emailPart}>
            <img src={Mail} alt="Email" />
            <div className={foot.email}>
              contact@sustainabilityeconomics.com
            </div>
          </div>

          {showSuccessMessage && (
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              Email Submitted Successfully!
            </Alert>
          )}
          <form onSubmit={(e) => handleSubmit(e)} method="POST">
            <div className={foot.inputPart}>
              <input
                className={foot.input}
                type="text"
                placeholder="Enter your email address"
                name="email"
                value={uemail.email}
                onChange={handleInput}
                required
              />
              <button className={foot.btn} type="submit">
                Get Started
              </button>
            </div>
          </form>
        </div>

        <div className={foot.content3}>
          <div className={foot.sin}>
            <div className={foot.titleA}>SINGAPORE</div>
            <div className={foot.address}>
              328 North Bridge Road, Raffles Hotel Arcade, Level 02-20 188719
            </div>
          </div>
          <div className={foot.sin}>
            <div className={foot.titleA}>UNITED STATES</div>
            <div className={foot.address}>
              One World Trade Center, Suite 8500, NY 10048
            </div>
          </div>
          <div className={foot.sin}>
            <div className={foot.titleA}>INDIA</div>
            <div className={foot.address}>
              Survey No. 133, Qube Software Park, 2, Bhoganahalli Rd, Hobli,
              East, Varthur, Bengaluru, Karnataka 560103
            </div>
          </div>
        </div>
        <div className={foot.content4}>
          <div className={foot.cpyrt}>© 2023 Sustainability Economics</div>
          <div className={foot.link}>
            <div className={foot.lowerDivspan}>
              <a
                href="https://www.linkedin.com/company/sustainabilityeconomics/"
                target="_blank"
              >
                <img className={foot.social} src={Linkedin} alt="svg" />
              </a>
            </div>
            <div className={foot.lowerDivspan}>
              <a href="https://twitter.com/SE_NetZero/" target="_blank">
                <img className={foot.social} src={Twitter} alt="svg" />
              </a>
            </div>
            <div className={foot.lowerDivspan}>
              <a
                href="https://www.instagram.com/sustainabilityeconomics/"
                target="_blank"
              >
                <img className={foot.social} src={Instagram} alt="svg" />
              </a>
            </div>
          </div>
        </div>
      </div> */}

      <div className={foot.mainContent}>
        <div className={foot.detailsContainer}>
          <div className={foot.mediaContainer}>
            <div className={foot.emailPart}>
              <img src={Mail} alt="Email" />
              <div className={foot.email}>
                contact@sustainabilityeconomics.com
              </div>
            </div>

            {showSuccessMessage && (
              <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                Email Submitted Successfully!
              </Alert>
            )}

            <form onSubmit={(e) => handleSubmit(e)} method="POST">
              <div className={foot.inputPart}>
                <input
                  className={foot.input}
                  type="text"
                  placeholder="Enter your email address"
                  name="email"
                  value={uemail.email}
                  onChange={handleInput}
                  required
                />
                <button className={foot.btn} type="submit">
                  Get Started
                </button>
              </div>
            </form>

            <div className={foot.link}>
              <div className={foot.lowerDivspan}>
                <a
                  href="https://www.linkedin.com/company/sustainabilityeconomics/"
                  target="_blank"
                >
                  <img className={foot.social} src={Linkedin} alt="svg" />
                </a>
              </div>
              <div className={foot.lowerDivspan}>
                <a href="https://twitter.com/SE_NetZero/" target="_blank">
                  <img className={foot.social} src={Twitter} alt="svg" />
                </a>
              </div>
              <div className={foot.lowerDivspan}>
                <a
                  href="https://www.instagram.com/sustainabilityeconomics/"
                  target="_blank"
                >
                  <img className={foot.social} src={Instagram} alt="svg" />
                </a>
              </div>
            </div>
          </div>

          <div className={foot.addressContainer}>
            <div className={foot.sin}>
              <div className={foot.titleA}>SINGAPORE</div>
              <div className={foot.address}>
                328 North Bridge Road, Raffles Hotel Arcade, Level 02-20 188719
              </div>
            </div>

            <div className={foot.sin}>
              <div className={foot.titleA}>UNITED STATES</div>
              <div className={foot.address}>
                One World Trade Center, Suite 8500, NY 10048
              </div>
            </div>

            <div className={foot.sin}>
              <div className={foot.titleA}>INDIA</div>
              <div className={foot.address}>
                Survey No. 133, Qube Software Park, 2, Bhoganahalli Rd, Hobli,
                East, Varthur, Bengaluru, Karnataka 560103
              </div>
            </div>
          </div>

          <div className={foot.legalSection}>
            <div className={foot.titleA}>Legal</div>

            <div className={`${foot.link} ${foot.address}`}>Privacy Policy</div>

            <div className={`${foot.link} ${foot.address}`} onClick={() => navigate("/terms")}>Terms & Conditions</div>
          </div>
        </div>

        <div className={foot.divider} />

        <div className={foot.cpyrt}>© {new Date().getFullYear()} Sustainability Economics</div>
      </div>

      <div className={foot.greenLine} />
    </div>
  );
}

export default Footer;
