import { useState, useMemo, useEffect } from "react";
import TabSelector from "../TabSelector/TabSelector";
import styles from "./SectorInfoSection.module.css";

import batteryOptimizeImg from "../../../assets/solutions/energy/battery-optimisation.jpeg";
import cleanEnergyImg from "../../../assets/solutions/energy/cffp-to-clean-energy-consulting-re-technology-consulting.jpg";
import demandForecastingImg from "../../../assets/solutions/energy/demand-forecasting.jpeg";
import financeReProjectImg from "../../../assets/solutions/energy/financing-for-re-projects.jpeg";
import hedgingPpaImg from "../../../assets/solutions/energy/hedging-of-ppa.jpeg";
import marketPlacePowerImg from "../../../assets/solutions/energy/marketplace-power-purchase-agreement.jpeg";
import marketPlaceRenewableImg from "../../../assets/solutions/energy/marketplace-renewable-energy-stake.jpeg";
import portfolioRiskImg from "../../../assets/solutions/energy/portfolio-risk-analysis.jpeg";
import ppaAnalysisImg from "../../../assets/solutions/energy/ppa-analysis-and-benchmarking.jpeg";
import priceForecastingImg from "../../../assets/solutions/energy/price-forecasting.jpeg";
import renewableEnergyImg from "../../../assets/solutions/energy/renewable-energy-certificates.jpeg";
import transitionFinancingImg from "../../../assets/solutions/energy/transition-financing.jpeg";
import decarbonisationImg from "../../../assets/solutions/energy/decarbonisation-pathways.jpg";

import carbonImg from "../../../assets/Carbon.png";
import targetImg from "../../../assets/Target.png";
import climateImg from "../../../assets/Climate.png";
import Transition from "../../../assets/Transition.png";
import carbonOffsetImg from "../../../assets/CarbonOffset.png";

const SectorInfoSection = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTitle, setSelectedTitle] = useState(0);
  const [selectedSubTitle, setSelectedSubTitle] = useState(0);
  const tabs = [
    "Transition",
    "Independent Renewable Energy Developers",
    "Analytics",
  ];

  const tabContents = useMemo(
    () => [
      [
        {
          title: "Carbon Accounting",
          content:
            "Measure your Emissions from Coal both as an Investor and as a Power Plant Owner with a single click across your entire portfolio.",
          img: carbonImg,
        },
        {
          title: "Target setting and Benchmarking",
          content:
            "Set Science Based Targets and track performance in real-time, benchmarking across industry leaders.",
          img: targetImg,
        },
        {
          title: "Transition Risk",
          content:
            "Assess the impact of transition, portfolio risks on your returns while tracking your investment portfolio’s alignment to Net-Zero.",
          img: climateImg,
        },
        {
          title: "Physical Risk",
          content:
            "Assess the impact of transition, physical risks on your returns while tracking your investment portfolio’s alignment to Net-Zero.",
          img: climateImg,
        },
        {
          title: "Decarbonisation pathways",
          content:
            "Decarbonizing the portfolio by transitioning from Coal fired power plants to Clean energy sources",
          img: decarbonisationImg,
        },
        {
          title: "Transition Financing",
          content:
            "Transition financing deals about the financing instruments like Sustainability-linked bonds, Green Bonds and other debt instruments required for the transition from CFPP to Clean energy sources.",
          img: transitionFinancingImg,
        },
        {
          title: "Carbon Markets",
          content:
            "Methodology developer: As methodology developers, we've crafted a customized approach tailored to this transition. Utilizing our methodology can generate transition credits. \n Project proponent: We're serving as the project proponent in carbon markets during this transition. Our responsibility extends to overseeing and actively facilitating the entire process of credit issuance, ensuring its successful implementation.\nMarketplace for Transition Credits: This marketplace serves as a platform where organizations and entities can buy and sell transition credits. The marketplace provides a structured environment for trading these credits, allowing buyers to meet their sustainability goals while enabling sellers to monetize their transition efforts.",
          img: carbonOffsetImg,
        },
        {
          title: "Renewable Energy Certificates",
          content:
            "The Renewable Energy Certificate (REC) Marketplace provides a platform for trading certificates representing renewable energy generation. It facilitates buyers in achieving sustainability goals and promotes global adoption of renewable energy. Sellers can monetize their renewable energy efforts, helping buyers meet regulatory or voluntary targets for renewable energy.",
          img: renewableEnergyImg,
        },
        {
          title: "CFFP to Clean energy Consulting",
          content:
            "We function as a consulting group aiding CFPP owners in assessing their current status and guiding them in choosing suitable clean energy technologies for their transition. Our services extend beyond technical aspects; we also provide support in financial planning for the transition and actively work towards its successful implementation.",
          img: cleanEnergyImg,
        },
      ],
      [
        {
          title: "RE Technology Consulting",
          content:
            "We specialize in renewable energy technology consulting, helping renewable energy (RE) owners assess their needs and identify the most suitable clean energy technologies to enhance efficiency and environmental sustainability.",
          img: cleanEnergyImg,
        },
        {
          title: "Financing for RE projects",
          content:
            "We offer financing support to renewable energy (RE) developers, facilitating RE project funding through strategic debt instruments such as Green bonds and leveraging Carbon credits or Renewable Energy Certificates (RECs) to secure funding. Our expertise ensures successful implementation and financing for RE projects.",
          img: financeReProjectImg,
        },
        {
          title: "Marketplace",
          subTabs: [
            {
              title: "Renewable Energy stake",
              content:
                "The Renewable Energy Stake Marketplace is designed for RE developers seeking to sell stakes in their projects and lenders interested in investing in renewable energy projects, providing a platform for efficient stake transactions and investment opportunities in the renewable energy sector.",
              img: marketPlaceRenewableImg,
            },
            {
              title: "Power Purchase Agreement",
              content:
                "The Power Purchase Agreement (PPA) Marketplace serves as a platform for renewable energy (RE) developers seeking buyers for the energy generated from their projects through PPAs, as well as for buyers actively seeking clean energy sources, facilitating efficient transactions and fostering the adoption of renewable energy.",
              img: marketPlacePowerImg,
            },
            {
              title: "Renewable Energy Certificates",
              content:
                "The Renewable Energy Certificate (REC) Marketplace provides a platform for trading certificates representing renewable energy generation. It facilitates buyers in achieving sustainability goals and promotes global adoption of renewable energy. Sellers can monetize their renewable energy efforts, helping buyers meet regulatory or voluntary targets for renewable energy.",
              img: renewableEnergyImg,
            },
            {
              title: "Carbon Credits",
              content:
                "Explore our Carbon Credit Marketplace to connect with sellers offering carbon credits for emission offsets and sustainability initiatives. Streamlined trading supports global carbon reduction efforts, fostering a greener future and promoting environmental sustainability.",
              img: carbonOffsetImg,
            },
          ],
        },
      ],
      [
        {
          title: "PPA Analysis and Benchmarking",
          content:
            "Our PPA Analysis and Benchmarking tool help you navigate market dynamics, minimize investment risks, and gain insights into factors influencing PPA prices, offering comprehensive market insights for informed decision-making.",
          img: ppaAnalysisImg,
        },
        {
          title: "Price Forecasting",
          content:
            "Our energy price forecasting product provides accurate predictions, essential for mitigating investment risks in dynamic energy markets affected by decarbonization trends and emerging pricing structures like negative prices.",
          img: priceForecastingImg,
        },
        {
          title: "Demand Forecasting",
          content:
            "Our Demand Forecasting solution employs advanced algorithms and data analytics to predict consumer demand accurately, enabling businesses to optimize inventory management, plan production schedules efficiently, and improve overall operational performance.",
          img: demandForecastingImg,
        },
        {
          title: "Hedging of PPA",
          content:
            "Our PPA Hedging Optimization suite provides deal analysis, advanced pricing simulations, and optimal hedging strategies to minimize revenue risk and ensure consistent cash flows for renewable assets, enhancing project bankability and investment decisions.",
          img: hedgingPpaImg,
        },
        {
          title: "Battery optimisation",
          content:
            "Battery optimization empowers energy investors with precise forecasts for energy pricing, battery storage profitability, and merchant risk, ensuring unparalleled accuracy in decision-making and investment strategies.",
          img: batteryOptimizeImg,
        },
        {
          title: "Portfolio Risk Analysis",
          content:
            "Our platform supports financial institutions and lenders by effectively assessing portfolio risk and analyzing project cash flow, helping manage merchant revenue and cash flow crucial for supporting loan repayments.",
          img: portfolioRiskImg,
        },
      ],
    ],
    []
  );

  const selectedContent = useMemo(() => {
    let t = tabContents[selectedTab][selectedTitle];
    const fallbackImg =
      "https://images.pexels.com/photos/247763/pexels-photo-247763.jpeg?auto=compress&cs=tinysrgb&dpr=2";
    let formattedT = {
      content: t?.content,
      img: t?.img || fallbackImg,
    };
    if (t?.subTabs) {
      formattedT.content = t.subTabs[selectedSubTitle]?.content;
      formattedT.img = t.subTabs[selectedSubTitle]?.img || fallbackImg;
    }

    return formattedT;
  }, [tabContents, selectedTab, selectedTitle, selectedSubTitle]);

  useEffect(() => {
    setSelectedTitle(0);
    setSelectedSubTitle(0);
  }, [selectedTab]);
  return (
    <div style={{ minHeight: "50vh", padding: "0 1.5rem" }}>
      <TabSelector
        tabs={tabs}
        selectedTab={selectedTab}
        onClickHandler={setSelectedTab}
      />

      <div className={styles.tabContent}>
        <div className={styles.selectionTabs}>
          {tabContents[selectedTab].map((tab, index) => (
            <>
              <div
                className={`${styles.tab} ${
                  selectedTitle === index ? styles.active : ""
                }`}
                onClick={() => setSelectedTitle(index)}
              >
                {tab.title}
              </div>
              {index === selectedTitle &&
                tab.subTabs &&
                tab.subTabs.map((tab, index) => (
                  <div
                    className={`${styles.subTab} ${
                      selectedSubTitle === index ? styles.active : ""
                    }`}
                    onClick={() => setSelectedSubTitle(index)}
                  >
                    {tab.title}
                  </div>
                ))}
            </>
          ))}
        </div>

        <div className={styles.selectionContent}>
          <img src={selectedContent.img} alt="" />
          <div className={styles.overlay}>
            <p>{selectedContent.content}</p>
            {/* <button>Read more {"→"}</button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectorInfoSection;
