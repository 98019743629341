import React from "react";
import ab from "./aboutus.module.css";
import Pic from "../../assets/abimage.png";
import About from "../../assets/About1.svg"
function Aboutus() {
  return (
    <div className={ab.imgDiv}>
      <div>
        <img className={ab.about} src={About} alt="aboutus" />
      </div>
      <div data-aos="fade-right" data-aos-once="true" className={ab.headingPart}>
        <div className={ab.heading}>
          Achieve Net-Zero Transitions with our Practical, Customizable and Data-Driven Solutions.
        </div>
        <div className={ab.heading2}>
          {/* Navigate the Complexities of your Sustainability Journey, with our Technology Driven SaaS Platforms. */}
          Navigate the Complexities of your Decarbonization Journey, with our Technology Driven SaaS Platforms. {/* Changed forr SEO*/}
        </div>
      </div>
    </div>
  );
}

export default Aboutus;


