import React from "react";
import c from "./cards.module.css";
import Icon from "../../assets/Who.png";
import Icon1 from "../../assets/What.png";
import Icon2 from "../../assets/Our.png";
function Cards() {
  return (
    <div className={c.mainParentDiv}>
      <div className={c.parentDiv}>
        <div data-aos="fade-up" data-aos-once="true" className={c.FirstCard}>
          <div className={c.image1}>
            <img className={c.image} src={Icon} alt="svg" />
          </div>
          <div className={c.text}>Who we are</div>
          <div className={c.text1}>
            Our team is a combination of seasoned corporate executives and global leaders with diverse expertise and can connect the dots to simplify the customer's net-zero transition journey.
          </div>
        </div>
        <div data-aos="fade-up" data-aos-once="true" data-aos-delay="200" className={c.SecondDiv}>
          <div className={c.image1}>
            <img className={c.image} src={Icon1} alt="svg" />
          </div>
          <div className={c.text}>What we do</div>
          <div className={c.text1}>
          We focus on the lifecycle management of <h1 style={{display:"inline"}}>end-to-end net-zero transitions </h1>across multiple sectors with an automation-first approach. We embrace global standards by collaborating with industry-standard forums and ecosystem partners.
          </div>
        </div>
        <div data-aos="fade-up" data-aos-once="true" data-aos-delay="400" className={c.ThirdDiv}>
          <div className={c.image1}>
            <img className={c.image} src={Icon2} alt="svg" />
          </div>
          <div className={c.text}>Our Value</div>
          <div className={c.text1}>
            Our value is to simplify and automate the customer's end-to-end net-zero transition with data-backed and tech-enabled digital platforms combined with our expert insights. {/*Changed for SEO */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cards;
