import React from "react";
import { useEffect } from "react";
import Solbg from "./background/solbg";
import Content from "./content/content";
import Footer from '../home/Footer/footer'
import Header from '../home/header/header'
import Products from "./products/products";

function Solution() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <Solbg />
      <Content />
      <Products/>
      <Footer />
    </div>
  );
}

export default Solution;
