import { React } from "react";
// import Vid from "./reducedvideo.mp4";
import "./video.css";
import { useNavigate } from "react-router-dom";
function Video() {
  const navigate = useNavigate();
  const navigateContact = () => {
    navigate("/contact");
  };
  return (
    <div className="video1">
      <div className="overlay"></div>
      <div className="vs">
        <video
          className="video"
          controls={false}
          autostart="true"
          autoPlay
          loop
          muted
          // src={Vid}
          // src="https://www.dropbox.com/s/wy317udwi0k293i/video.mp4?raw=1"
          src={"https://sen-website-video.s3.ap-south-1.amazonaws.com/websitemain.mp4"}
          type="video/mp4"
        />
      </div>
      <div data-aos="fade-right" data-aos-once="true" className={`headingPart`}>
        <div className={`heading`}>
          End-to-End Lifecycle Management of Net-Zero Transitions.
        </div>
        <div className={`heading2`}>
          Begin your Journey to Net-Zero with Sustainability Economics.
        </div>
        <div className={`contact`}>
          <button onClick={navigateContact} className={`btn`}>
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
}

export default Video;
